import './ContactInformation.scss';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-date-picker';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  FROM_CAMPAIGN_PAGE_QUERY_STRING
} from '@components/CampaignRegisterComponent/RegisterEventForm/UserInfoForm/UpdatePhoneNumberText';
import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import PhoneNumberSelect from '@components/Share/PhoneNumberSelect';
import DateOfBirth from '@components/SingleSignOn/DateOfBirth';
import { Yup } from '@components/SingleSignOn/RegisterLogin/Register/utils';
import { updateUserProfile } from '@redux/actions/myAccount';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { MY_SITE_NAME, SG_SITE_NAME } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

import {useCheckPOSAccount} from './hooks';
import ContactInformationModel from './models/ContactInformationModel';

const ContactInfomation = ({ fields, isLoading, accountUser, message, isError, settingGlobal, isUpdateSuccess }) => {
  const dispatch = useDispatch();
  const [dataSources, setDataSources] = useState(null);
  const fieldPhone = useRef({ code: '', value: '' });
  const [datePicker, setDatePicker] = useState(accountUser.DoB ? new Date(accountUser.DoB) : '');
  const [gender, setGender] = useState(accountUser.Gender);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const { search } = useLocation();
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;

  const [schema, setSchema] = useState(
    Yup.object({
      firstName: Yup.string()
        .required(<Message messageCode='Msg_001.1' />)
        .matches(Global.nameValidateRegex(), messageObj?.['Msg_001.1'])
        .trim(),
      lastName: Yup.string()
        .required(<Message messageCode='Msg_001.2' />)
        .matches(Global.nameValidateRegex(), messageObj?.['Msg_001.2'])
        .trim(),
      dob: Yup.string()
        .required(<Message messageCode='Msg_001.4' />)
        .trim(),
      gender: Yup.string()
        .required(<Message messageCode='Msg_019' />)
        .trim()
    })
  );
  const checkPOSAccountService = useCheckPOSAccount();
  const [isCheckingPOSAccount, setIsCheckingPOSAccount] = useState(false);
  const [isPOSAccount, setIsPOSAccount] = useState(false);

  const isDisableDOBInput = useMemo(() => {
    const isDateValid = !isNaN(new Date(accountUser.DoB));

    return (
      ((currentSiteName === SG_SITE_NAME || currentSiteName === MY_SITE_NAME) && accountUser.DoB && isDateValid) || (isPOSAccount && !accountUser.DoB)
    );
  }, [currentSiteName, accountUser, isPOSAccount]);

  useEffect(() => {
    setDataSources(new ContactInformationModel().getData(fields || {}));
  }, []);

  useEffect(() => {
    const user = SitecoreContextFactoryService.getValueContextItem('user');
    if (settingGlobal && !user) {
      window.location.href = settingGlobal?.LoginLink;
    }
    if (settingGlobal && settingGlobal?.PhoneNumberMust) {
      setSchema(
        Yup.object({
          firstName: Yup.string()
            .required(<Message messageCode='Msg_001.1' />)
            .matches(Global.nameValidateRegex(), messageObj?.['Msg_001.1'])
            .trim(),
          lastName: Yup.string()
            .required(<Message messageCode='Msg_001.2' />)
            .matches(Global.nameValidateRegex(), messageObj?.['Msg_001.2'])
            .trim(),
          dob:
            settingGlobal.IsDoBRequired &&
            (!isDisableDOBInput
              ? Yup.string()
                .required(<Message messageCode='Msg_001.4' />)
                .trim()
              : Yup.string().trim()),
          gender:
            settingGlobal.IsGenderRequired &&
            Yup.string()
              .required(<Message messageCode='Msg_019' />)
              .trim(),
          phone: settingGlobal.PhoneNumberMust
            ? settingGlobal.IsPhoneNumberRequired
              ? Yup.string()
                .required(<Message messageCode='Msg_020' />)
                .trim()
                .validatePhone({
                  min: settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust,
                  max: settingGlobal.PhoneNumberMust,
                  prefixCode: fieldPhone.current?.code || '',
                  errorMsg: <Message messageCode='Msg_001.7' />
                })
              : Yup.string().validatePhone({
                min: settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust,
                max: settingGlobal.PhoneNumberMust,
                prefixCode: fieldPhone.current?.code || '',
                errorMsg: <Message messageCode='Msg_001.7' />
              })
            : settingGlobal.IsPhoneNumberRequired
              ? Yup.string()
                .required(<Message messageCode='Msg_020' />)
                .trim()
                .validatePhone({
                  min: settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust,
                  prefixCode: fieldPhone.current?.code || '',
                  errorMsg: <Message messageCode='Msg_001.7' />
                })
              : Yup.string().validatePhone({
                min: settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust,
                prefixCode: fieldPhone.current?.code || '',
                errorMsg: <Message messageCode='Msg_001.7' />
              })
          // .length(settingGlobal.PhoneNumberMust, <Message messageCode='Msg_001.7' />)
        })
      );
      const phoneFormat = Global.splitPhoneNumberWithoutCode(settingGlobal.UseAreaCode, accountUser.PhoneNumber, settingGlobal.AreaCodes);

      fieldPhone.current.code = phoneFormat.code || settingGlobal?.AreaCodes?.[0]?.value || '';

      fieldPhone.current.value = phoneFormat.value;
    }
  }, [settingGlobal, messageObj, isDisableDOBInput]);

  useDidUpdateEffect(() => {
    if (isUpdateSuccess && search) {
      const fromPath = search?.replace(FROM_CAMPAIGN_PAGE_QUERY_STRING, '');

      window.location.href = fromPath;
    }
  }, [isUpdateSuccess, search]);

  useEffect(() => {
    // INFO: check account is POS or not
    (async () => {
      setIsCheckingPOSAccount(true);
      try {
        const checkPOSAccountRes = await checkPOSAccountService();
        if (checkPOSAccountRes) setIsPOSAccount(true);
      } catch (error) {
        setIsPOSAccount(false);
      } finally {
        setIsCheckingPOSAccount(false);
      }
    })();
  }, []);

  const handleSubmit = (values) => {
    const params = {
      EmailAddress: values.email,
      FirstName: values.firstName,
      LastName: values.lastName,
      PhoneNumber: `${fieldPhone.current.code}${values.phone}` || '',
      Gender: values.gender,
      DoB: values.dob
    };
    if (isDisableDOBInput) params.IsMyAccountPage = isDisableDOBInput;

    dispatch(updateUserProfile(params));
  };

  return dataSources ? (
    <>
      <div className='contact-info'>
        <div className='container'>
          <div className='contact-info__wrap'>
            <Formik
              initialValues={{
                email: accountUser.Email,
                firstName: accountUser.FirstName,
                lastName: accountUser.LastName,
                phone: fieldPhone.current.value,
                dob: datePicker,
                gender: gender
              }}
              enableReinitialize={true}
              validationSchema={schema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ errors, touched, handleChange, values, setFieldValue }) => {
                const onHandleTelephoneChange = (evt, fieldName) => {
                  let telephoneWithoutPrefix = evt.target.value;
                  // const telephoneWithPrefix = `${fieldPhone.current.code || ''}${telephoneWithoutPrefix}`;
                  if (!telephoneWithoutPrefix || new RegExp(settingGlobal.PhoneNumberFormat).test(telephoneWithoutPrefix)) {
                    setFieldValue(fieldName, telephoneWithoutPrefix);
                  }
                };

                return (
                  <Form className='contact-info__form'>
                    {message ? <span className={isError ? 'error-message text-center' : 'message-successful text-center'}>{message}</span> : ''}
                    {settingGlobal?.FormatName === 'First Name First' ? (
                      <>
                        <div className={Global.renderDynamicClass(errors.firstName && touched.firstName, 'form-group', 'input-error-validate')}>
                          <input
                            type='text'
                            onChange={handleChange}
                            value={values.firstName}
                            id='firstName'
                            className={Global.renderDynamicClass(values['firstName'], 'form-control form-control-lg', 'input-valid')}
                            name='firstName'
                            placeholder={dataSources['First Name Label'].value}
                          />
                          <label htmlFor='firstName' className='form-group__label'>
                            <Text field={dataSources['First Name Label']} />
                          </label>
                          {errors.firstName && touched.firstName && <span className='error-validate'>{errors.firstName}</span>}
                        </div>
                        <div className={Global.renderDynamicClass(errors.lastName && touched.lastName, 'form-group', 'input-error-validate')}>
                          <input
                            type='text'
                            onChange={handleChange}
                            value={values.lastName}
                            id='lastName'
                            className={Global.renderDynamicClass(values['lastName'], 'form-control form-control-lg', 'input-valid')}
                            name='lastName'
                            placeholder={dataSources['Last Name Label'].value}
                          />
                          <label htmlFor='lastName' className='form-group__label'>
                            <Text field={dataSources['Last Name Label']} />
                          </label>
                          {errors.lastName && touched.lastName && <span className='error-validate'>{errors.lastName}</span>}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={Global.renderDynamicClass(errors.lastName && touched.lastName, 'form-group', 'input-error-validate')}>
                          <input
                            type='text'
                            onChange={handleChange}
                            value={values.lastName}
                            id='lastName'
                            className={Global.renderDynamicClass(values['lastName'], 'form-control form-control-lg', 'input-valid')}
                            name='lastName'
                            placeholder={dataSources['Last Name Label'].value}
                          />
                          <label htmlFor='lastName' className='form-group__label'>
                            <Text field={dataSources['Last Name Label']} />
                          </label>
                          {errors.lastName && touched.lastName && <span className='error-validate'>{errors.lastName}</span>}
                        </div>
                        <div className={Global.renderDynamicClass(errors.firstName && touched.firstName, 'form-group', 'input-error-validate')}>
                          <input
                            type='text'
                            onChange={handleChange}
                            value={values.firstName}
                            id='firstName'
                            className={Global.renderDynamicClass(values['firstName'], 'form-control form-control-lg', 'input-valid')}
                            name='firstName'
                            placeholder={dataSources['First Name Label'].value}
                          />
                          <label htmlFor='firstName' className='form-group__label'>
                            <Text field={dataSources['First Name Label']} />
                          </label>
                          {errors.firstName && touched.firstName && <span className='error-validate'>{errors.firstName}</span>}
                        </div>
                      </>
                    )}
                    <div className={Global.renderDynamicClass(errors.dob && touched.dob, 'form-group', 'input-error-validate')}>
                      {/* <DatePicker
                      name='dob'
                      value={datePicker}
                      onChange={(e) => {
                        setDatePicker(e);

                        setFieldValue('dob', (e && Global.customizeISOStringHourType(e)) || '');
                      }}
                      format='dd/MMM/yyyy'
                      className={Global.renderDynamicClass(values['dob'], 'date-picker-custom form-control form-control-lg', 'input-valid')}
                      dayPlaceholder='dd'
                      monthPlaceholder='mm'
                      yearPlaceholder='yyyy'
                    /> */}
                      <DateOfBirth
                        setFieldValue={setFieldValue}
                        dobLabel={dataSources['DoB Label']}
                        isRegister={false}
                        disabled={isDisableDOBInput}
                      />
                      {/* <Text field={dataSources['DoB Label']} tag='label' className='form-group__label' /> */}
                      {errors.dob && touched.dob && <span className='error-validate'>{errors.dob}</span>}
                    </div>
                    {settingGlobal?.GenderDatasource.length ? (
                      <div
                        className={Global.renderDynamicClass(
                          errors.gender && touched.gender,
                          'form-group form-group--gender',
                          'form-group--gender input-error-validate'
                        )}
                      >
                        <div>
                          {settingGlobal?.GenderDatasource.map((item) => {
                            return (
                              <div className='osim-radio' key={item.Key}>
                                <input
                                  type='radio'
                                  name='gender'
                                  value={item.Key}
                                  className='osim-radio-input'
                                  checked={gender === item.Key}
                                  readOnly
                                />
                                <label
                                  className='osim-radio-label'
                                  onClick={() => {
                                    setGender(item.Key);

                                    setFieldValue('gender', item.Key);
                                  }}
                                />
                                <label
                                  className='osim-radio-title'
                                  onClick={() => {
                                    setGender(item.Key);

                                    setFieldValue('gender', item.Key);
                                  }}
                                >
                                  {item.Value}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                        {errors.gender && touched.gender && <span className='error-validate'>{errors.gender}</span>}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      className={
                        settingGlobal?.UseAreaCode
                          ? `${Global.renderDynamicClass(
                            errors.phone && touched.phone,
                            'form-group',
                            'input-error-validate'
                          )} form-group--group-select-input`
                          : Global.renderDynamicClass(errors.phone && touched.phone, 'form-group', 'input-error-validate')
                      }
                    >
                      {settingGlobal?.UseAreaCode ? (
                        <PhoneNumberSelect
                          setPhoneNumberCodeEvt={(code) => (fieldPhone.current.code = code)}
                          optionValues={settingGlobal.AreaCodes}
                          currentValue={fieldPhone.current.code}
                        />
                      ) : (
                        ''
                      )}
                      <div className='form-group--input'>
                        <input
                          type='text'
                          id='phone'
                          onChange={(evt) => onHandleTelephoneChange(evt, 'phone')}
                          value={values.phone}
                          className={Global.renderDynamicClass(values['phone'], 'form-control form-control-lg', 'input-valid')}
                          name='phone'
                          placeholder={dataSources['Phone Number Label'].value}
                        />
                        <label htmlFor='phone' className='form-group__label'>
                          <Text field={dataSources['Phone Number Label']} />
                        </label>
                        {errors.phone && touched.phone && <span className='error-validate'>{errors.phone}</span>}
                      </div>
                    </div>
                    <div className='form-group'>
                      <input
                        onChange={handleChange}
                        value={values.email}
                        type='email'
                        id='email'
                        className={Global.renderDynamicClass(values['phone'], 'form-control form-control-lg form-bg-gray', 'input-valid')}
                        name='email'
                        disabled
                      />
                    </div>
                    <div className='contact-info__btn text-center'>
                      <div className='contact-info__btn__item'>
                        <button type='submit' className='btn btn-outline-CTA1'>
                          <Text field={dataSources['Save Change Text']} />
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        {isLoading && <LoadingMask />}
      </div>
      {isCheckingPOSAccount ? <LoadingMask /> : <></>}
    </>
  ) : (
    <></>
  );
};

ContactInfomation.propTypes = {
  accountUser: PropTypes.object,
  fields: PropTypes.object,
  isLoading: PropTypes.bool,
  message: PropTypes.string,
  isError: PropTypes.bool,
  settingGlobal: PropTypes.any,
  isUpdateSuccess: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  accountUser: state.singleSignOnReducer.userInfoReducer.accountUser,
  isLoading: state.singleSignOnReducer.updateAccountReducer.isLoading,
  message: state.singleSignOnReducer.updateAccountReducer.message,
  isError: state.singleSignOnReducer.updateAccountReducer.isError,
  settingGlobal: state.settingGlobalReducer.settingGlobal,
  isUpdateSuccess: state.singleSignOnReducer.updateAccountReducer.isUpdateSuccess,
}
);

export default connect(mapStateToProps)(ContactInfomation);
