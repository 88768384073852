import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import BuyNowLinkModel from './BuyNowLinkModel';
import ProductsModel from './ProductsModel';

export default class CampaignProductListingModel {
  constructor() {
    this['Buy Now Link'] = new BuyNowLinkModel();

    this['Experience In Store Button Text'] = new SitecoreTextModel();

    this['Learn More Button Text'] = new SitecoreTextModel();

    this['Products'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Link')) {
      this['Buy Now Link'].getData(dataSource['Buy Now Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Experience In Store Button Text')) {
      this['Experience In Store Button Text'].getData(dataSource['Experience In Store Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Button Text')) {
      this['Learn More Button Text'].getData(dataSource['Learn More Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Products')) {
      let newProductsList = [];

      dataSource['Products'].map((element) => {
        newProductsList.push(new ProductsModel().getData(deepCopy(element) || {}));
      });

      this['Products'] = newProductsList;
    }

    return this;
  }
}
