import './DataTable.scss';

import { array, arrayOf, func, object, shape, string } from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useFormatDate } from '@utils/customsHook/useFormatDate';

const DataTable = ({ layoutData, columns, data }) => {
  const storeList = JSON.parse(window.localStorage.getItem('storeList') || '');
  const formatDateUtil = useFormatDate();

  return (
    <Table responsive className='data-table'>
      <thead className='data-table__head'>
        <tr className='data-table__row'>
          {columns.map((column) => (
            <th
              key={column.key}
              className={`data-table__data ${storeList.length === 1 && column.key.toLowerCase() === 'storename' ? 'data-table__hidden' : ''}`}
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className='data-table__body'>
        {data?.map((item, index) => (
          <tr key={index} className='data-table__row'>
            {columns.map((column) => {
              const isSubmissionDate = column.key.toLowerCase() === 'submissiondate';
              const submissionDateValue = formatDateUtil(item?.[`${column.key}`]);
              const valueItem = isSubmissionDate ? submissionDateValue : item?.[`${column.key}`];

              return (
                <td
                  key={column.key}
                  className={`data-table__data ${storeList.length === 1 && column.key.toLowerCase() === 'storename' ? 'data-table__hidden' : ''}`}
                >
                  <Link
                    className='data-table__data__link'
                    field={{ href: `${layoutData?.['Appointment Detail Page']?.value?.href}?appointment_id=${item.Id}` }}
                  >
                    {column.render ? column.render(item) : <Text field={{ value: valueItem }} />}
                  </Link>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

DataTable.propTypes = {
  columns: arrayOf(
    shape({
      key: string,
      label: string,
      render: func
    })
  ),
  data: array,
  layoutData: object
};

export default DataTable;
