import './BuyingOptions.scss';

import Proptypes from 'prop-types';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import ErrorComponent from '@components/ErrorComponent';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import LoadingMask from '@components/Share/LoadingMask';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import OmnichatTracking from '@components/Share/OmnichatTracking';
import { getBuyingOptionsData, getFreeGiftLisrt, getRelationshipId } from '@redux/actions/buyingOptions';
import { preventAddTradeInProductToCart, updateOneCartLine, updateSOCartLine } from '@redux/actions/cartDetail';
import { addToCart, addToCartV2, addToSOCart, getCurrentCart, getCurrentSOCart } from '@redux/actions/product';
import { productStartTrackingGoal } from '@redux/actions/trackingGoal/productTracking';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import {
  BALANCE_FREEGIFT_TYPE,
  DEPOSIT_FREEGIFT_TYPE,
  GA4_LOCAL_STORAGE_KEY,
  PROMO_ADDITIONAL_TYPE,
  PROMO_DEFAULT_QUANTITY,
  PROMO_FREEGIFT_TYPE,
  PROMO_RENTAL_TYPE,
  PROMO_TRADEIN_TYPE,
  PROMO_TRADEIN_TYPE_LOYALTY,
  PROMO_WARRANTY_TYPE,
  TW_SITE_NAME
} from '@utils/constant';
import { HK_SITE_NAME } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useQuery } from '@utils/customsHook/useQuery';
import global from '@utils/global';
import Global from '@utils/global';

import { addToSOCartV2 } from '../../redux/actions/product';
import { getCartNameValue } from '../../redux/epic/product/cartDetail/utils';
import LoginRegisterModal from '../CategoryLanding/LoginRegisterModal';
import { SEARCH_PARAMS_CART_NAME_KEY } from '../ClientPortal/config';
import BOAdditionalOptions from './BOAdditionalOptions';
import BOAddOnDeal from './BOAddOnDeal';
import useAddOnDealReducer from './BOAddOnDeal/useAddOnDealReducer';
import BOAddToCartButton from './BOAddToCartButton';
import BOConfirmDemoProduct from './BOConfirmDemoProduct';
import BOFreeGifts from './BOFreeGifts';
import BOHeader from './BOHeader';
import BOImages from './BOImages';
import BOPaymentInfo from './BOPaymentInfo';
import BORentals from './BORentals';
import BOTradeIn from './BOTradeIn';
import BOVariant from './BOVariants';
import BOWarranty from './BOWarranty';
import BuyingOptionsShimmer from './BuyingOptionsShimmer';
import { IS_ADDED_TO_CART_SESSION_STORAGE_NAME } from './configs';
import { useSetIsAddedToCartValue } from './hooks/useSetIsAddedToCartValue';
import BuyingOptionsFieldsModel from './model/BuyingOptionsFieldsModel';

const BuyingOptions = (props) => {
  const {
    fields,
    productDetailUrl,
    productOverview,
    productName,
    productId,
    catalogName,
    currencySetting,
    isDoneGetData,
    isLoading,
    isDeposit,
    isLoadingCurrentCart,
    isActionSuccess,
    isToggleChangePage,
    isFindCartLine,
    cartLineIdToSuccessPage,
    isError,
    Acknowledgement,
    errorMsg,
    ProductPageId,
    ProductItemId,
    HasInstallmentPlan,
    CategoryName,
    InstallmentPeriods,
    CurrencyCode,
    Discount,
    SellingPrice,
    ListPrice,

    variantList,
    variantActive,
    variantSelectedLength,
    variantPropertyName,
    chooseVariantLabel,
    validationMessage,

    warrantyList,
    warrantyActive,
    warrantySelectedLength,

    freeGiftData,
    freeGiftsList,
    freeGiftsActiveID,
    freeGiftsSelectedID,
    freeGiftsSelectedLength,

    tradeInList,
    tradeInActive,
    deliveryOptions,
    EditQuantity,
    tradeInSelectedLength,
    uploadedFile,

    rentalList,
    rentalActive,
    rentalSelectedLength,

    additionalOptionsList,
    additionalOptionsActive,

    currentCart,

    freeGiftDepositSelected,

    soCartStatuses,
    dataDeposit
  } = props;
  const itemId = SitecoreContextFactoryService.getValueContextRouteItem('itemId');
  const isTWSite = SitecoreContextFactoryService.getValueContextItem('site')?.name === 'OTW';
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const query = useQuery();
  const isLogin = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.isLogin);

  const dispatch = useDispatch();
  const [firstLoading, setFirstLoading] = useState(true);

  const [dataFields, setDataFields] = useState(false);

  const [isEditing, setEditing] = useState(false);

  const [cartlineID, setCartLineID] = useState(null);

  const [productPageID, setProductPageID] = useState(null);

  const [checkValidate, setCheckValidate] = useState(false);

  const [confirm, setConfirm] = useState(false);

  const [trackingCode, setTrackingCode] = useState(false);

  const [validateList, setValidateList] = useState({
    Variant: false,
    AdditionalOptions: { ...additionalOptionsActive },
    Rental: false,
    Warranty: false,
    FreeGift: false,
    Confirm: false
  });

  const variantListRef = useRef(null);
  const warrantyListRef = useRef(null);
  const freeGiftListRef = useRef(null);
  const confirmProductRef = useRef(null);
  const rentalListRef = useRef(null);
  const additionalOptionsListRef = useRef(null);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const [LoadedGTM, setLoadedGTM] = useState(false);
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const isTurnOnOmnichat = SitecoreContextFactoryService.getValueContextItem('HKOmnichat');
  const isTWTurnOnOmnichat = SitecoreContextFactoryService.getValueContextItem('TWOmnichat');
  const isGA4 = Global.getIsGA4();
  const setIsAddedToCartValue = useSetIsAddedToCartValue();
  const queryParam = useQuery();
  const [addOnDealProductAdded, setAddOnDealProductAdded] = useState([]);
  const [state, dispatchAddOnDealData] = useAddOnDealReducer();
  const [isShowLoginPopup, setIsShowLoginPopup] = useState(false);

  const handleClosePopupLogin = () => setIsShowLoginPopup(false);

  const isLoyalty = useMemo(() => {
    return JSON.parse(queryParam.get('isLoyalty') || false);
  }, [queryParam]);

  useEffect(() => {
    if (isDoneGetData) {
      setValidateList({
        Variant: variantList?.length ? (variantSelectedLength > 0 ? true : false) : true,
        // AdditionalOptions: Object.keys(additionalOptionsList).length ? (Object.values(additionalOptionsActive).length === Object.values(additionalOptionsList).length ? true : false) : true,
        AdditionalOptions: Object.keys(additionalOptionsList).length ? { ...additionalOptionsActive } : true,
        Rental: rentalList.length ? (rentalSelectedLength > 0 ? true : false) : true,
        Warranty: warrantyList?.length ? (warrantySelectedLength > 0 ? true : false) : true,
        // FreeGift:
        //   freeGiftsList?.length || (freeGiftData?.DepositFreeGifts?.length && freeGiftData?.BalanceFreeGifts?.length)
        //     ? freeGiftsSelectedLength >= freeGiftData?.DepositFreeGifts?.length && freeGiftsSelectedLength >= freeGiftData?.BalanceFreeGifts?.length
        //     : false,
        FreeGift: freeGiftsList?.length ? freeGiftsSelectedLength >= freeGiftsList?.length : true,
        Confirm: Acknowledgement.length > 0 ? confirm : true
      });
    }
  }, [
    variantSelectedLength,
    warrantySelectedLength,
    freeGiftsSelectedLength,
    freeGiftsList,
    rentalSelectedLength,
    Object.values(additionalOptionsActive).length,
    checkValidate,
    isDoneGetData,
    confirm
  ]);

  useEffect(() => {
    const buyingOptionsFieldsModel = new BuyingOptionsFieldsModel();

    setDataFields(buyingOptionsFieldsModel.getData(fields || {}));

    if (!global.getGA4Params()) {
      window.localStorage.setItem(GA4_LOCAL_STORAGE_KEY, JSON.stringify({}));
    }
  }, []);

  useEffect(() => {
    const fullPath = window.location.href;

    const dataType = global.getQueryParamsNoLower(fullPath, 'dataType') || null;

    const variantId = global.getQueryParams(fullPath, 'variantId') || null;

    const productPageId = global.getQueryParams(fullPath, 'productPageId') || null;

    const cartLineId = global.getQueryParams(fullPath, 'cartLineId') || null;

    setCartLineID(cartLineId);

    setProductPageID(productPageId);

    dispatch(getBuyingOptionsData(productPageId, variantId, dataType));
  }, []);

  useDidUpdateEffect(() => {
    let relationshipIdArr = [];

    dataFields['Additional Options Setting'].fields['Allowed Options'].forEach((item) => {
      relationshipIdArr.push(item.fields.Relationship.fields['Field Name'].value);
    });

    dispatch(getRelationshipId(relationshipIdArr));
  }, [dataFields]);

  useDidUpdateEffect(() => {
    if (isDoneGetData && cartlineID) {
      setEditing(true);
      if (isClientTelingUser) {
        dispatch(getCurrentSOCart());
      } else {
        dispatch(getCurrentCart());
      }
    }
  }, [isDoneGetData, isClientTelingUser]);

  useDidUpdateEffect(() => {
    // if (!isEditing) {
    //   //In Add Page
    if (isActionSuccess) {
      const LstName = new URLSearchParams(window.location.search).get('LstName');
      const cartName = getCartNameValue();
      if (!isEditing) setIsAddedToCartValue(true);
      if (productPageID) query.set('productid', productPageID);
      if (cartLineIdToSuccessPage) query.set('cartLineId', cartLineIdToSuccessPage);
      if (LstName) query.set('LstName', LstName);
      if (cartName) query.set(SEARCH_PARAMS_CART_NAME_KEY, cartName);

      window.location.href = `${dataFields['Add To Cart Success Page'].value.href}?${query.toString()}`;
    }
    // } else {
    //   //In Editing Page
    //   if (isActionSuccess) {
    //     window.location = '/cart';
    //   }
    // }
  }, [isToggleChangePage]);

  const onGetAddtionalOptionsPrice = (additionalOptionsActive) => {
    let total = 0;

    let activeOptions = [];

    Object.keys(additionalOptionsActive).forEach((relationshipId) => {
      let activeOption = additionalOptionsList?.[relationshipId]?.Products.find((product) => {
        return product.ProductId === additionalOptionsActive?.[relationshipId];
      });

      activeOptions.push(activeOption);
    });

    activeOptions.forEach((option) => {
      total = total + option?.ListPrice;
    });

    return total;
  };

  const totalSellingPrice =
    (isDeposit ? dataDeposit?.DepositPrice || 0 : variantActive?.Price?.SellingPrice || 0) +
    (rentalActive?.Price.SellingPrice || 0) +
    (warrantySelectedLength ? warrantyActive?.Price?.ListPrice || 0 : 0) +
    (Object.keys(additionalOptionsActive).length ? onGetAddtionalOptionsPrice(additionalOptionsActive) : 0) +
    (isLoyalty || tradeInActive?.type === PROMO_TRADEIN_TYPE_LOYALTY
      ? tradeInActive?.selectedTradeInProduct?.LoyaltyPrice || 0
      : tradeInActive?.selectedTradeInProduct?.SellingPrice || 0) +
    state.totalSellingPrice || 0;

  const totalSellingPriceText = `${Global.formatDynamicCurrency(
    totalSellingPrice,
    currencySetting?.Symbol,
    currencySetting?.SymbolPosition,
    currencySetting?.CurrencyNumberFormatCulture,
    currencySetting?.NumberFormat?.CurrencyDecimalDigits
  )}`;

  const totalListPrice =
    (variantActive?.Price?.ListPrice || 0) +
    (warrantySelectedLength ? warrantyActive?.Price?.ListPrice || 0 : 0) +
    (rentalActive?.Price.ListPrice || 0) +
    (Object.keys(additionalOptionsActive).length ? onGetAddtionalOptionsPrice(additionalOptionsActive) : 0) +
    // Remove tradeIn price from total list price with new req OSIM-5616
    // (tradeInActive?.selectedTradeInProduct?.ListPrice || 0) +
    state.totalListPrice || 0;

  const totalListPriceText = `${Global.formatDynamicCurrency(
    totalListPrice,
    currencySetting?.Symbol,
    currencySetting?.SymbolPosition,
    currencySetting?.CurrencyNumberFormatCulture,
    currencySetting?.NumberFormat?.CurrencyDecimalDigits
  )}`;

  const onAddToCartHandler = () => {
    let newPromotionCartLines = [];

    const queryParams = new URLSearchParams(window.location.search);
    const pdpPromoCode = queryParams.get('cCode');
    if (freeGiftsSelectedID?.length) {
      freeGiftsSelectedID.map((item) => {
        newPromotionCartLines.push({
          Slot: item.Line,
          ProductId: item.ProductId,
          VariantId: item.VariantId,
          CatalogName: catalogName,
          Type: PROMO_FREEGIFT_TYPE
        });
      });
    }

    //Push Warranty
    if (warrantySelectedLength > 0) {
      newPromotionCartLines.push({
        ProductId: warrantyActive?.ProductId,
        CatalogName: catalogName,
        VariantId: '',
        Type: PROMO_WARRANTY_TYPE
      });
    }

    //Push Rental
    if (rentalSelectedLength > 0) {
      newPromotionCartLines.push({
        ProductId: rentalActive.ProductId,
        CatalogName: catalogName,
        Quantity: 1,
        Type: PROMO_RENTAL_TYPE
      });
    }

    //Push AdditionalOptions
    if (Object.values(additionalOptionsActive).length > 0) {
      Object.keys(additionalOptionsActive).forEach((options) => {
        newPromotionCartLines.push({
          ProductId: additionalOptionsActive[options],
          CatalogName: catalogName,
          VariantId: '',
          RelationshipId: options,
          Type: PROMO_ADDITIONAL_TYPE
        });
      });
    }

    // Push TradeInOptions
    if (tradeInActive && Object.values(tradeInActive?.values).length > 0) {
      newPromotionCartLines.push({
        ProductId: tradeInActive?.selectedTradeInProduct?.ProductId,
        CatalogName: 'OSG',
        Quantity: '1',
        // VariantId: tradeInActive?.selectedTradeInProduct?.VariantId,
        VariantId: '',
        Type: isLoyalty || tradeInActive.type === PROMO_TRADEIN_TYPE_LOYALTY ? PROMO_TRADEIN_TYPE_LOYALTY : PROMO_TRADEIN_TYPE,
        TradeInImage: tradeInActive.values.imageTradeInProduct.map((image) => image.url).join(';'),
        TradeInProductBrand: tradeInActive?.values?.brand,
        TradeInProductName: tradeInActive?.values?.productName,
        TradeInProductModel: tradeInActive?.values?.model
      });
    }

    // Push Deposit & Balance Free Gift
    const depositFreeGiftsKeys = Object.keys(freeGiftDepositSelected);
    if (depositFreeGiftsKeys.length) {
      for (const key of depositFreeGiftsKeys) {
        if (freeGiftDepositSelected[key] && Object.keys(freeGiftDepositSelected[key]).length) {
          newPromotionCartLines.push({
            ProductId: freeGiftDepositSelected[key].ProductId,
            CatalogName: catalogName,
            VariantId: freeGiftDepositSelected[key].VariantId,
            Type: key === 'DepositFreeGifts' ? DEPOSIT_FREEGIFT_TYPE : BALANCE_FREEGIFT_TYPE
          });
        }
      }
    }

    const defaultParams = {
      LineUniqueId: isEditing ? cartlineID : null,
      ProductId: productId,
      VariantId: variantActive.VariantId,
      ProductPageId: ProductPageId,
      ProductItemId: ProductItemId,
      VariantItemId: variantActive.VariantItemId,
      CatalogName: catalogName,
      Quantity: isEditing ? EditQuantity : PROMO_DEFAULT_QUANTITY,
      PromotionCartLines: newPromotionCartLines,
      PdpPromotionCode: pdpPromoCode,
      // DepositFreeGift: freeGiftDepositSelected?.DepositFreeGifts?.ProductId,
      // BalanceFreeGift: freeGiftDepositSelected?.BalanceFreeGifts?.ProductId

    };

    let params = { ...defaultParams };

    if (addOnDealProductAdded.length) {
      params = [{ ...defaultParams }];
      for (const productItem of addOnDealProductAdded) {
        params = [
          ...params,
          {
            LineUniqueId: isEditing ? cartlineID : null,
            ProductId: productItem.ProductId,
            VariantId: productItem.VariantId,
            ProductPageId: productItem.ProductPageId,
            ProductItemId: productItem.ProductItemId,
            VariantItemId: productItem.VariantItemId,
            CatalogName: productId.CatalogName,
            Quantity: productItem.Quantity,
            PromotionCartLines: [],
            PdpPromotionCode: pdpPromoCode
          }
        ];
      }
    }

    if (isEditing && isFindCartLine) {
      if (isClientTelingUser) {
        const updateParams = {
          Lines: [{ ...params }]
        };

        dispatch(updateSOCartLine(updateParams));
      } else {
        dispatch(updateOneCartLine(params));
      }
    } else {
      setLoadedGTM(!LoadedGTM);

      if (isClientTelingUser) {
        if (!addOnDealProductAdded.length) dispatch(addToSOCart({ ...params, soCartStatuses }));
        else dispatch(addToSOCartV2({ Lines: [...params], soCartStatuses }));
      } else {
        if (!addOnDealProductAdded.length) dispatch(addToCart(params));
        else dispatch(addToCartV2({ Lines: [...params] }));
      }
      // IF CART ALREADY HAS LINE EQUAL WITH LINE IN BUYINGOPTION
      // if (currentCart.Lines.some((line) => line.ProductId === params.ProductId)) {
      //   let tmpLine = currentCart.Lines.filter((line) => line.ProductId === params.ProductId)?.[0]; // GET LINE IN CART THAT EQUAL WITH LINE IN BUYINGOPTION
      //   if (
      //     tmpLine.PromotionCartLines.some((promotionCartLine) => promotionCartLine.Type === PROMO_TRADEIN_TYPE) && // IF LINE IN CART HAS TRADE-IN AND LINE IN BUYINGOPTION HAS TRADE-IN
      //     params.PromotionCartLines.some((paramsLine) => paramsLine.Type === PROMO_TRADEIN_TYPE)
      //   ) {
      //     dispatch(preventAddTradeInProductToCart(messageObj['Msg_020']));
      //   } else {
      //     dispatch(addToCart(params));
      //   }
      // } else {
      //   dispatch(addToCart(params));
      // }
    }
  };

  useDidUpdateEffect(() => {
    if (window['google_tag_manager'] && !isGA4) {
      window.dataLayer = window.dataLayer || [];
      let obj = {
        event: 'addToCart',
        ecommerce: {
          currencyCode: variantActive?.Price?.CurrencyCode,
          add: {
            products: [
              {
                name: productName,
                id: productId,
                price: variantActive?.Price?.SellingPrice.toString(),
                category: CategoryName,
                quantity: 1
              }
            ]
          }
        }
      };

      // function gtag() {
      //   window.dataLayer.push(arguments);
      // }

      // window.dataLayer.push({
      //   event: 'addToCart',
      //   ecommerce: {
      //     currencyCode: variantActive?.Price?.CurrencyCode,
      //     add: {
      //       products: [
      //         {
      //           name: productName,
      //           id: productId,
      //           price: variantActive?.Price?.SellingPrice.toString(),
      //           category: CategoryName,
      //           quantity: 1
      //         }
      //       ]
      //     }
      //   }
      // });

      // gtag(obj);

      window.dataLayer.push(obj);

      // window.dataLayer.push(arguments);

      // new GoogleAnalytic().ggGateWay(obj);

      // const script = document.createElement('script');

      // script.textContent = `gtag("event","addToCart",${JSON.stringify(obj)});`;
      // script.textContent =
      //   'gtag("event","addToCart",{"ecommerce":{"currencyCode":"HKD","add":{"products":[{"name":"uDiva 3 Massage Sofa (Color Gray delivery period: Mid-Dec)","id":"HK_OS8211","price":"13699","category":"","quantity":1}]}}});';

      // document.head.appendChild(script);

      // return () => {
      //   document.head.removeChild(script);
      // };
    }
  }, [LoadedGTM]);

  useDidUpdateEffect(() => {
    dispatch(getFreeGiftLisrt(variantActive.VariantId));
  }, [variantActive]);

  const onCheckAdditionalOptions = (selectedDataList, optionList) => {
    let isValid = true;

    const additionalOptionSettings = fields['Additional Options Setting']?.fields?.['Allowed Options'];
    if (additionalOptionSettings) {
      additionalOptionSettings.forEach((optionSetting) => {
        const relationshipId = optionSetting.fields.Relationship.fields['Field Name'].value;
        const isRequired = optionSetting.fields.Required.value;
        if (!isRequired) return;
        const selectedValue = selectedDataList[relationshipId];
        if (!selectedValue && optionList[relationshipId]) {
          isValid = false;

          return;
        }
      });
    }

    return isValid;
  };

  const checkDepositFreeGiftSelected = useMemo(() => {
    return Object.entries(freeGiftData).every(([key, value]) => (value?.length ? freeGiftDepositSelected?.[key] : true));
  }, [freeGiftData, freeGiftDepositSelected]);

  const onClickAddToCartBtn = () => {
    // let urlParams = new URLSearchParams(new URL(window.location.href).search);
    // let productPageId = urlParams.get('productPageId');

    // dispatch(productStartTrackingGoal({
    //   'GoalId': '{D6373F94-64E3-4F6B-B924-88D2033B6431}',
    //   'UseTrackerAPI': false,
    //   'ProductId': `{${productPageId}}`
    // }));

    setFirstLoading(false);

    setCheckValidate(true);

    if (
      validateList.Variant &&
      validateList.Warranty &&
      validateList.FreeGift && checkDepositFreeGiftSelected &&
      validateList.Confirm &&
      validateList.Rental &&
      onCheckAdditionalOptions(additionalOptionsActive, additionalOptionsList)
    ) {
      if (freeGiftData?.IsDeposit) {
        if (isLogin && isDeposit) {
          onAddToCartHandler();
        } else {
          setIsShowLoginPopup(true);
        }
      } else {
        onAddToCartHandler();
      }
      // INFO: Omnichat Tracing for HK and TW site
      if (currentSiteName === HK_SITE_NAME && isTurnOnOmnichat || currentSiteName === TW_SITE_NAME && isTWTurnOnOmnichat) {
        const productAddToCartData = [
          {
            id: productId || '',
            name: productName || '',
            brand: 'Osim',
            category: CategoryName || '',
            variant: variantActive?.Color || '',
            quantity: 1,
            price: variantActive?.Price?.SellingPrice || ''
          }
        ];

        new OmnichatTracking().tracking('event', 'add_to_cart', { ['items']: productAddToCartData });
      }
    } else {
      const boHeaderHeight = 64;
      // const keys = Object.keys(validateList).filter(key => validateList[key] === false)[0];
      let keys = Object.keys(validateList);

      keys = keys.find((key) => {
        if (key === 'AdditionalOptions') {
          if (!onCheckAdditionalOptions(additionalOptionsActive, additionalOptionsList)) {
            return validateList[key];
          }
        } else {
          return validateList[key] === false;
        }
      });

      const listRefOffset = {
        Variant: variantListRef,
        Warranty: warrantyListRef,
        FreeGift: freeGiftListRef,
        Confirm: confirmProductRef,
        Rental: rentalListRef,
        AdditionalOptions: additionalOptionsListRef
      };

      window.scrollTo({
        top: listRefOffset?.[keys]?.current.getBoundingClientRect().top + window.scrollY - boHeaderHeight,
        behavior: 'smooth'
      });
    }

    if (isTWSite) {
      setTrackingCode(true);

      //BridgeWell
      window._bwq = window._bwq || [];

      window._bwq.push([
        'trackSingle',
        '687-138G445F0MCC238',
        'AddToCart',
        {
          tags: ['cart_687']
        }
      ]);
    }
  };

  useDidUpdateEffect(() => {
    //Google 事件Code
    const script = document.createElement('script');

    script.type = 'type="text/jsx"';

    script.textContent = 'gtag("event","conversion",{​"send_to": "AW-773603377/vXYvCN-92N0CELGA8fAC",​"value":1.0,​"currency":"TWD"​});';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [trackingCode]);

  useDidUpdateEffect(() => {
    //TW-Google-Code-0830
    const script = document.createElement('script');

    script.type = 'type="text/jsx"';

    script.textContent = 'gtag("event", "conversion", {"send_to": "AW-796718007/JWVnCPLBq-8CELfn8_sC","transaction_id": ""});';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [trackingCode]);

  useDidUpdateEffect(() => {
    // INF: Google analytics
    if (currentCart?.Lines) {
      const params = {
        items: Global.getItemList(currentCart.Lines)
      };

      new GoogleAnalytic().ggGateWay('event', 'buying_options', params);
    }
  }, [currentCart]);

  return (
    <Fragment>
      <input type='hidden' value={productPageID || ''} id='buying-opion-product-page-id' />
      <input type='hidden' value={itemId || ''} id='buying-opion-item-id' />
      {dataFields && !isLoadingCurrentCart ? (
        <>
          {isError ? (
            <ErrorComponent>{errorMsg}</ErrorComponent>
          ) : (
            <Fragment>
              <BOHeader
                dataFields={dataFields}
                productName={productName}
                totalSellingPriceText={totalSellingPriceText}
                totalListPriceText={totalListPriceText}
                isActivePrice={variantSelectedLength ? true : false}
              >
                <div className='buying-option-header__btn'>
                  <BOAddToCartButton
                    textFields={isEditing && isFindCartLine ? dataFields['Edit Cart Line Text'].value : dataFields['Add To Cart Text'].value}
                    onClickAddHandler={onClickAddToCartBtn}
                  />
                </div>
              </BOHeader>
              <div className='animated-slow fadeIn delay-100'>
                <div className={`${isClientTelingUser ? 'buying-option-cp' : 'buying-option'} ${checkValidate ? 'check-validate' : ''}`}>
                  <div className='container'>
                    <div className={`${isClientTelingUser ? 'buying-option-cp__wrapper' : 'row'}`}>
                      {!isClientTelingUser && (
                        <div className='col-lg-12'>
                          <a href={productDetailUrl} className='btn-link-back'>
                            {dataFields['Back To Product Detail Label'].value}
                          </a>
                        </div>
                      )}
                      {/* BuyingOptionHeader Component */}
                      <div className={`${isClientTelingUser ? 'row buying-option-cp__wrapper__img' : 'col-lg-6 buying-option__left'}`}>
                        <BOImages
                          dataFields={dataFields}
                          variantActive={variantActive}
                          productId={productId}
                          deliveryOptions={deliveryOptions}
                          productData={{
                            ProductName: productName,
                            CurrencyCode: CurrencyCode,
                            Discount: Discount,
                            CategoryName: CategoryName,
                            SellingPrice: SellingPrice,
                            ListPrice: ListPrice
                          }}
                          totalSellingPriceText={totalSellingPriceText}
                          totalListPriceText={totalListPriceText}
                        />
                        {/* ProductImage Component */}
                        {/* ShippingInfo Component */}
                      </div>
                      <div className={`${isClientTelingUser ? 'buying-option-cp__wrapper__content' : 'col-lg-6 buying-option__right'}`}>
                        {/* ChooseColor Component */}
                        <div className='buying-option__group-info'>
                          <BOVariant
                            productData={{
                              ProductId: productId,
                              ProductName: productName,
                              CategoryName: CategoryName
                            }}
                            dataFields={dataFields}
                            variantList={variantList}
                            variantActive={variantActive}
                            variantPropertyName={variantPropertyName}
                            variantSelectedLength={variantSelectedLength}
                            isShowError={checkValidate && !validateList.Variant}
                            chooseVariantLabel={chooseVariantLabel}
                            validationMessage={validationMessage}
                            setRef={variantListRef}
                          />
                          <BOAdditionalOptions
                            dataFields={dataFields}
                            additionalOptionsList={additionalOptionsList}
                            additionalOptionsActive={additionalOptionsActive}
                            isShowError={{ checkValidate: checkValidate, validateList: validateList.AdditionalOptions }}
                            setRef={additionalOptionsListRef}
                          />
                          <BOWarranty
                            dataFields={dataFields}
                            warrantyList={warrantyList}
                            warrantyActive={warrantyActive}
                            warrantySelectedLength={warrantySelectedLength}
                            isShowError={checkValidate && !validateList.Warranty}
                            setRef={warrantyListRef}
                          />
                          <BORentals
                            dataFields={dataFields}
                            rentalList={rentalList}
                            rentalActive={rentalActive}
                            rentalSelectedLength={rentalSelectedLength}
                            isShowError={checkValidate && !validateList.Rental}
                            setRef={rentalListRef}
                          />
                          <BOTradeIn
                            dataFields={dataFields}
                            tradeInList={tradeInList}
                            tradeInActive={tradeInActive}
                            tradeInSelectedLength={tradeInSelectedLength}
                          />
                          <BOFreeGifts
                            dataFields={dataFields}
                            freeGiftsList={freeGiftsList}
                            freeGiftsActiveID={freeGiftsActiveID}
                            freeGiftsSelectedID={freeGiftsSelectedID}
                            freeGiftsSelectedLength={freeGiftsSelectedLength}
                            isShowError={checkValidate && (!checkDepositFreeGiftSelected || !validateList.FreeGift)}
                            setRef={freeGiftListRef}
                            onFreeGiftData={freeGiftData}
                          />
                          {!isEditing ? (
                            <BOAddOnDeal
                              layoutData={dataFields}
                              setAddOnDealProductAdded={setAddOnDealProductAdded}
                              dispatchAddOnDealData={dispatchAddOnDealData}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        {/* PaymentInfo Component */}
                        <BOPaymentInfo
                          dataFields={dataFields}
                          variantActive={variantActive}
                          totalSellingPriceText={totalSellingPriceText}
                          totalListPriceText={totalListPriceText}
                          deliveryOptions={deliveryOptions}
                          isDeposit={isDeposit}
                          productOverview={productOverview}
                          hasInstallmentPlan={HasInstallmentPlan}
                          installmentPeriods={InstallmentPeriods}
                          totalSellingPrice={totalSellingPrice}
                          onClickAddHandler={onClickAddToCartBtn}
                        />
                        {Acknowledgement.length > 0 ? (
                          <BOConfirmDemoProduct
                            Acknowledgement={Acknowledgement}
                            confirm={confirm}
                            setConfirmHandler={(value) => setConfirm(value)}
                            isShowError={checkValidate && !validateList.Confirm}
                            setRef={confirmProductRef}
                          />
                        ) : null}
                        {!isClientTelingUser && (
                          <div className='buying-option__btn text-center'>
                            <div className='buying-option__btn__item'>
                              <BOAddToCartButton
                                textFields={
                                  isEditing && isFindCartLine ? dataFields['Edit Cart Line Text'].value : dataFields['Add To Cart Text'].value
                                }
                                onClickAddHandler={onClickAddToCartBtn}
                              />
                            </div>
                            <div className='buying-option__btn__item'>
                              <Link field={dataFields['CTA Button']} className='buying-option__btn__continue-shopping btn-link'>
                                <Text field={dataFields['CTA Button Label']}></Text>
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <LoginRegisterModal
                isShow={isShowLoginPopup}
                onCloseHandler={handleClosePopupLogin}
                dataLayout={dataFields}
                urlLoginPage={`${dataFields?.['Login Button Link']?.value?.href}?from=${window.location.href}`}
              />
              {isLoading ? <LoadingMask></LoadingMask> : null}
            </Fragment>
          )}
        </>
      ) : (
        <Fragment>
          {firstLoading ? (
            <LoadingShimmer itemNumb={1}>
              <Fragment>
                <BuyingOptionsShimmer></BuyingOptionsShimmer>
              </Fragment>
            </LoadingShimmer>
          ) : (
            <LoadingMask></LoadingMask>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

BuyingOptions.propTypes = {
  fields: Proptypes.any,
  productOverview: Proptypes.any,
  productDetailUrl: Proptypes.any,
  productName: Proptypes.any,
  productId: Proptypes.any,
  catalogName: Proptypes.any,
  currencySetting: Proptypes.any,
  isDoneGetData: Proptypes.any,
  isLoading: Proptypes.any,
  isDeposit: Proptypes.any,
  isLoadingCurrentCart: Proptypes.any,
  isActionSuccess: Proptypes.any,
  isToggleChangePage: Proptypes.any,
  isFindCartLine: Proptypes.any,
  errorMsg: Proptypes.any,
  isError: Proptypes.any,
  Acknowledgement: Proptypes.string,
  cartLineIdToSuccessPage: Proptypes.any,
  ProductPageId: Proptypes.string,
  ProductItemId: Proptypes.string,
  HasInstallmentPlan: Proptypes.bool,
  CategoryName: Proptypes.string,
  InstallmentPeriods: Proptypes.array,
  CurrencyCode: Proptypes.string,
  Discount: Proptypes.number,
  SellingPrice: Proptypes.number,
  ListPrice: Proptypes.number,

  variantList: Proptypes.any,
  variantActive: Proptypes.any,
  variantSelectedLength: Proptypes.any,
  variantPropertyName: Proptypes.any,
  chooseVariantLabel: Proptypes.any,
  validationMessage: Proptypes.any,

  warrantyList: Proptypes.any,
  warrantyActive: Proptypes.any,
  warrantySelectedLength: Proptypes.any,

  freeGiftData: Proptypes.any,
  freeGiftsList: Proptypes.any,
  freeGiftsSelectedID: Proptypes.any,
  freeGiftsActiveID: Proptypes.any,
  freeGiftsSelectedLength: Proptypes.any,

  tradeInList: Proptypes.any,
  tradeInActive: Proptypes.any,
  tradeInSelectedLength: Proptypes.any,
  deliveryOptions: Proptypes.array,
  EditQuantity: Proptypes.any,
  uploadedFile: Proptypes.string,

  rentalList: Proptypes.array,
  rentalActive: Proptypes.any,
  rentalSelectedLength: Proptypes.number,

  additionalOptionsList: Proptypes.object,
  additionalOptionsActive: Proptypes.object,

  currentCart: Proptypes.object,

  freeGiftDepositSelected: Proptypes.object,

  soCartStatuses: Proptypes.object,
  dataDeposit: Proptypes.object
};

const mapStateToProps = (state) => {
  const BOGetDataStatusReducer = state.buyingOptionReducer.BOGetDataStatusReducer;
  const BOVariantsReducer = state.buyingOptionReducer.BOVariantsReducer;
  const BOWarrantyReducer = state.buyingOptionReducer.BOWarrantyReducer;
  const BOFreeGiftsReducer = state.buyingOptionReducer.BOFreeGiftsReducer;
  const BOTradeInReducer = state.buyingOptionReducer.BOTradeInReducer;
  const BORentalsReducer = state.buyingOptionReducer.BORentalsReducer;
  const BOAdditionalOptionsReducer = state.buyingOptionReducer.BOAdditionalOptionsReducer;
  const CartDetailReducer = state.productReducer.cartDetailReducer;

  return {
    productDetailUrl: BOGetDataStatusReducer.ProductDetailUrl || '',
    productName: BOGetDataStatusReducer.productName || '',
    productOverview: BOGetDataStatusReducer.productOverview || null,
    productId: BOGetDataStatusReducer.productId || '',
    catalogName: BOGetDataStatusReducer.catalogName || '',
    currencySetting: BOGetDataStatusReducer.currencySetting || {},
    isDoneGetData: BOGetDataStatusReducer.isDoneGetData || false,
    isLoading: BOGetDataStatusReducer.isLoading || false,
    isDeposit: BOGetDataStatusReducer.IsDeposit || false,
    isLoadingCurrentCart: BOGetDataStatusReducer.isLoadingCurrentCart || false,
    isActionSuccess: BOGetDataStatusReducer.isActionSuccess || false,
    isToggleChangePage: BOGetDataStatusReducer.isToggleChangePage || false,
    isFindCartLine: BOGetDataStatusReducer.isFindCartLine || false,
    cartLineIdToSuccessPage: BOGetDataStatusReducer.cartLineIdToSuccessPage || null,
    errorMsg: BOGetDataStatusReducer.errorMsg || '',
    isError: BOGetDataStatusReducer.isError || false,
    Acknowledgement: BOGetDataStatusReducer.Acknowledgement || '',
    ProductPageId: BOGetDataStatusReducer.ProductPageId || '',
    ProductItemId: BOGetDataStatusReducer.ProductItemId || '',
    HasInstallmentPlan: BOGetDataStatusReducer.HasInstallmentPlan || false,
    CategoryName: BOGetDataStatusReducer.CategoryName || '',
    InstallmentPeriods: BOGetDataStatusReducer.InstallmentPeriods || [],
    CurrencyCode: BOGetDataStatusReducer.CurrencyCode || '',
    Discount: BOGetDataStatusReducer.Discount || 0,
    SellingPrice: BOGetDataStatusReducer.SellingPrice || 0,
    ListPrice: BOGetDataStatusReducer.ListPrice || 0,

    variantList: BOVariantsReducer.variantList || [],
    variantActive: BOVariantsReducer.variantActive || null,
    variantSelectedLength: BOVariantsReducer.variantSelectedLength || 0,
    variantPropertyName: BOVariantsReducer.variantPropertyName || '',
    chooseVariantLabel: BOVariantsReducer.chooseVariantLabel || '',
    validationMessage: BOVariantsReducer.validationMessage || '',

    warrantyList: BOWarrantyReducer.warrantyList || [],
    warrantyActive: BOWarrantyReducer.warrantyActive || null,
    warrantySelectedLength: BOWarrantyReducer.warrantySelectedLength || 0,

    freeGiftData: BOFreeGiftsReducer.freeGiftData || [],
    freeGiftsList: BOFreeGiftsReducer.freeGiftsList || [],
    freeGiftsActiveID: BOFreeGiftsReducer.freeGiftsActiveID || [],
    freeGiftsSelectedID: BOFreeGiftsReducer.freeGiftsSelectedID || [],
    freeGiftsSelectedLength: BOFreeGiftsReducer.freeGiftsSelectedLength || 0,

    tradeInList: BOTradeInReducer.tradeInList || [],
    tradeInActive: BOTradeInReducer.tradeInActive || null,
    tradeInSelectedLength: BOTradeInReducer.tradeInSelectedLength || 0,
    deliveryOptions: BOGetDataStatusReducer.deliveryOptions || [],
    EditQuantity: BOGetDataStatusReducer.EditQuantity || null,
    uploadedFile: BOTradeInReducer.uploadedFile || null,

    rentalList: BORentalsReducer.rentalList || [],
    rentalActive: BORentalsReducer.rentalActive || null,
    rentalSelectedLength: BORentalsReducer.rentalSelectedLength || 0,

    additionalOptionsList: BOAdditionalOptionsReducer.additionalOptionsList || [],
    additionalOptionsActive: BOAdditionalOptionsReducer.additionalOptionsActive || null,

    currentCart: CartDetailReducer.currentCart || {},

    freeGiftDepositSelected: BOFreeGiftsReducer.freeGiftDepositSelected,

    soCartStatuses: state.cpSaleOrderStatusesReducer.soCartStatuses,
    dataDeposit: BOGetDataStatusReducer?.Deposit
  };
};

export default connect(mapStateToProps)(BuyingOptions);
