import './LeadInfo.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useFormatDate } from '@utils/customsHook/useFormatDate';

const LeadInfo = ({ leadManagementModelFields, leadManagementData, isCPManager }) => {
  const formatDateUtil = useFormatDate();

  return (
    leadManagementModelFields && (
      <div className='lead-info'>
        <div className='lead-info__group lead-info__group--mg-bt-lg lead-info__group--space-between'>
          <div className='lead-info__group__left'>
            <h4 className='lead-info__name'>{leadManagementData.CustomerName || ''}</h4>
            <span className='lead-info__type'>{leadManagementData.CustomerType || ''}</span>
          </div>
          <a href={leadManagementData.DetailLink} className='lead-info__view-detail text-blue link-nomal'>
            <Text field={leadManagementModelFields['View Detail Text']}></Text>
          </a>
        </div>
        <div className='lead-info__group'>
          <div className='lead-info__group__block'>
            <Text tag='span' className='lead-info__label' field={leadManagementModelFields['Date Label']}></Text>
            <span className='lead-info__value'>{` ${formatDateUtil(leadManagementData.CreatedDate)}`}</span>
          </div>
          <div className='lead-info__group__block'>
            <Text tag='span' className='lead-info__label' field={leadManagementModelFields['Store Label']}></Text>
            <span className='lead-info__value'>{` ${leadManagementData.StoreName || ''}`}</span>
          </div>
          <div className='lead-info__group__block'>
            <Text
              tag='span'
              className='lead-info__label'
              field={isCPManager && isCPManager != null ? leadManagementModelFields['Staff Name Label'] : ''}
            ></Text>
            <span className='lead-info__value'>{` ${isCPManager && isCPManager != null ? leadManagementData.StaffName : ''}`}</span>
          </div>
        </div>
        <div className='lead-info__line'>
          <Text tag='span' className='lead-info__label' field={leadManagementModelFields['Product Interest Label']}></Text>
          <span className='lead-info__value'>{` ${leadManagementData.ProductInterest || ''}`}</span>
        </div>
        <div className='lead-info__group lead-info__group--space-between'>
          <div className='lead-info__group__left'>
            <Text tag='span' className='lead-info__label' field={leadManagementModelFields['Total Spent Label']}></Text>
            <span className='lead-info__value'>{` ${leadManagementData.TotalSpent}`}</span>
          </div>
          <div className='lead-info__container'>
            {leadManagementData.IsSpent ? (
              <div className='lead-info__status'>
                <Text tag='span' className='lead-info__status__text' field={leadManagementModelFields['Purchased Label']}></Text>
              </div>
            ) : (
              <></>
            )}
            {leadManagementData.ByAppointment && (
              <div className='lead-info__status'>
                <Text tag='span' className='lead-info__status__text' field={leadManagementModelFields['By Appointment Label']}></Text>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

LeadInfo.propTypes = {
  leadManagementModelFields: PropTypes.any,
  leadManagementData: PropTypes.any
};

export default LeadInfo;
