import { any } from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import { createBookingExperienceContactStart } from '@redux/actions/bookingExperience/createBookingExperienceContact';
import { scheduleAppointmentStart } from '@redux/actions/bookingExperience/scheduleAppointment';
import { ScheduleAppointStatus } from '@redux/epic/bookingExperience/scheduleAppointment/responseStatus';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useQuery } from '@utils/customsHook/useQuery';

import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';
import UpdateAppointmentSuccessPopup from '../../../UpdateAppointmentSuccessPopup';
import EmailExistModal from '../EmailExistModal';
import { useGenerateBookExperienceParams } from './hooks';
import { useContactDetailFormik } from './useContactDetailFormik';

const initialStates = {
  formik: {}
};
const ContactDetailFormLayoutContext = createContext(initialStates);

export const useContactDetailFormLayoutContext = () => useContext(ContactDetailFormLayoutContext);

const ContactDetailFormLayout = ({ children }) => {
  const query = useQuery();
  const { isLoading: isScheduling, scheduleAppointmentResponse } = useSelector((state) => state.bookingExperienceReducer.scheduleAppointmentReducer);
  const { isLoading: isCreatingContact } = useSelector((state) => state.bookingExperienceReducer.createBookingExperienceContactReducer);
  const { isLoading: isGettingOtp, getScheduleOtpCodeResult } = useSelector((state) => state.bookingExperienceReducer.getScheduleOtpCodeReducer);
  const { isLoading: isUpdatingContact } = useSelector((state) => state.bookingExperienceReducer.updateBookingExperienceContactReducer);

  const { isLoading: isUpdatingBookingExperience } = useSelector((state) => state.bookingExperienceReducer.updateBookingExperienceReducer);
  const { contactData, isLoading: isGettingContactData } = useSelector((state) => state.campaignRegisterReducer.getContactReducer);
  const isCheckingExistEmail = useSelector((state) => state.checkoutProcesstReducer.accountCheckingReducer?.isLoading);

  const { scheduleData, layoutData } = useScheduleAppointmentContext();
  const generateBookingExperienceParams = useGenerateBookExperienceParams();
  const dispatch = useDispatch();
  const formik = useContactDetailFormik();

  useDidUpdateEffect(() => {
    if (contactData) {
      const contactDetail = {
        firstName: scheduleData.contactDetail.firstName,
        lastName: scheduleData.contactDetail.lastName,
        mobileNumber: scheduleData.contactDetail.mobileNumberWithPrefix || scheduleData.contactDetail.mobileNumber,
        emailAddress: scheduleData.contactDetail.emailAddress,
        dob: scheduleData.contactDetail.dob
      };
      const params = generateBookingExperienceParams(contactDetail);
      if (Object.keys(contactData).length) {
        // INFO: continue schedule appointment if contact exist
        dispatch(
          scheduleAppointmentStart({
            ...params,
            mobileNumber: contactDetail.mobileNumber.replace('+', '')
          })
        );
      } else {
        // INFO: create contact data (if not exist) before start schedule appointment
        dispatch(createBookingExperienceContactStart(params));
      }
    }
  }, [scheduleData, contactData]);

  const checkBookAppointmentSuccess = useMemo(() => {
    return (
      scheduleAppointmentResponse &&
      ScheduleAppointStatus.SuccessStatusCode.includes(scheduleAppointmentResponse?.StatusCode) &&
      scheduleAppointmentResponse?.Result
    );
  }, [scheduleAppointmentResponse]);

  useDidUpdateEffect(() => {
    if (checkBookAppointmentSuccess) {
      const appointmentId = scheduleAppointmentResponse?.Result?.Id;

      window.location.href = `${layoutData?.['Book Experience Link']?.value?.href}?appointment_id=${appointmentId}`;
    }
  }, [layoutData, scheduleAppointmentResponse, checkBookAppointmentSuccess]);

  const isLoadingStatus = useMemo(() => {
    return (
      isGettingContactData ||
      isCheckingExistEmail ||
      isScheduling ||
      isCreatingContact ||
      isGettingOtp ||
      isUpdatingBookingExperience ||
      isUpdatingContact
    );
  }, [isGettingContactData, isCheckingExistEmail, isScheduling, isCreatingContact, isGettingOtp, isUpdatingBookingExperience, isUpdatingContact]);

  return (
    <>
      <ContactDetailFormLayoutContext.Provider value={{ formik: formik }}>
        <form onSubmit={formik.handleSubmit} className='contact-detail__body'>
          <div className='register-event__form__content__section'>
            {children}
            {!getScheduleOtpCodeResult && !layoutData?.appointment ? (
              <div className='be-schedule-appointment__submit-btn'>
                <button
                  type='submit'
                  className={['btn btn-primary', isCheckingExistEmail || isGettingContactData ? 'btn-disable' : ''].join(' ')}
                  disabled={isCheckingExistEmail || isGettingContactData}
                >
                  <Text field={layoutData['Get OTP Button Text']} />
                </button>
              </div>
            ) : query.get('appointment_id') ? (
              <div className='be-schedule-appointment__submit-btn'>
                <button
                  type='submit'
                  className={['btn btn-primary', isCheckingExistEmail || isGettingContactData ? 'btn-disable' : ''].join(' ')}
                  disabled={isCheckingExistEmail || isGettingContactData}
                >
                  <Text field={layoutData['Book Experience Text']} />
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </form>
      </ContactDetailFormLayoutContext.Provider>
      <EmailExistModal />
      <UpdateAppointmentSuccessPopup />
      {isLoadingStatus ? <LoadingMask /> : <></>}
    </>
  );
};

ContactDetailFormLayout.propTypes = {
  children: any
};

export default ContactDetailFormLayout;
