import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import BuyButtonComponent from '@components/Product/BuyButtonComponent/BuyButtonComponent';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import { getImageUrl } from '@utils/getImageUrl';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

const RecentlyViewedProductItem = (props) => {
  const [buyNowLink, setBuyNowLink] = useState(deepCopy(props.dataFromLayout['Buy Now Link']));
  const [purchaseLabel, setPurchaseLabel] = useState('');
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const isUserLogin = useSelector((state) => state.singleSignOnReducer.userInfoReducer.isLogin);

  const isGA4 = Global.getIsGA4();
  const itemLstName = props?.dataFromLayout?.['Title']?.value;
  const categoryName = props?.dataFromAPI?.CategoryName;

  useEffect(() => {
    let tempLink = deepCopy(buyNowLink);

    tempLink.value.href = `${props.dataFromLayout['Buy Now Link'].value.href}?productPageId=${props.dataFromAPI.Id}&LstName=${itemLstName}&IndexGA4=${props?.indexGA4}`;

    setBuyNowLink(tempLink);

    props.dataFromAPI.IsDeposit
      ? setPurchaseLabel(props.dataFromLayout['Deposit Button Text']?.value)
      : setPurchaseLabel(props.dataFromAPI.Stock?.PurchasableLabel);
  }, []);

  const handleGa4SelectItem = (action, ev) => {
    if (isGA4) {
      const queryParams = new URLSearchParams(window.location.search);
      const pdpPromoCode = queryParams.get('cCode');
      let dataConvert = [];

      dataConvert.push(props?.dataFromAPI);
      let paramsSelectItem = {
        ecommerce: {
          action: action,
          items: Global.handleGA4ViewAndSelectItems(props?.indexGA4, dataConvert, '', pdpPromoCode, '', '',  itemLstName)
        }
      };

      new GoogleAnalytic().gAnalytic4('event', 'select_item', paramsSelectItem);
    }

    // Handle show pop-up login
    if (!isUserLogin && props.dataFromAPI.IsDeposit) {
      ev.preventDefault();

      props.handleOpenPopupLogin(buyNowLink);
    }
  };

  return (
    <Fragment>
      <div className='recently-viewed-products__content-wrapper'>
        {/* <div className='recently-viewed-products__thumb'>
          <picture className='recently-viewed-products__thumb__img'>
            <img
              className='swiper-lazy animated'
              src={props.dataFromAPI?.Variants?.[0]?.Images[0]}
              className='product-variant__color__item__image'
              alt='no alt'
            />
          </picture> */}
        {/* <div className='swiper-lazy-preloader swiper-lazy-preloader-white' /> */}
        {/* </div> */}
        <a
          className='recently-viewed-products__image'
          href={`${props.dataFromAPI.Url}?IndexGA4=${props?.indexGA4}&CategoryName=${categoryName}&LstName=${itemLstName}&CategoryPageId=${props?.dataFromAPI?.CategoryPageId}`}
          onClick={() => handleGa4SelectItem('Image')}
        >
          <img
            className='recently-viewed-products__image__item'
            src={getImageUrl(props.dataFromAPI?.Image, props.dataFromAPI?.Variants?.[0]?.Images[0])}
            alt='Product Image'
            title='No title'
          />
        </a>
        <ul className='list-tags'>
          {props.dataFromAPI?.Variants?.[0]?.Labels?.length ? (
            props.dataFromAPI?.Variants?.[0]?.Labels?.map((item) => {
              return (
                <li className={`list-tags__item osim-label ${item.IsSpecial ? 'osim-label--highlight' : 'osim-label--default'}`} key={item.Code}>
                  {item.Name}
                </li>
              );
            })
          ) : (
            <></>
          )}
        </ul>
        <h4 className='recently-viewed-products__name'>
          <a
            href={`${props.dataFromAPI.Url}?IndexGA4=${props?.indexGA4}&CategoryName=${categoryName}&LstName=${itemLstName}&CategoryPageId=${props?.dataFromAPI?.CategoryPageId}`}
            onClick={() => handleGa4SelectItem('Image')}
          >
            {props.dataFromAPI?.ProductName}
          </a>
        </h4>
        {props?.dataFromAPI?.Deposit ? (
          <div className='recently-viewed-products__selling-price'>{props?.dataFromAPI?.Deposit?.DepositPriceWithCurrency}</div>
        ) : (
          <>
            <div className='recently-viewed-products__selling-price'>
              {props.dataFromAPI?.IsBundle
                ? props.dataFromAPI?.Price?.SellingPriceWithCurrency
                : props.dataFromAPI?.Variants?.[0]?.Price?.SellingPriceWithCurrency}
            </div>
            {props.dataFromAPI?.IsBundle ? (
              props.dataFromAPI?.Price?.SellingPriceWithCurrency !== props.dataFromAPI?.Price?.ListPriceWithCurrency ? (
                <div className='recently-viewed-products__list-price'>{props.dataFromAPI?.Price?.ListPriceWithCurrency}</div>
              ) : (
                <></>
              )
            ) : props.dataFromAPI?.Variants?.[0]?.Price?.SellingPriceWithCurrency !==
              props.dataFromAPI?.Variants?.[0]?.Price?.ListPriceWithCurrency ? (
                <div className='recently-viewed-products__list-price'>{props.dataFromAPI?.Variants?.[0]?.Price?.ListPriceWithCurrency}</div>
              ) : (
                <></>
              )}
          </>
        )}
      </div>
      <div className='recently-viewed-products__content-wrapper recently-viewed-products__btn-group'>
        {props.dataFromAPI?.IsBundle ? (
          <a className='recently-viewed-products__button btn btn-primary' href={props.dataFromAPI.Url}>
            {messageObj?.['LBL-LearnMore']}
          </a>
        ) : (
          <BuyButtonComponent
            productData={{
              ...props.dataFromAPI,
              productId: `${props.dataFromAPI.ProductId}|${props.dataFromAPI.RepresentativeVariantId}`
            }}
          >
            <Link
              field={buyNowLink}
              className='recently-viewed-products__button btn btn-primary'
              onClick={(ev) => handleGa4SelectItem('Buy Now', ev)}
            >
              {/* <Text field={props.dataFromLayout['Buy Now Text']} tag='span' editable={true} /> */}
              {purchaseLabel}
            </Link>
          </BuyButtonComponent>
        )}
      </div>
    </Fragment>
  );
};

RecentlyViewedProductItem.propTypes = {
  dataFromAPI: PropTypes.object,
  dataFromLayout: PropTypes.object,
  indexGA4: PropTypes.number,
  handleOpenPopupLogin: PropTypes.func
};

export default RecentlyViewedProductItem;
