import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import ProductListingItemLi from '@components/CategoryLanding/ProductListing/ProductListingItem/ProductListingItemLi';
import BuyButtonComponent from '@components/Product/BuyButtonComponent/BuyButtonComponent';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

const CampaignProductListingItem = (props) => {
  const [buyNowLink, setBuyNowLink] = useState(null);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);

  const isGA4 = Global.getIsGA4();
  const itemLstName = SitecoreContextFactoryService.getValueContextRouteItem('fields')['Title']?.value;
  const promotionId = SitecoreContextFactoryService.getValueContextItem('itemId');

  useEffect(() => {
    let temp = deepCopy(props.dataFromLayout);
    const queryParams = new URL(`${window.location.origin}${props.data.Url}`);

    temp['Buy Now Link'].fields.Link.value.href = `${props.dataFromLayout['Buy Now Link'].fields.Link.value.href}?productPageId=${props.data.Id}${
      props.data['IsRentalProduct'] ? '&datatype=RefunableDeposit' : ''}${queryParams?.searchParams?.get('cCode') ? '&cCode=' + queryParams.searchParams.get('cCode') : ''}&LstName=${itemLstName || ''}`;

    setBuyNowLink(temp);
  }, []);

  const handleGa4SelectItem = (action) => {
    if (isGA4) {
      const queryParams = new URLSearchParams(window.location.search);
      const pdpPromoCode = queryParams.get('cCode');
      let dataConvert = [];

      dataConvert.push(props?.data);
      let paramsSelectItem = {
        ecommerce: {
          action: action,
          items: Global.handleGA4ViewAndSelectItems(props?.indexGA4, dataConvert, '', pdpPromoCode, '', '', itemLstName)
        }
      };

      new GoogleAnalytic().gAnalytic4('event', 'select_item', paramsSelectItem);
    }
  };

  return (
    buyNowLink && (
      <Fragment>
        {props.data.ProductType !== 'Product' ? (
          <article className={`${props.customClass}`}>
            <div className='article__image'>
              <a href={`${props.data.Url}?IndexGA4=${props.indexGA4}&CategoryName=${props.data.CategoryName}&LstName=${itemLstName}&CategoryPageId=${props?.data?.CategoryPageId}`} onClick={() => handleGa4SelectItem('Image')}>
                <img alt={props.data.CategoryName ? props.data.CategoryName : 'campaign item image'} src={props.data.Image} />
              </a>
              <ul className='category-product__list-tag article__list-tag list-tag'>
                {props.data.Labels.length > 0 &&
                  props.data.Labels.map((item, index) => {
                    if (index < 4) {
                      return <ProductListingItemLi data={item} key={index} />;
                    }
                  })}
              </ul>
            </div>
            <div className='article__content'>
              <span className='info-stock-message'>{props.data.LastFewUnitsLeftLabel}</span>
              <h3 className='article__content__title'>
                <a className='article__content__title-link' href={`${props.data.Url}?IndexGA4=${props.indexGA4}&CategoryName=${props.data.CategoryName}&LstName=${itemLstName}&CategoryPageId=${props?.data?.CategoryPageId}`} onClick={() => handleGa4SelectItem('Image')}>
                  {props.data.ProductName}
                </a>
              </h3>
              <div className='article__content__price'>
                <span className='article__content__price--main'>{`${props.data['IsRentalProduct'] ? messageObj['LBL-RentalPrice'] : ''} ${
                  props.data.Price.SellingPriceWithCurrency
                } ${props.data.PriceExtraInformation}`}</span>
                {props.data.Price.SellingPriceWithCurrency === props.data.Price.ListPriceWithCurrency ? (
                  <></>
                ) : (
                  <span className='article__content__price--old'>{props.data.Price.ListPriceWithCurrency}</span>
                )}
                {props.data['IsRentalProduct'] ? (
                  <span className='article__content__price--old rental-deposit'>{`${messageObj['LBL-Deposit']} ${props.data?.RefundableDepositProducts?.[0]?.Price?.SellingPriceWithCurrency} ${messageObj['LBL-Refundable']}`}</span>
                ) : (
                  <></>
                )}
              </div>
              {/* <p className='article__content__description'>{props.data.Description}</p> */}
              <RichText className='article__content__description' field={{ value: props.data.Description }} />
              {props.data?.IsBundle ? (
                <></>
              ) : (
                <a className='article__content__link btn-link' href={props.data?.Url}>
                  <span>{props.dataFromLayout?.['Learn More Button Text'].value}</span>
                </a>
              )}
              <div className='article__content__action'>
                <a href={props.data.Url} className='btn btn-outline-CTA2'>
                  <Text tag='span' field={props.dataFromLayout['Learn More Button Text']} />
                </a>
              </div>
            </div>
          </article>
        ) : (
          <article className={`${props.customClass}`}>
            <div className='article__image'>
              <a href={`${props.data.Url}?IndexGA4=${props.indexGA4}&CategoryName=${props.data.CategoryName}&LstName=${itemLstName}&CategoryPageId=${props?.data?.CategoryPageId}`} onClick={() => handleGa4SelectItem('Image')}>
                <img alt={props.data.CategoryName ? props.data.CategoryName : 'campaign item image'} src={props.data.Image} />
              </a>
              <ul className='category-product__list-tag article__list-tag list-tag'>
                {props.data.Labels.length > 0 &&
                  props.data.Labels.map((item, index) => {
                    if (index < 4) {
                      return <ProductListingItemLi data={item} key={index} />;
                    }
                  })}
              </ul>
            </div>
            <div className='article__content'>
              <span className='info-stock-message'>{props.data.LastFewUnitsLeftLabel}</span>
              <h3 className='article__content__title'>
                <a className='article__content__title-link' href={`${props.data.Url}?IndexGA4=${props.indexGA4}&CategoryName=${props.data.CategoryName}&LstName=${itemLstName}&CategoryPageId=${props?.data?.CategoryPageId}`} onClick={() => handleGa4SelectItem('Image')}>
                  {props.data.ProductName}
                </a>
              </h3>
              <div className='article__content__price'>
                <span className='article__content__price--main'>{`${props.data['IsRentalProduct'] ? messageObj['LBL-RentalPrice'] : ''} ${
                  props.data.Price.SellingPriceWithCurrency
                } ${props.data.PriceExtraInformation}`}</span>
                {props.data.Price.SellingPriceWithCurrency === props.data.Price.ListPriceWithCurrency ? (
                  <></>
                ) : (
                  <span className='article__content__price--old'>{props.data.Price.ListPriceWithCurrency}</span>
                )}
                {props.data['IsRentalProduct'] ? (
                  <span className='article__content__price--old rental-deposit'>{`${messageObj['LBL-Deposit']} ${props.data?.RefundableDepositProducts?.[0]?.Price?.SellingPriceWithCurrency} ${messageObj['LBL-Refundable']}`}</span>
                ) : (
                  <></>
                )}
              </div>
              {/* <p className='article__content__description'>{props.data.Description}</p> */}
              <RichText className='article__content__description' field={{ value: props.data.Description }} />
              {props.data?.IsBundle ? (
                <></>
              ) : (
                <a className='article__content__link btn-link' href={props.data?.Url}>
                  <span>{props.dataFromLayout?.['Learn More Button Text'].value}</span>
                </a>
              )}
              <div className='article__content__action'>
                {/* //INFO: BE confirm not done in this sprint */}
                {/* <a href={props.data.Url} className='btn btn-outline-CTA2'>
                  <Text tag='span' field={props.dataFromLayout['Experience In Store Button Text']} />
                </a> */}
                <BuyButtonComponent
                  productData={{
                    ...props.data,
                    productId: `${props.data.Id}|${props.data.RepresentativeVariantId}`
                  }}
                >
                  <Link className='category-product__main__btn__buy-options btn btn-primary' field={buyNowLink['Buy Now Link']?.fields?.Link} onClick={() => handleGa4SelectItem('Buy Now')}>
                    {props.data['IsRentalProduct'] ? <span>{messageObj['LBL-RentNowLabel']}</span> : <span>{props.data.Stock.PurchasableLabel}</span>}
                  </Link>
                </BuyButtonComponent>
              </div>
            </div>
          </article>
        )}
      </Fragment>
    )
  );
};

CampaignProductListingItem.propTypes = {
  customClass: PropTypes.string,
  data: PropTypes.object,
  dataFromLayout: PropTypes.object,
  indexGA4: PropTypes.number
};

export default CampaignProductListingItem;
