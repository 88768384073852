import './ModalComponent.scss';

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import { IS_CLICK_OUTSIDE } from '@components/Share/ModalComponent/constant';

const ModalComponent = (props) => {
  const { isShowDefault, classNameDefault, cssClass, isShow, onCloseHandler, titlePopup } = props;
  const MODAL_CLASS = 'modal-dialog';
  const titleTextHidden = 'Modal dialog';

  useEffect(() => {
    if (isShow) {
      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  }, [isShow]);

  useEffect(() => {
    const element = document.getElementsByClassName('popup-banner__content--js');
    if (element[0]) {
      element[0].setAttribute('aria-labelledby', 'dialog-data');
    }
  }, [document]);

  const handleClickOutside = (event) => {
    if (event.target.className === MODAL_CLASS && isShow) {
      onCloseHandler(IS_CLICK_OUTSIDE);
    }
  };

  return (
    <Modal show={isShow ?? isShowDefault} className={`${classNameDefault} ${cssClass || ''}`}>
      <div className={'osim-popup__header'}>
        <h2 className='osim-popup__header__title'>{titlePopup}</h2>
        <span id="dialog-data" className='visually-hidden'>{titleTextHidden}</span>
        <span className='osim-popup__close-btn' onClick={onCloseHandler}>
          <i className='icon-close'></i>
        </span>
      </div>
      {props.children}
    </Modal>
  );
};

ModalComponent.defaultProps = {
  classNameDefault: 'osim-popup',
  isShowDefault: false
};

ModalComponent.propTypes = {
  isShow: PropTypes.bool,
  onCloseHandler: PropTypes.func,
  children: PropTypes.any,
  titlePopup: PropTypes.any,
  classNameDefault: PropTypes.string,
  cssClass: PropTypes.string,
  isShowDefault: PropTypes.bool
};

export default ModalComponent;
