import React from 'react';
import * as Yup from 'yup';

import { DELIVERY_OPTION } from '@utils/constant';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import Message from '../../../../Share/Message';

export const AddressService = {
  initialValuesAddress: (dataFields, addressCurrent, settingPreFills, settingGlobal, cartLineData, type, optionsForMacau, allAddressOptions) => {
    let initValue = {};

    dataFields.map((field) => {
      const key = field[config.FieldNameModel].value;
      if (!addressCurrent) {
        initValue = Object.assign(initValue, { [key]: '' });
      } else {
        const isPreFill =
          !settingPreFills || (settingPreFills && settingPreFills.find((preFillItem) => preFillItem[config.FieldNameModel].value === key)) || true;
        if (field.Type.value !== config.TypeElement.Dropdown) {
          if (field.Type.value === config.TypeElement.Phone) {
            const phone = Global.splitPhoneNumberWithoutCode(settingGlobal?.UseAreaCode, addressCurrent[key], settingGlobal?.AreaCodes);

            initValue = Object.assign(initValue, { [key]: (isPreFill && phone.value) || '' }, { [`${key}Code`]: phone.code });
          } else {
            initValue = Object.assign(initValue, { [key]: (isPreFill && addressCurrent[key]) || '' });
          }
        }
        if (field.Type.value === config.TypeElement.Dropdown) {
          if (key == 'City' && type == 'delivery-by-sf-express' && isPreFill) {
            initValue = Object.assign(initValue, {
              ['City']: {
                label: AddressService.getMacauCityData(cartLineData, optionsForMacau)?.['label'],
                value: AddressService.getMacauCityData(cartLineData, optionsForMacau)?.['value']
              }
            });
          } else if (addressCurrent[`${key}Code`] && isPreFill) {
            const isSelectedValueExist = AddressService.checkSelectedAddressValid(allAddressOptions, key, addressCurrent);
            if (isSelectedValueExist) {
              initValue = Object.assign(initValue, {
                [key]: {
                  label: addressCurrent[`${key}`],
                  value: addressCurrent[`${key}Code`]
                }
              });
            } else {
              initValue = Object.assign(initValue, { [key]: '' });
            }
          } else {
            initValue = Object.assign(initValue, { [key]: '' });
          }
        }
      }
    });

    return dataFields ? initValue : '';
  },
  validateFileld: (dataFields, settingGlobal, isNotFoundAddress, messageObj, allAddressOptions, addressCurrent) => {
    let initValue = {};
    const dataFieldsTmp = deepCopy(dataFields);

    dataFieldsTmp.map((field) => {
      const key = field[config.FieldNameModel].value;
      if (field.Type.value !== config.TypeElement.Dropdown) {
        initValue = Object.assign(initValue, { [key]: Yup.string().required('Required').trim() });
        if (key === 'PhoneNumber' && settingGlobal?.PhoneNumberMust) {
          initValue = Object.assign(initValue, {
            [key]: settingGlobal?.PhoneNumberMust
              ? Yup.string()
                .required('Required')
                .trim()
                .min(settingGlobal?.PhoneNumberMinimumMust || settingGlobal?.PhoneNumberMust, 'Invalid')
                .max(settingGlobal?.PhoneNumberMust, 'Invalid')
              : Yup.string()
                .required('Required')
                .trim()
                .min(settingGlobal?.PhoneNumberMinimumMust || settingGlobal?.PhoneNumberMust, 'Invalid')
          });
        } else if (key === 'Postcode' && settingGlobal?.ZipCodeNumberFormat) {
          initValue = Object.assign(initValue, {
            [key]: Yup.string().required('Required').trim().length(settingGlobal?.ZipCodeNumberMust, 'Invalid')
          });
        } else if (key === 'FirstName' || key === 'LastName') {
          initValue = Object.assign(initValue, {
            [key]: Yup.string()
              .required(<Message messageCode={key === 'FirstName' ? 'Msg_001.1' : 'Msg_001.2'} />)
              .matches(Global.nameValidateRegex(), messageObj[`${key === 'FirstName' ? 'Msg_001.1' : 'Msg_001.2'}`])
              .trim()
          });
        } else {
          initValue = Object.assign(initValue, { [key]: Yup.string().required('Required').trim() });
        }
      } else {
        const isAddressSelectedValid = AddressService.checkSelectedAddressValid(allAddressOptions, key, addressCurrent);

        if (!isNotFoundAddress || ((key === 'City' || key === 'State') && !isAddressSelectedValid)) {
          initValue = Object.assign(initValue, {
            [key]: Yup.string().required()
          });
        }
      }
    });

    return Yup.object(initValue);
  },
  getMacauCityData: (cartLineData, optionsForMacau) => {
    return (
      optionsForMacau &&
      optionsForMacau?.City?.find(
        (option) =>
          option?.Code ==
          cartLineData?.[0]?.DeliveryOptions?.find((option) => option?.ShippingOptionType?.Value == DELIVERY_OPTION.DeliveryBySFExpress)
            ?.AddressRootCode
      )
    );
  },
  checkSelectedAddressValid: (addressSelectionData, key, addressCurrent) => {
    let isValid = false;
    if (addressSelectionData && Object.keys(addressSelectionData).length) {
      const selectionOption = addressSelectionData?.[key] || [];
      if (selectionOption.length) {
        const isSelectedValueExist = selectionOption.find((item) => item.value === addressCurrent?.[`${key}Code`]);
        if (isSelectedValueExist) isValid = true;
      }
    }

    return isValid;
  }
};

export const config = {
  FieldNameValidate: '{Field-name}',
  FieldNameModel: 'Field Name',
  DisplayNameModel: 'Display Name',
  TypeElement: {
    Text: 'Text',
    Phone: 'Phone',
    Dropdown: 'Dropdown',
    Zipcode: 'Zipcode'
  }
};
