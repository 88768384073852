import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { getCouponWallet } from '@redux/actions/clientTellingPortalCustomer';
import { getCouponDetail } from '@redux/actions/coupon';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import BoxData from '../BoxData';
import Voucher from '../Voucher';
import CustomerVoucherShimmer from './CustomerVoucherShimmer';
import CustomerVoucherModel from './models/CustomerVoucherModel';

const MAX_SHOW_VOUCHER = 2;

const CustomerVoucher = ({fields}) => {
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const cpCustomerVoucher = useSelector((state) => state.clientTellingPortalCustomer.cpCustomerVoucher);
  const couponReducer = useSelector((state) => state.couponReducer);
  const dispatch = useDispatch();
  const [customerVoucherFields, setCustomerVoucherFields] = useState(null);
  const [ isShowVoucher, setIsShowVoucher] = useState(false);
  const [ voucherDetail, setVoucherDetail] = useState(null);
  const [ isShowvoucherDetail, setIsShowVoucherDetail] = useState(false);
  const [ isSecondPopup, setIsSecondPopup] = useState(false);

  useEffect(() => {
    const customerVoucherModel = new CustomerVoucherModel();

    setCustomerVoucherFields(customerVoucherModel.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    setVoucherDetail(couponReducer?.couponDetail?.Promotion);
  }, [couponReducer]);

  useDidUpdateEffect(() => {
    dispatch(getCouponWallet(clientTellingPortalCustomer.externalId));
  }, [clientTellingPortalCustomer.externalId]);

  const handleClickVoucher = (voucherData, isMultipPopup) => {

    setIsSecondPopup(isMultipPopup);

    let params = {
      id: voucherData.Id
    };
    if (voucherData?.Code) params.code = voucherData.Code;

    dispatch(getCouponDetail(params));

    setIsShowVoucherDetail(true);
  };

  return customerVoucherFields && (
    <>
      <div className='col-md-6 profiles__col'>
        {
          ((clientTellingPortalCustomer.externalId && !cpCustomerVoucher) || clientTellingPortalCustomer.isLoading) ? (
            <CustomerVoucherShimmer title={customerVoucherFields?.['Section Title']}/>
          ) : (
            <BoxData
              component='voucher-available'
              layoutData={customerVoucherFields}
              data={cpCustomerVoucher}
              onClickView={() => setIsShowVoucher(true)}
            >
              {
                cpCustomerVoucher && cpCustomerVoucher.map(
                  (voucher, index) => index < MAX_SHOW_VOUCHER ? (
                    <Voucher
                      key={voucher.Id || index}
                      voucher={voucher}
                      handleClickVoucher={handleClickVoucher}
                      layoutData={customerVoucherFields}
                    />
                  ) : ''
                )
              }
            </BoxData>
          )
        }
      </div>
      <ModalComponent
        isShow={isShowVoucher}
        onCloseHandler={() => setIsShowVoucher(false)}
        titlePopup={<Text field={customerVoucherFields['Pop Up Title']}/>}
        cssClass='customer-voucher-popup'
      >
        <div className='row customer-voucher__row'>
          {
            cpCustomerVoucher?.length > 0 && cpCustomerVoucher.map((voucher, index) => (
              <div className='col-md-6 customer-voucher__col' key={voucher.Id || index}>
                <Voucher
                  voucher={voucher}
                  handleClickVoucher={handleClickVoucher}
                  isMultiplePopup={true}
                  layoutData={customerVoucherFields}
                />
              </div>
            ))
          }
        </div>
      </ModalComponent>
      {/* Voucher detail popup */}
      <ModalComponent
        isShow={isShowvoucherDetail}
        onCloseHandler={() => {
          setIsShowVoucherDetail(false);

          setIsShowVoucher(isSecondPopup);
        }}
        titlePopup={<Text field={customerVoucherFields['Pop Up Title']}/>}
        cssClass='customer-voucher-popup'
      >
        <div className='voucher-detail'>
          <h3 className='voucher-detail__title'>{(voucherDetail?.Title || '')}</h3>
          <div className='text-center'>
            <Text tag='p' className='voucher-detail__hint-text text-center' field={customerVoucherFields['Hint Text']}/>
            <p className='voucher-detail__code text-center'>{voucherDetail?.Code || ''}</p>
          </div>
          <p className='voucher-detail__text'>{voucherDetail?.Description}</p>
          <p className='voucher-detail__text'>
            <Text tag='span' className='voucher-detail__text__bold' field={customerVoucherFields['Valid From Label']}/>
            {` ${Global.convertEpochTime(voucherDetail?.ValidityFrom)} `}
            <Text tag='span' className='voucher-detail__text' field={customerVoucherFields['To Label']}/>
            {` ${Global.convertEpochTime(voucherDetail?.ValidityTo)}`}
          </p>
        </div>
      </ModalComponent>
    </>
  );
};

CustomerVoucher.propTypes = {
  fields: object
};


export default CustomerVoucher;
