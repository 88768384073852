import './Register.scss';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-date-picker';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  FROM_CHECKOUT_PAGE_QUERY_STRING_NAME
} from '@components/CheckoutProcess/AccountChecking/ReturningCustomer/FormReturningCustomer';
import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import PhoneNumberSelect from '@components/Share/PhoneNumberSelect';
import DateOfBirth from '@components/SingleSignOn/DateOfBirth';
import FacebookSSO from '@components/SingleSignOn/FacebookSSO';
import GoogleSSO from '@components/SingleSignOn/GoogleSSO';
import { SPIN_URL_SESSION_STORAGE_NAME } from '@components/SpinAndWin/configs';
import { submitProductReview } from '@redux/actions/productReview';
import { register } from '@redux/actions/singleSignOn';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { MINUMUM_CHARACTERS_PASSWORD } from '@utils/constant';
import {PRODUCT_REVIEW_PAGE} from '@utils/constant';
import { REDIRECT_CHECKOUT_PARAM } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

import { FROM_PAGE_QUERY_STRING_SEPARATOR_SYMBOL } from '../index';
import { EVENT_MESSAGE, EVENT_METHOD, EVENT_NAME, EVENT_STATUS, handleTrackingEventRegisterLogin } from '../utils';
import { Yup } from './utils';

const Register = (props) => {
  const { inputData, isLoading, errorMessage, settingGlobal, isRegisterSuccess, registerError, isRegisteringNewAccount } = props;
  const dispatch = useDispatch();
  const templateName = SitecoreContextFactoryService.getValueContextRouteItem('templateName');
  const reviewFormParams = useSelector((state) => state.productReviewReducer.submitProductReviewReducer.reviewFormParams);
  const fieldPhoneCode = useRef('');
  const { search } = useLocation();
  const [datePicker, setDatePicker] = useState('');
  const [gender, setGender] = useState(null);
  const [receiveSMS, setReceiveSMS] = useState(false);
  const [receiveEmail, setReceiveEmail] = useState(false);
  const [receiveDirectMailing, setReceiveDirectMailing] = useState(false);
  const recaptchaRef = useRef(null);
  const [captchaErr, setCaptchaErr] = useState(false);
  const [confirm, setConfirm] = useState(settingGlobal?.DefaultSubscription);
  const formikRef = useRef();
  const [locationSpinPage, setLocationSpinPage] = useState('');
  const [fromTo, setFromTo] = useState('');
  const [emailSpin, setEmailSpin] = useState('');
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const [isClickedSubmitBtn, setIsClickedSubmitBtn] = useState(false);

  const [schema, setSchema] = useState(
    Yup.object({
      password: Yup.string()
        .required(<Message messageCode='Msg_001.5' />)
        .matches(Global.formatPassword(MINUMUM_CHARACTERS_PASSWORD), <Message messageCode='Msg_001.5' />),
      firstName: Yup.string()
        .required(<Message messageCode='Msg_001.1' />)
        .matches(Global.nameValidateRegex(), messageObj?.['Msg_001.1'])
        .trim(),
      lastName: Yup.string()
        .required(<Message messageCode='Msg_001.2' />)
        .matches(Global.nameValidateRegex(), messageObj?.['Msg_001.2'])
        .trim(),
      email: Yup.string()
        .required(<Message messageCode='Msg_001.3' />)
        .email(<Message messageCode='Msg_001.3' />),
      dob: Yup.string()
        .required(<Message messageCode='Msg_001.4' />)
        .trim(),
      gender: Yup.string()
        .required(<Message messageCode='Msg_019' />)
        .trim(),
      captcha: Yup.string()
        .required(<Message messageCode='Msg_001.6' />)
        .trim(),
      confirm: Yup.boolean().oneOf([true], <Message messageCode='Msg_015' />),
      emailConfirmation: Yup.string().required(inputData?.['Register Email Confirmation Required Message']?.value).oneOf([Yup.ref('email'), null], inputData?.['Register Email Confirmation Not Match Message']?.value)
    })
  );

  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    dob: '',
    gender: '',
    password: '',
    captcha: '',
    receiveSms: null,
    receiveMail: null,
    receiveDirectMail: null,
    confirm: confirm,
    emailConfirmation: ''
  };

  const handleSubmit = (values) => {
    //INFO: setEmailSpin when login from spin page
    if (locationSpinPage == fromTo) {
      setEmailSpin(values.email);
    }

    // props.setEmailRegisterEvt(values.email);
    dispatch(
      register({
        FirstName: values.firstName,
        LastName: values.lastName,
        Email: values.email,
        PhoneNumber: `${fieldPhoneCode.current || ''}${values.phone}`,
        Password: values.password,
        ReCaptchaRequest: values.captcha,
        Gender: gender?.Key || '',
        DOB: values.dob,
        ReceiveSms: receiveSMS ? receiveSMS : null,
        ReceiveEmail: receiveEmail ? receiveEmail : null,
        ReceiveDirectMail: receiveDirectMailing ? receiveDirectMailing : null,
        Subscribed: confirm
      })
    );
  };

  useEffect(() => {
    setGender(
      settingGlobal?.GenderDatasource?.find((item) => {
        return item.Key === settingGlobal?.DefaultGender;
      }) || ''
    );
  }, [settingGlobal?.DefaultGender]);

  useDidUpdateEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue('gender', gender?.Key || '');
    }
  }, [gender]);

  useEffect(() => {
    if (settingGlobal && settingGlobal?.PhoneNumberMust) {
      setSchema(
        Yup.object({
          password: Yup.string()
            .required(<Message messageCode='Msg_001.5' />)
            .matches(Global.formatPassword(MINUMUM_CHARACTERS_PASSWORD), <Message messageCode='Msg_001.5' />),
          firstName: Yup.string()
            .required(<Message messageCode='Msg_001.1' />)
            .matches(Global.nameValidateRegex(), messageObj?.['Msg_001.1'])
            .trim(),
          lastName: Yup.string()
            .required(<Message messageCode='Msg_001.2' />)
            .matches(Global.nameValidateRegex(), messageObj?.['Msg_001.2'])
            .trim(),
          email: Yup.string()
            .required(<Message messageCode='Msg_001.3' />)
            .email(<Message messageCode='Msg_001.3' />),
          dob:
            settingGlobal.IsDoBRequired &&
            Yup.string()
              .required(<Message messageCode='Msg_001.4' />)
              .trim(),
          gender:
            settingGlobal.IsGenderRequired &&
            Yup.string()
              .required(<Message messageCode='Msg_019' />)
              .trim(),
          phone: !settingGlobal.PhoneNumberMust
            ? settingGlobal.IsPhoneNumberRequired
              ? Yup.string()
                .required(<Message messageCode='Msg_020' />)
                .trim()
                .validatePhone({
                  min: settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust,
                  max: settingGlobal.PhoneNumberMust,
                  prefixCode: fieldPhoneCode.current || '',
                  errorMsg: <Message messageCode='Msg_001.7' />
                })
              : Yup.string()
                .trim()
                .validatePhone({
                  min: settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust,
                  max: settingGlobal.PhoneNumberMust,
                  prefixCode: fieldPhoneCode.current || '',
                  errorMsg: <Message messageCode='Msg_001.7' />
                })
            : settingGlobal.IsPhoneNumberRequired
              ? Yup.string()
                .required(<Message messageCode='Msg_020' />)
                .trim()
                .validatePhone({
                  min: settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust,
                  prefixCode: fieldPhoneCode.current || '',
                  errorMsg: <Message messageCode='Msg_001.7' />
                })
              : Yup.string()
                .trim()
                .validatePhone({
                  min: settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust,
                  prefixCode: fieldPhoneCode.current || '',
                  errorMsg: <Message messageCode='Msg_001.7' />
                }),
          captcha:
            settingGlobal.IsReCaptchaRequired &&
            Yup.string()
              .required(<Message messageCode='Msg_001.6' />)
              .trim(),
          confirm: settingGlobal.IsSubscriptionRequired && Yup.boolean().oneOf([true], <Message messageCode='Msg_015' />),
          emailConfirmation: Yup.string()
            .required(inputData?.['Register Email Confirmation Required Message']?.value)
            .oneOf([Yup.ref('email'), null], inputData?.['Register Email Confirmation Not Match Message']?.value)
        })
      );

      fieldPhoneCode.current = settingGlobal.AreaCodes[0].value;
    }
  }, [settingGlobal, messageObj]);

  useEffect(() => {
    const locationSpinPage = JSON.parse(window.sessionStorage.getItem(SPIN_URL_SESSION_STORAGE_NAME)) || '';

    const fromTo = Global.getQueryParamsNoLower(window.location.href, 'from');

    setLocationSpinPage(locationSpinPage);

    setFromTo(fromTo);
  }, []);

  useEffect(() => {
    if (isRegisterSuccess) {
      if (templateName === PRODUCT_REVIEW_PAGE) {
        //INFO: if is register of product review page => submit productReview
        let formData = new window.FormData();
        for (let keyParams in reviewFormParams) {
          if (keyParams == 'MediaFiles') {
            reviewFormParams[keyParams].forEach((item, index) => {
              for (let keyItem in item) {
                formData.append(`MediaFiles[${index}][${keyItem}]`, item[keyItem]);
              }
            });
          } else {
            formData.append(keyParams, reviewFormParams[keyParams]);
          }
        }

        dispatch(submitProductReview(formData));
      } else if (fromTo == locationSpinPage) {
        window.sessionStorage.setItem('emailLogin', JSON.stringify(emailSpin));

        window.location.href = fromTo;
      } else {
        // INFO: Back again homepage page if 'from' is undefined
        const isAddWishListWithQueryParams = Global.getQueryParamsNoLower(window.location.href, 'isAddWishList');
        const fromTo = Global.getQueryParamsNoLower(window.location.href, 'from');
        if (!fromTo?.includes(FROM_PAGE_QUERY_STRING_SEPARATOR_SYMBOL)) {
          // const isAddWishListWithNoQueryParams = Global.getQueryParamsNoLower(fromTo || '', 'isAddWishList');
          const redirectToCheckoutLink = Global.getQueryParamsNoLower(window.location.href, REDIRECT_CHECKOUT_PARAM);

          if (!redirectToCheckoutLink) {
            window.location.href = isAddWishListWithQueryParams
              ? `${fromTo}&isAddWishList=${true}`
              : fromTo || settingGlobal?.MyAccountPageLink || '/';
          } else {
            window.location.href = redirectToCheckoutLink;
          }
        } else {
          const [pageUrl] = search?.replace(FROM_CHECKOUT_PAGE_QUERY_STRING_NAME, '')?.split(FROM_PAGE_QUERY_STRING_SEPARATOR_SYMBOL);

          window.location.href = pageUrl;
        }
      }
    }
  }, [isRegisterSuccess]);

  const handleClearCaptchaValue = useCallback(() => {
    if (formikRef?.current) {
      const formikCurrent = formikRef?.current;
      const values = formikCurrent?.values;

      settingGlobal?.IsReCaptchaRequired && values.captcha ? setCaptchaErr(false) : setCaptchaErr(true);

      settingGlobal?.IsReCaptchaRequired && values.captcha && recaptchaRef.current.reset();

      settingGlobal?.IsReCaptchaRequired && values.captcha && formikCurrent.setFieldValue('captcha', '');
    }
  }, [formikRef?.current, settingGlobal]);

  useDidUpdateEffect(() => {
    //INFO: tracking sign_up event GA4 when register success
    if (isRegisterSuccess) {
      handleTrackingEventRegisterLogin(EVENT_NAME.register, EVENT_STATUS.register.success, EVENT_MESSAGE.success, isRegisterSuccess.type);

      // INFO: reset captcha value when actions finish
      handleClearCaptchaValue();

      setIsClickedSubmitBtn(false);
    }
  }, [isRegisterSuccess]);

  useDidUpdateEffect(() => {
    //INFO: tracking sign_up event GA4 when register attempt
    if (formikRef?.current?.errors) {
      const errors = Object.keys(formikRef?.current?.errors) || [];

      if (isClickedSubmitBtn && errors.length) {
        handleTrackingEventRegisterLogin(EVENT_NAME.register, EVENT_STATUS.register.attempt, EVENT_MESSAGE.fail, EVENT_METHOD.email);

        // INFO: reset captcha value when actions finish
        handleClearCaptchaValue();

        setIsClickedSubmitBtn(false);
      }
    }
  }, [registerError, formikRef?.current?.errors, isClickedSubmitBtn]);

  useDidUpdateEffect(() => {
    //INFO: tracking sign_up event GA4 when register fail
    if (registerError?.errorMessage && registerError?.type) {
      handleTrackingEventRegisterLogin(EVENT_NAME.register, EVENT_STATUS.register.failure, EVENT_MESSAGE.fail, registerError.type);

      // INFO: reset captcha value when actions finish
      handleClearCaptchaValue();

      setIsClickedSubmitBtn(false);
    }
  }, [registerError]);

  useDidUpdateEffect(() => {
    if (receiveSMS || receiveEmail || receiveDirectMailing) {
      setConfirm(true);

      formikRef.current.setFieldValue('confirm', true);
    }
  }, [receiveSMS, receiveEmail, receiveDirectMailing]);

  useDidUpdateEffect(() => {
    if (!confirm) {
      setReceiveSMS(null);

      setReceiveEmail(null);

      setReceiveDirectMailing(null);
    }
  }, [confirm]);

  return gender !== null ? (
    <div className='osim-account-form osim-account-form--register'>
      <div className='osim-account-form__social-login text-center'>
        <FacebookSSO isLogin={false} dataFields={inputData['Facebook Login Settings']} />
        <GoogleSSO isLogin={false} dataFields={inputData['Google Login Settings']} />
      </div>
      {inputData['Register Introduction'].value ? (
        <p className='osim-account-form__title'>
          <Text field={inputData['Register Introduction']} />
        </p>
      ) : (
        ''
      )}
      {errorMessage ? <span className='error-message'>{errorMessage}</span> : ''}
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={(values) => handleSubmit(values)} innerRef={formikRef}>
        {({ errors, touched, handleChange, values, setFieldValue }) => {
          const onHandleTelephoneChange = (evt, fieldName) => {
            const phoneValueWithoutPrefix = evt.target.value;
            // let telephone = `${fieldPhoneCode.current || ''}${phoneValueWithoutPrefix}`;
            if (!phoneValueWithoutPrefix || new RegExp(settingGlobal.PhoneNumberFormat).test(phoneValueWithoutPrefix)) {
              setFieldValue(fieldName, phoneValueWithoutPrefix);
            }
          };

          return (
            <Form className='osim-account-form__form' id='osim-account-form__form'>
              {settingGlobal?.FormatName === 'First Name First' ? (
                <>
                  <div className={Global.renderDynamicClass(errors.firstName && touched.firstName, 'form-group', 'input-error-validate')}>
                    <input
                      onChange={(e) => handleChange(e)}
                      value={values.firstName}
                      type='text'
                      className={Global.renderDynamicClass(values['firstName'], 'form-control form-control-lg', 'input-valid')}
                      id='firstName'
                      name='firstName'
                      placeholder={inputData['Register First Name Label'].value}
                    />
                    <label htmlFor='firstName' className='form-group__label'>
                      <Text field={inputData['Register First Name Label']} />
                    </label>
                    {errors.firstName && touched.firstName && <span className='error-validate'>{errors.firstName}</span>}
                  </div>
                  <div className={Global.renderDynamicClass(errors.lastName && touched.lastName, 'form-group', 'input-error-validate')}>
                    <input
                      onChange={handleChange}
                      value={values.lastName}
                      type='text'
                      className={Global.renderDynamicClass(values['lastName'], 'form-control form-control-lg', 'input-valid')}
                      id='lastName'
                      name='lastName'
                      placeholder={inputData['Register Last Name Label'].value}
                    />
                    <label htmlFor='lastName' className='form-group__label'>
                      <Text field={inputData['Register Last Name Label']} />
                    </label>
                    {errors.lastName && touched.lastName && <span className='error-validate'>{errors.lastName}</span>}
                  </div>
                </>
              ) : (
                <>
                  <div className={Global.renderDynamicClass(errors.lastName && touched.lastName, 'form-group', 'input-error-validate')}>
                    <input
                      onChange={handleChange}
                      value={values.lastName}
                      type='text'
                      className={Global.renderDynamicClass(values['lastName'], 'form-control form-control-lg', 'input-valid')}
                      id='lastName'
                      name='lastName'
                      placeholder={inputData['Register Last Name Label'].value}
                    />
                    <label htmlFor='lastName' className='form-group__label'>
                      <Text field={inputData['Register Last Name Label']} />
                    </label>
                    {errors.lastName && touched.lastName && <span className='error-validate'>{errors.lastName}</span>}
                  </div>
                  <div className={Global.renderDynamicClass(errors.email && touched.email, 'form-group', 'input-error-validate')}>
                    <input
                      onChange={(e) => handleChange(e)}
                      value={values.firstName}
                      type='text'
                      className={Global.renderDynamicClass(values['firstName'], 'form-control form-control-lg', 'input-valid')}
                      id='firstName'
                      name='firstName'
                      placeholder={inputData['Register First Name Label'].value}
                    />
                    <label htmlFor='firstName' className='form-group__label'>
                      <Text field={inputData['Register First Name Label']} />
                    </label>
                    {errors.firstName && touched.firstName && <span className='error-validate'>{errors.firstName}</span>}
                  </div>
                </>
              )}
              <div className={Global.renderDynamicClass(errors.email && touched.email, 'form-group', 'input-error-validate')}>
                <input
                  onChange={handleChange}
                  value={values.email}
                  type='text'
                  className={Global.renderDynamicClass(values['email'], 'form-control form-control-lg', 'input-valid')}
                  id='email'
                  name='email'
                  placeholder={inputData['Register Email Label'].value}
                />
                <label htmlFor='email' className='form-group__label'>
                  <Text field={inputData['Register Email Label']} />
                </label>
                {errors.email && touched.email && <span className='error-validate'>{errors.email}</span>}
              </div>
              <div className={Global.renderDynamicClass(errors.emailConfirmation && touched.emailConfirmation, 'form-group', 'input-error-validate')}>
                <input
                  onChange={handleChange}
                  autoComplete='new-password'
                  value={values.emailConfirmation}
                  type='text'
                  className={Global.renderDynamicClass(values['emailConfirmation'], 'form-control form-control-lg', 'input-valid')}
                  id='emailConfirmation'
                  name='emailConfirmation'
                  placeholder={inputData['Register Email Confirmation Label'].value}
                />
                <label htmlFor='emailConfirmation' className='form-group__label'>
                  <Text field={inputData['Register Email Confirmation Label']} />
                </label>
                {errors.emailConfirmation && touched.emailConfirmation && <span className='error-validate'>{errors.emailConfirmation}</span>}
              </div>
              <div className={Global.renderDynamicClass(errors.dob && touched.dob, 'form-group', 'input-error-validate')}>
                {/* <DatePicker
                  name='dob'
                  value={datePicker}
                  onChange={(e) => {
                    setDatePicker(e);

                    setFieldValue('dob', (e && Global.customizeISOStringHourType(e)) || '');
                  }}
                  format='dd/MMM/yyyy'
                  className={Global.renderDynamicClass(values['dob'], 'date-picker-custom form-control form-control-lg', 'input-valid')}
                  dayPlaceholder='dd'
                  monthPlaceholder='mm'
                  yearPlaceholder='yyyy'
                /> */}
                <DateOfBirth setFieldValue={setFieldValue} dobLabel={inputData['Register DoB Label']} isRegister={true} />
                {/* <Text field={inputData['Register DoB Label']} className='form-group__label' tag='label' /> */}
                {errors.dob && touched.dob && <span className='error-validate'>{errors.dob}</span>}
              </div>
              {settingGlobal?.GenderDatasource.length ? (
                <div
                  className={Global.renderDynamicClass(
                    errors.gender && touched.gender,
                    'form-group form-group--gender',
                    'form-group--gender input-error-validate'
                  )}
                >
                  <div>
                    {settingGlobal?.GenderDatasource.map((item) => {
                      return (
                        <div className='osim-radio' key={item.Key}>
                          <input
                            type='radio'
                            name='gender'
                            value={item.Key}
                            className='osim-radio-input'
                            checked={gender?.Key?.toLowerCase() === item.Key.toLowerCase()}
                            readOnly
                          />
                          <label
                            className='osim-radio-label'
                            onClick={() => {
                              setGender(item);

                              setFieldValue('gender', item.Key);
                            }}
                          />
                          <label
                            className='osim-radio-title'
                            onClick={() => {
                              setGender(item);

                              setFieldValue('gender', item.Key);
                            }}
                          >
                            {item.Value}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  {errors.gender && touched.gender && <span className='error-validate'>{errors.gender}</span>}
                </div>
              ) : (
                <></>
              )}
              <div
                className={
                  settingGlobal?.UseAreaCode
                    ? `${Global.renderDynamicClass(
                      errors.phone && touched.phone,
                      'form-group',
                      'input-error-validate'
                    )} form-group--group-select-input`
                    : Global.renderDynamicClass(errors.phone && touched.phone, 'form-group', 'input-error-validate')
                }
              >
                {settingGlobal?.UseAreaCode ? (
                  <PhoneNumberSelect setPhoneNumberCodeEvt={(code) => (fieldPhoneCode.current = code)} optionValues={settingGlobal.AreaCodes} />
                ) : (
                  ''
                )}
                <div className='form-group--input'>
                  <input
                    onChange={(evt) => onHandleTelephoneChange(evt, 'phone')}
                    value={values.phone}
                    type='text'
                    className={Global.renderDynamicClass(values['phone'], 'form-control form-control-lg', 'input-valid')}
                    id='phone'
                    name='phone'
                    placeholder={inputData['Register Phone Label'].value}
                  />
                  <label htmlFor='phone' className='form-group__label'>
                    <Text field={inputData['Register Phone Label']} />
                  </label>
                  {errors.phone && touched.phone && <span className='error-validate'>{errors.phone}</span>}
                </div>
              </div>
              <div className={Global.renderDynamicClass(errors.password && touched.password, 'form-group', 'input-error-validate')}>
                <input
                  onChange={handleChange}
                  value={values.password}
                  type='password'
                  className={Global.renderDynamicClass(values['password'], 'form-control form-control-lg', 'input-valid')}
                  id='password'
                  name='password'
                  placeholder={inputData['Register Password Label'].value}
                />
                <label htmlFor='password' className='form-group__label'>
                  <Text field={inputData['Register Password Label']} />
                </label>
                {errors.password && touched.password && (
                  <span className='error-validate'>
                    <Message messageCode='Msg_001.5' />
                  </span>
                )}
              </div>
              <div className={Global.renderDynamicClass(errors.confirm && touched.confirm, 'form-group', 'input-error-validate')}>
                <div className='osim-checkbox'>
                  <input
                    type='checkbox'
                    onClick={() => setConfirm(!confirm)}
                    id='confirm'
                    name='confirm'
                    className='osim-checkbox-input'
                    checked={confirm}
                    readOnly
                  />
                  <RichText
                    tag='label'
                    field={inputData['Register Subscription Label']}
                    className='osim-checkbox-label osim-account-form__form__subscription'
                    onClick={() => {
                      setConfirm(!confirm);

                      setFieldValue('confirm', !confirm);
                    }}
                  />
                </div>
                {errors.confirm && touched.confirm && (
                  <span className='error-validate'>
                    <Message messageCode='Msg_015' />
                  </span>
                )}
              </div>
              {inputData['Enable Subscription'].value ? (
                <div className='form-group form-group--checkboxes form-group--checkbox-pd-left'>
                  <Text tag='span' className='form-group--checkboxes__label' field={inputData['Subscription Description']} />
                  <div className='form-group--checkboxes__list'>
                    <div className='osim-checkbox'>
                      <input type='checkbox' id='receiveSms' name='receiveSms' className='osim-checkbox-input' checked={receiveSMS} readOnly />
                      <Text
                        field={inputData['SMS Subscription Label']}
                        tag='label'
                        className='osim-checkbox-label'
                        onClick={() => {
                          setFieldValue('receiveSms', !receiveSMS);

                          setReceiveSMS(!receiveSMS);
                        }}
                      />
                    </div>
                    <div className='osim-checkbox'>
                      <input type='checkbox' id='receiveMail' name='receiveMail' className='osim-checkbox-input' checked={receiveEmail} readOnly />
                      <Text
                        field={inputData['Email Subscription Label']}
                        tag='label'
                        className='osim-checkbox-label'
                        onClick={() => {
                          setFieldValue('receiveMail', !receiveEmail);

                          setReceiveEmail(!receiveEmail);
                        }}
                      />
                    </div>
                    <div className='osim-checkbox'>
                      <input
                        type='checkbox'
                        id='receiveDirectMail'
                        name='receiveDirectMail'
                        className='osim-checkbox-input'
                        checked={receiveDirectMailing}
                        readOnly
                      />
                      <Text
                        field={inputData['Direct Mailing Subscription Label']}
                        tag='label'
                        className='osim-checkbox-label'
                        onClick={() => {
                          setFieldValue('receiveDirectMail', !receiveDirectMailing);

                          setReceiveDirectMailing(!receiveDirectMailing);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {settingGlobal?.IsReCaptchaRequired && settingGlobal['CaptchaPublicKey'].length ? (
                <div className='form-group form-group--captcha'>
                  <ReCAPTCHA
                    sitekey={settingGlobal['CaptchaPublicKey']}
                    onChange={(value) => setFieldValue('captcha', value || '')}
                    ref={recaptchaRef}
                  />
                  {errors.captcha && touched.captcha && captchaErr && <span className='error-validate'>{errors.captcha}</span>}
                </div>
              ) : (
                <></>
              )}
              <div className='form-group osim-account-form__form__btn'>
                <button
                  disabled={isRegisteringNewAccount}
                  className={['osim-account-form__form__btn__item btn btn-primary', isRegisteringNewAccount ? 'btn-disable' : ''].join(' ')}
                  onClick={() => {
                    // settingGlobal?.IsReCaptchaRequired && values.captcha ? setCaptchaErr(false) : setCaptchaErr(true);
                    //
                    // settingGlobal?.IsReCaptchaRequired && values.captcha && recaptchaRef.current.reset();
                    //
                    // settingGlobal?.IsReCaptchaRequired &&
                    // values.captcha &&
                    // setTimeout(() => {
                    //   setFieldValue('captcha', '');
                    // }, 1000);

                    // INFO: detect button is clicked when form not valid
                    setIsClickedSubmitBtn(true);
                  }}
                  type='submit'
                >
                  <Text field={inputData['Register Button Label']} />
                </button>
              </div>
              <div className='form-group'>
                <p className='text-center'>
                  <Text field={inputData['Register Switch To Login Text']} />
                  <a href='#' onClick={() => props.changeTabEvt()}>
                    <Text field={inputData['Login Button Text']} />
                  </a>
                </p>
              </div>
            </Form>
          );
        }}
      </Formik>
      {isRegisteringNewAccount ? <LoadingMask /> : ''}
    </div>
  ) : <></>;
};

Register.propTypes = {
  inputData: PropTypes.object,
  changeTabEvt: PropTypes.func,
  isRegisterSuccess: PropTypes.bool,
  setEmailRegisterEvt: PropTypes.func,
  isLoading: PropTypes.bool,
  errorMessage: PropTypes.string,
  registerError: PropTypes.any,
  settingGlobal: PropTypes.any,
  isRegisteringNewAccount: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isRegisterSuccess: state.singleSignOnReducer.userInfoReducer.accountUser?.IsRegisterNew,
  isLoading: state.singleSignOnReducer.registerReducer.isLoading,
  errorMessage: state.singleSignOnReducer.registerReducer.errorMessage,
  registerError: state.singleSignOnReducer.registerReducer.registerError,
  settingGlobal: state.settingGlobalReducer.settingGlobal,
  isRegisteringNewAccount: state.singleSignOnReducer.registerReducer.isRegisteringNewAccount,
});

export default connect(mapStateToProps)(Register);
