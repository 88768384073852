import './CPDemoStatus.scss';

import { any } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import CPDemoStatusModel from './Models/CPDemoStatusModel';

const CPDemoStatus = ({ fields }) => {
  const [cPDemoFields, setCPDemoFields] = useState(null);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);

  useEffect(() => {
    const cPDemoStatus = new CPDemoStatusModel();

    setCPDemoFields(cPDemoStatus.getData(deepCopy(fields || {})));
  }, []);

  return cPDemoFields && SitecoreContextFactoryService.getValueContextItem('cpCustomerId') ? (
    SitecoreContextFactoryService.getValueContextItem('cpCustomerName') !== 'Anonymous' ? (
      <div className='cp-demo'>
        <div className='cp-demo__status'>
          <p className='cp-demo__status__content'>
            <span>{fields.Text.value.replace('[CustomerName]', '')}</span>
            <span className='customer-name'>{SitecoreContextFactoryService.getValueContextItem('cpCustomerName')}</span>
          </p>
        </div>
      </div>
    ) : (
      <div className='cp-demo'>
        <div className='cp-demo__status'>
          <p className='cp-demo__status__content'>
            <Text tag='span' field={cPDemoFields['Text']} />
          </p>
        </div>
      </div>
    )
  ) : (
    <></>
  );
};

CPDemoStatus.propTypes = {
  fields: any
};

export default CPDemoStatus;
