import { format } from 'date-fns';

import { MY_SITE_NAME } from '@utils/constant';
import { isAtSiteName } from '@utils/customsHook/useIsAtSiteName';

export const removeCurlyBrackets = (text) => text?.replace(/[{}]/g, '');

export const addCurlyBrackets = (text) => `{${removeCurlyBrackets(text)}}`;

export const handleAddCurlyBracket = (data) => {
  const tempData = [];
  for (let item of data) {
    const answerIdsData = item.AnswerIds.map((answer) => addCurlyBrackets(answer));

    tempData.push({
      QuestionId: addCurlyBrackets(item.QuestionId),
      AnswerIds: answerIdsData
    });
  }

  return tempData;
};

export const handleGenerateAppointmentParams = (payload) => ({
  ProductId: addCurlyBrackets(payload.productId),
  ContactId: addCurlyBrackets(payload.contactId),
  OsimAccountUsername: payload.osimAccountUsername,

  StoreId: addCurlyBrackets(payload.storeId),
  Date: payload.date ? format(new Date(payload.date), 'yyyy-MM-dd') : '',
  TimeSlotId: addCurlyBrackets(payload.timeSlot),
  NumberOfGuests: payload.guest,

  TeaId: addCurlyBrackets(payload.teaId),
  ScentId: addCurlyBrackets(payload.scentId),
  ContactAnswers: [...handleAddCurlyBracket(payload.additionalSelectionAns)],

  FirstName: payload.firstName,
  LastName: payload.lastName,
  MobileNumber: isAtSiteName(MY_SITE_NAME) ? `+${payload.mobileNumber}` : payload.mobileNumber,
  EmailAddress: payload.emailAddress
});
