import './SocialMedia.scss';

import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import { cpShareProductDetailStart } from '@redux/actions/cpShareProduct';
import { productStartTrackingGoal } from '@redux/actions/trackingGoal/productTracking';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import SharingItemModel from './model/SharingItemModel';
import SocialMediaItemModel from './model/SocialMediaItemModel';

const SocialMedia = (props) => {
  const [inputData, setInputData] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const variantSelected = useSelector((state) => state.productReducer.productDetailOverviewReducer.variantSelected);
  const isGA4 = Global.getIsGA4();
  const dispatch = useDispatch();
  const productId = SitecoreContextFactoryService.getValueContextItem('itemId');
  const cpCustomerId = SitecoreContextFactoryService.getValueContextItem('cpCustomerId');

  useEffect(() => {
    const socialMediaItemModel = new SocialMediaItemModel();

    setInputData(socialMediaItemModel.getData(deepCopy(props.fields || {})));
  }, []);

  const copyToClipBoard = () => {
    const successElement = document.getElementById('copied');

    var input = document.createElement('input');

    input.setAttribute('value', window.location.href);

    document.body.appendChild(input);

    input.select();

    var result = document.execCommand('copy');
    
    document.body.removeChild(input);

    successElement.style.setProperty('--show-copied', 'block');
    
    setTimeout(() => {
      successElement.style.setProperty('--show-copied', 'none');
    }, 2000);

    return result;
  };

  const showDialog = () => {
    document.getElementById('fake-modal').style.setProperty('--show-dialog', 'visible');
    setIsActive(true);
  };

  const closeDialog = () => {
    document.getElementById('fake-modal').style.setProperty('--show-dialog', 'hidden');
    setIsActive(false);
  };

  const handleTrackingProduct = () => {
    dispatch(productStartTrackingGoal({
      'GoalId': '{F332B9B9-E956-4F80-8AE3-CB4065BDAA78}',
      'ProductId': `{${productId}}`,
      'UseTrackerAPI': false
    }));
  };

  const handleCPTrackingShareProduct = () => {
    dispatch(cpShareProductDetailStart({
      'contactId': `{${cpCustomerId}}`,
      'productPageId': `{${productId}}`
    }));
  };

  //New Logic ON/OFF GA, GA4
  const gA4Share = (data) => {
    if (isGA4 && data) {
      let params = {
        method: data.method,//Eg:google,facebook,line,Twitter etc
        item_id: data.item_id, // item id
        item_name: data.item_name // name of the item
      };

      //GA4
      new GoogleAnalytic().gAnalytic4('event', 'share', params);
    }
    
    if (cpCustomerId) {
      handleCPTrackingShareProduct();
    } else {
      handleTrackingProduct();
    }
  };

  return (
    inputData && (
      <div className='content-sharing'>
        <Helmet>{parse(inputData['Script Library'].value)}</Helmet>
        <div className='social-media'>
          <div className={isActive ? 'social-media__container active' : 'social-media__container'}>
            <div className='social-media__icon item-tooltip' onClick={() => showDialog()}>
              <div className='social-media__tooltip item-tooltip__label'>{messageObj['LBL-ShareLabel']}</div>
            </div>
          </div>
        </div>
        <div className='fake-modal' id='fake-modal'>
          <span  onClick={() => closeDialog()} className='osim-popup__close-btn'>
            {/* <i className='icon-close'></i> */}
          </span>
          <div className='share-modal'>
            {/* <div className='share-modal__title'><Text field={inputData['Share On Label']} /></div> */}
            {/* <span  onClick={() => closeDialog()} className='osim-popup__close-btn'><i className='icon-close'></i></span> */}
            <div className='share-modal__list'>
              {
                inputData.items.map(item => {
                  const model = new SharingItemModel();
                  const data = model.getData(item);
                  
                  return (
                    <div 
                      data-network={data.fields['Data Network'].value} 
                      className={`st-custom-button ${data.fields['Data Network'].value}`} 
                      key={data.id}
                      onClick={()=> gA4Share({
                        method: data.fields['Data Network'].value,
                        item_id: variantSelected.VariantId,
                        item_name: variantSelected.ProductName
                      })}
                    >
                      {data.fields['Title'].value}
                    </div>
                  );
                })
              }
              <div className='st-custom-button copyLink' onClick={() => {
                copyToClipBoard();
                
                gA4Share({
                  method: inputData['Copy Link Label'],
                  item_id: variantSelected.VariantId,
                  item_name: variantSelected.ProductName
                });
              }}><Text field={inputData['Copy Link Label']} /></div>
              <div id='copied' className='copied'><Text field={inputData['Capied Label']} /></div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

SocialMedia.propTypes = {
  fields: PropTypes.object
};

export default SocialMedia;
