import Proptypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

import { isStickyHeader$ } from '@components/Product/VariantDetail/ProductDetailHeader/handleStickyHeaderService';

const BundleBOHeader = (props) => {
  const { bundleName, totalSellingPriceText, totalListPriceText} = props;
  const [isBundleBOHeaderSticky, setIsBundleBOHeaderSticky] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  useEffect(() => {
    if (scrollTop > 0) {
      setIsBundleBOHeaderSticky(true);

      isStickyHeader$.next(true);
    } else {
      setIsBundleBOHeaderSticky(false);

      isStickyHeader$.next(false);
    }
  }, [scrollTop]);

  return (
    <Fragment>
      <div className={`buying-option-header${isBundleBOHeaderSticky ? ' buying-option-header-sticky' : ''}`}>
        <div className='container buying-option-header__wrap'>
          <div className='buying-option-header__text'>
            <span className='buying-option-header__text__name' title={`${bundleName}`}>{bundleName}</span>
            <div className='buying-option-header__text__price'>
              {totalSellingPriceText === totalListPriceText ? (
                <span className='buying-option-header__text__price__current'>{totalSellingPriceText}</span>
              ) 
                : (
                  <Fragment>
                    <span className='buying-option-header__text__price__current'>{totalSellingPriceText}</span>
                    <del className='buying-option-header__text__price__old'>{totalListPriceText}</del>
                  </Fragment>
                )}
            </div>
          </div>
          {props.children}
        </div>
      </div>
    </Fragment>
  );
};

BundleBOHeader.propTypes = {
  bundleName: Proptypes.any,
  children: Proptypes.any,
  totalSellingPriceText: Proptypes.any,
  totalListPriceText: Proptypes.any,
  CurrencyCode: Proptypes.any,
};

export default BundleBOHeader;
