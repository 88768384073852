import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/cpSendSaleOrder';
import * as cpSendSaleOrderTypes from '@redux/actions/cpSendSaleOrder/cpSendSaleOrderTypes';
import { post } from '@services/genericService';

import * as config from '../config';

const cpSendSaleOrderEpic = (action$) =>
  action$.pipe(
    ofType(cpSendSaleOrderTypes.SEND_SALE_ORDER),
    switchMap((action) => {
      const params = {
        cartName: action.payload.cartName,
        customerId: action.payload.customerId,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        sendEmail: action.payload.sendEmail,
        emailAddresses: action.payload.emailAddresses,
        sendSms: action.payload.sendSms,
        phoneNumbers: action.payload.phoneNumbers
      };

      return from(post(config.ACTION_CP_SEND_SALE_ORDER, params)).pipe(
        map((res) => {
          if (res.status == 200) {
            return actions.sendSaleOrderSuccess({
              ...res.data
            });
          } else {
            return actions.sendSaleOrderFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actions.sendSaleOrderFailed(error.message || 'Failed')))
      );
    })
  );

export default cpSendSaleOrderEpic;
