import './CampaignRegisterComponent.scss';

import { any } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { bookAppointmentStart } from '@redux/actions/campaignRegister/bookAppointment';
import { createContactStart } from '@redux/actions/campaignRegister/createContact';
import { getContactStart } from '@redux/actions/campaignRegister/getContact';
import { updateContactStart } from '@redux/actions/campaignRegister/updateContact';
import { register, resetRegisterErrorMsg } from '@redux/actions/singleSignOn';
import { BookAppointmentStatus } from '@redux/epic/campaignRegister/bookAppointment/responseStatus';
import { CreateContactStatus } from '@redux/epic/campaignRegister/createContact/responseStatus';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import LoadingMask from '../Share/LoadingMask';
import { CampaignRegisterContext } from './CampaignRegisterContext';
import LoginModalForm from './LoginModalForm';
import CampaignRegisterComponentModel from './model/CampaignRegisterComponentModel';
import RegisterEventForm from './RegisterEventForm';
import SelectStoreAndDate from './SelectStoreAndDate';

const CampaignRegisterComponent = ({ fields }) => {
  const dispatch = useDispatch();
  const [layoutData, setLayoutData] = useState(null);
  const { isLoading: isRegistering, errorMessage } = useSelector((state) => state.singleSignOnReducer.registerReducer);
  const isRegisterSuccess = useSelector((state) => state.singleSignOnReducer.userInfoReducer.accountUser?.IsRegisterNew);
  const [isShowRegisterForm, setIsShowRegisterForm] = useState(false);
  const isLoginSuccess = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.isLogin);
  const { accountUser } = useSelector((state) => state.singleSignOnReducer.userInfoReducer);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const { contactData } = useSelector((state) => state.campaignRegisterReducer.getContactReducer);
  const { validateResponse } = useSelector((state) => state.campaignRegisterReducer.validateOtpReducer);

  const {
    createResponse,
    isLoading: isCreatingContact,
    error: createContactError
  } = useSelector((state) => state.campaignRegisterReducer.createContactReducer);

  const {
    bookAppointmentResponse,
    isLoading: isBookingAppointment,
    error: bookAppointmentError
  } = useSelector((state) => state.campaignRegisterReducer.bookAppointmentReducer);

  const [userRegisterInfo, setUserRegisterInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    dob: '',
    phoneNumber: ''
  });
  const [values, setValues] = useState(null);
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);

  useEffect(() => {
    let model = new CampaignRegisterComponentModel();
    if (fields?.['Url404'] != undefined && fields?.['Url404'] != '') {
      window.location.replace(fields?.['Url404']);
    }

    setLayoutData(model.getData(fields || {}));
  }, []);

  useDidUpdateEffect(() => {
    // INFO: get contact data after register success and before book appointment
    if (values && isRegisterSuccess) {
      const params = {
        mobileNumber: userRegisterInfo.phoneNumber,
        emailAddress: values.email
      };

      dispatch(getContactStart(params));
    }
  }, [values, isRegisterSuccess, userRegisterInfo]);

  useDidUpdateEffect(() => {
    // INFO: book appointment after get contact success
    if (isRegisterSuccess && contactData) {
      handleDispatchBookAppointment(values);
    }
  }, [isRegisterSuccess, contactData]);

  useDidUpdateEffect(() => {
    if (BookAppointmentStatus.SuccessStatusCode.includes(bookAppointmentResponse?.StatusCode)) {
      window.location.href = `/Booking%20Portal/Appointment%20Booked?appointment_id=${bookAppointmentResponse?.Result?.Id}`;
    }
  }, [bookAppointmentResponse]);

  const handleBookAppointment = (values) => {
    // INFO: reset register error message if yes
    if (errorMessage) dispatch(resetRegisterErrorMsg());
    if (values.allowCreateAccount) {
      handleCreateNewAccount(values);
    } else if (
      (isLoginSuccess || accountUser) &&
      (values.allowReceiveMarketingSMS !== contactData.AllowSMS ||
        values.allowReceiveMarketingEmail !== contactData.AllowEmail ||
        values.allowReceiveMarketingDirectMail !== contactData.AllowDirectMail ||
        userRegisterInfo.phoneNumber !== contactData?.MobileNumber?.replace('65', ''))
    ) {
      const updateData = {
        ...values,
        isUpdatePhoneNumber: userRegisterInfo.phoneNumber !== contactData?.MobileNumber?.replace('65', '')
      };

      // INFO: update contact data if yes
      handleUpdateContact(updateData);
    } else {
      handleDispatchBookAppointment(values);
    }
  };

  const handleUpdateContact = useCallback(
    (values) => {
      if (contactData) {
        const params = {
          bookingValues: {
            ...values,
            campaignId: layoutData?.['campaign']?.Id,
            contactId: contactData.Id || createResponse?.Result?.Id,
            osimAccountUsername: accountUser?.Username,
            firstName: userRegisterInfo.firstName,
            lastName: userRegisterInfo.lastName,
            mobileNumber: userRegisterInfo.phoneNumber,
            emailAddress: userRegisterInfo.email
          },
          contactId: contactData.Id,
          firstName: contactData.FirstName,
          lastName: contactData.LastName,
          dob: contactData.DateOfBirth,
          phoneNumber: userRegisterInfo.phoneNumber,
          allowSMS: values.allowReceiveMarketingSMS,
          allowEmail: values.allowReceiveMarketingEmail,
          allowDirectMail: values.allowReceiveMarketingDirectMail
        };

        dispatch(updateContactStart(params));
      }
    },
    [contactData, accountUser, layoutData?.['campaign']?.Id, createResponse?.Result?.Id, userRegisterInfo]
  );

  const handleDispatchBookAppointment = useCallback(
    (values) => {
      const queryParams = new URLSearchParams(window.location.search);
      const referralCode = queryParams.get('ref_code');
      if (isLoginSuccess || createResponse) {
        dispatch(
          bookAppointmentStart({
            ...values,
            campaignId: layoutData?.['campaign']?.Id,
            contactId: contactData?.Id || createResponse?.Result?.Id,
            osimAccountUsername: accountUser?.Username,
            firstName: userRegisterInfo.firstName,
            lastName: userRegisterInfo.lastName,
            mobileNumber: userRegisterInfo.phoneNumber,
            emailAddress: userRegisterInfo.email,
            referralCode: referralCode
          })
        );
      } else {
        const params = {
          campaignId: layoutData?.['campaign']?.Id,
          firstName: userRegisterInfo.firstName,
          lastName: userRegisterInfo.lastName,
          dob: values.dob,
          email: values.email,
          phoneNumber: userRegisterInfo.phoneNumber,
          bookingsValues: {
            ...values,
            campaignId: layoutData?.['campaign']?.Id,
            osimAccountUsername: accountUser?.Username,
            firstName: userRegisterInfo.firstName,
            lastName: userRegisterInfo.lastName,
            mobileNumber: userRegisterInfo.phoneNumber,
            emailAddress: userRegisterInfo.email,
            referralCode: referralCode
          }
        };

        dispatch(createContactStart(params));
      }
    },
    [accountUser?.Username, layoutData?.['campaign']?.Id, createResponse, contactData?.Id, isLoginSuccess, userRegisterInfo]
  );

  const handleCreateNewAccount = (values) => {
    setValues(values);

    dispatch(
      register({
        FirstName: userRegisterInfo.firstName,
        LastName: userRegisterInfo.lastName,
        Email: values.email,
        PhoneNumber: userRegisterInfo.phoneNumber,
        Password: values.password,
        Gender: values.gender,
        DOB: values.dob,
        ReceiveSms: values.allowReceiveMarketingSMS ?? false,
        ReceiveEmail: values.allowReceiveMarketingEmail ?? false,
        ReceiveDirectMail: values.allowReceiveMarketingDirectMail ?? false,
        ReCaptchaRequest: values.captcha
      })
    );
  };

  const handleShowRegisterForm = () => setIsShowRegisterForm(true);

  const bookAppointmentErrorMsgCode = useMemo(() => {
    return bookAppointmentError ? BookAppointmentStatus.ErrorStatusCode[bookAppointmentError?.StatusCode] || BookAppointmentStatus.GeneralMsg : '';
  }, [bookAppointmentError]);

  const createContactMsgCode = useMemo(() => {
    return createContactError ? CreateContactStatus.ErrorStatusCode[createContactError.StatusCode] : CreateContactStatus.GeneralMsg;
  }, [createContactError]);

  const createContactWarningMsgCode = useMemo(() => {
    return createResponse
      ? Object.keys(CreateContactStatus.WarningStatusCode).includes(createResponse?.StatusCode)
        ? createResponse?.StatusCode
        : ''
      : '';
  }, [createResponse]);

  return layoutData ? (
    <CampaignRegisterContext.Provider
      value={{
        layoutData,
        userRegisterInfo,
        setUserRegisterInfo,
        isOpenLoginModal,
        setIsOpenLoginModal
      }}
    >
      <div id={layoutData?.registerButtonId?.value || 'campaign-register-form'} className='register-event'>
        <div className='container'>
          <div className='register-event__form'>
            {isShowRegisterForm ? (
              !(validateResponse && (validateResponse.StatusCode === 0 || validateResponse.Result)) ? (
                <>
                  <div className='register-event__form__head'>
                    <Text field={layoutData.registerEventScreenTitle} tag='h4' className='title' />
                    <div className='subtitle'>
                      <LoginModalForm />
                    </div>
                  </div>
                  <div className='register-event__form__body'>
                    <RegisterEventForm />
                  </div>
                  {createContactError ? <span className='error-validate'>{createContactError.StatusReason}</span> : <></>}
                </>
              ) : (
                <>
                  <SelectStoreAndDate layoutData={layoutData} onSubmit={handleBookAppointment} />
                  {bookAppointmentError ? <span className='error-validate'>{messageObj?.[bookAppointmentErrorMsgCode]}</span> : <></>}
                  {errorMessage ? <span className='error-validate'>{errorMessage}</span> : <></>}
                  {createContactError ? <span className='error-validate'>{messageObj?.[createContactMsgCode]}</span> : <></>}
                  {createContactWarningMsgCode ? (
                    <span>{messageObj?.[CreateContactStatus.WarningStatusCode[createContactWarningMsgCode]]}</span>
                  ) : (
                    <></>
                  )}
                </>
              )
            ) : (
              <button type='button' onClick={handleShowRegisterForm} className='btn btn-primary'>
                <Text field={layoutData?.registerButtonText} />
              </button>
            )}
          </div>
        </div>
      </div>
      {isCreatingContact || isBookingAppointment || isRegistering ? <LoadingMask /> : <></>}
    </CampaignRegisterContext.Provider>
  ) : (
    <></>
  );
};

CampaignRegisterComponent.propTypes = {
  fields: any
};

export default CampaignRegisterComponent;
