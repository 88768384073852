export const COUNTRY_CODE = [
  {
    code: 'CA',
    regex: /^[\d]*$/
  },
  {
    code: 'CN',
    regex: /^[\d]*$/
  },
  {
    code: 'SGP',
    regex: /^[\d]{0,8}$/
  },
  {
    code: 'HK',
    regex: /^[456789]?(?:[\d]{0,8})$/
  },
  {
    code: 'MV',
    regex: /^[\d]*$/
  },
  {
    code: 'TJ',
    regex: /^[\d]{0,10}$/
  },
  {
    code: 'TH',
    regex: /^[\d]*$/
  },
  {
    code: 'US',
    regex: /^[\d]*$/
  }
];

export const REGEX_PHONE = /^[\d]*$/;

export const PromotionType = {
  FreeGift: 1,
  TradeIn: 2,
  PwP: 3,
  FreeGiftForCoupon: 4,
  Warranty: 5,
  Rental: 7,
  AdditionalOptions: 8,
  TradeInLoyalty: 10,
  AddOnDeals: 11,
  DepositGift: 12,
  BalanceGift: 13
};

export const PRODUCT_SORT_TYPE = [
  { name: 'Latest', value: 0 },
  { name: 'MostPopular', value: 1 },
  { name: 'PriceAsc', value: 2 },
  { name: 'PriceDesc', value: 3 }
];

export const DELIVERY_OPTION = {
  ShipItem: 1,
  PickUpStore: 2,
  SFExpress: 99,
  DeliveryBySFExpress: 98
};

export const INITIAL_INDEX = 0;

export const PRODUCT_DETAIL_DISPLAY_IMAGES = 1;

export const DECIMAL_COUNT_DEFAULT = 2;

export const CURRENCY_FORMAT_DEFAULT = 'en-us';

export const _SECOND = 1000;

export const _MINUTE = _SECOND * 60;

export const _HOUR = _MINUTE * 60;

export const _DAY = _HOUR * 24;

export const MINUMUM_CHARACTERS_PASSWORD = 6;

//Banner component
export const CLASS_NAME_CONTENT_RIGHT = 'content-right';

export const TEXT_RIGHT = 'Text Right';

export const TEXT_COLOR_WHITE = 'White';

export const LIST_CLASS_NAME__MOBILE = {
  'Text Top': 'content-top',
  'Text Middle': 'content-middle',
  'Text Bottom': 'content-bottom'
};

export const LIST_CLASS_NAME__DESKTOP = {
  'Text Left': 'content-left',
  'Text Right': 'content-right',
  'Text Middle': 'content-center',
  'Text Top Middle': 'content-top-center',
  'Text Bottom Middle': 'content-bottom-center',
  'Text Bottom Left': 'content-bottom-left',
  'Text Bottom Right': 'content-bottom-right',
  'Text Center': 'content-center',
  'Text Middle Left': 'content-left',
  'Text Middle Right': 'content-right',
  'Text Top Left': 'content-top-left',
  'Text Top Right': 'content-top-right'
};

export const LAYOUT_FULL = 'Full-Height';

//Store Locator

export const GOOGLE_MAP_API_KEY = 'AIzaSyAmscckyniR6pZeDn8Bdv39r-HZXFkgV0M';

export const INITIAL_STORE_DISPLAY = 1;

export const MONTH_NAM_SORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const WELLNESS_ARTICLE_PAGE = 1;

export const WELLNESS_ARTICLE_PAGE_SIZE = 9;

//Buying Options
export const DEFAULT_SELECT_LIMIT = 1;

export const VARIANT_EVENT_KEY = 'VARIANT_EVENT_KEY';

export const WARRANTY_EVENT_KEY = 'WARRANTY_EVENT_KEY';

export const FREEGIFT_EVENT_KEY = 'FREEGIFT_EVENT_KEY';

export const TRADEIN_EVENT_KEY = 'TRADEIN_EVENT_KEY';

export const RENTAL_EVENT_KEY = 'RENTAL_EVENT_KEY';

export const ADDITIONAL_OPTIONS_KEY = 'ADDITIONAL_OPTIONS_KEY';

export const FREEGIFT_SLIDE_DISPLAY_ITEM = 5;

export const ADDITIONAL_OPTIONS_SLIDE_DISPLAY_ITEM = 5;

export const FREEGIFT_SLIDE_DISPLAY_ITEM_DEVICE = 3.5;

export const ADDITIONAL_OPTIONS_SLIDE_DISPLAY_ITEM_DEVICE = 3.5;

//Promotion Cart Line

export const PROMO_DEFAULT_QUANTITY = 1;

export const PROMO_FREEGIFT_TYPE = 1;

export const PROMO_TRADEIN_TYPE = 2;

// Type trade in when buy now from component trade in special on page myrewards
export const PROMO_TRADEIN_TYPE_LOYALTY = 10;

export const PROMO_PWP_TYPE = 3;

export const PROMO_FREEGIFT_FOR_COUPON_TYPE = 4;

export const PROMO_WARRANTY_TYPE = 5;

export const PROMO_RENTAL_TYPE = 7;

export const PROMO_ADDITIONAL_TYPE = 8;

export const DEPOSIT_FREEGIFT_TYPE = 12;

export const BALANCE_FREEGIFT_TYPE = 13;

//SearchBox Universal

export const SEARCHBOX_UNIVERSAL_PAGE = 1;

export const SEARCHBOX_UNIVERSAL_PAGESZ = 5;

export const PRODUCT_STATUS = {
  InStock: 'InStock',
  OutOfStock: 'OutOfStock',
  InStockInventoy: 'InStockInventoy',
  OutOfStockInventoy: 'OutOfStockInventoy',
  PreOrderable: 3,
  BackOrderable: 4
};

// checkout
export const NUMBER_ADDRESS_TO_SHOW = 3;

export const SUGGESTED_ADDRESS_NUMBER = 5;

export const SITECOREFORM_RECAPTCHA_ID = '{956BB844-3DF5-4E00-9571-86C53D40DB0C}';

export const CUSTOMIZED_CHECKBOX_ID = '{4D4C6492-8208-450B-99A6-A4336C6B5F12}';

export const ENUM_STATUS = [
  {
    status: 'Pending',
    class: 'processing'
  },
  {
    status: 'Released',
    class: 'processing'
  },
  {
    status: 'Completed',
    class: 'completed'
  },
  {
    status: 'OnHold',
    class: 'on-hold'
  },
  {
    status: 'Cancelled',
    class: 'cancelled'
  },
  {
    status: 'Problem',
    class: 'problem'
  },
  {
    status: 'WaitingForPayment',
    class: 'waiting-for-payment'
  },
  {
    status: 'DeliveryArranged',
    class: 'delivery-arranged'
  },
  {
    status: 'WaitingForFullPayment',
    class: 'waiting-for-full-payment'
  },
  {
    status: 'Processing',
    class: 'processing'
  },
  {
    status: 'WaitingForAvailability',
    class: 'waiting-for-availability'
  },
  {
    status: 'ReceivedByLogistic',
    class: 'processing'
  }
];

export const ENUM_PACKAGE_CLASS = [
  {
    type: 'delivery',
    class: 'package-icon--delivery'
  },
  {
    type: 'pick-up-store',
    class: 'package-icon--pickup'
  },
  {
    type: 'sf-express',
    class: 'package-icon--sf-express'
  },
  {
    type: 'billing-details',
    class: 'package-icon--billing'
  },
  {
    type: 'delivery-by-sf-express',
    class: 'package-icon--delivery-sf-express'
  },
  {
    type: 'deposit',
    class: 'package-icon--deposit'
  }
];

// Product
export const STOCK_QUANTITY = '[Qty]';

export const WELLNESS_SEARCH = 'Wellness Search';

export const SUPPORT_SEARCH = 'Support Search';

export const YEAR_TO_MILLISECONDS = 31536000000;

export const THIRTY_DAYS_TO_SECONDS = 2592000;

export const DEFAULT_DATE = 978307240000;

//Search resource
export const RESOURCE_SEARCH = 'Resource Search';

// Breakpoint

export const IPAD_BREAKPOINT = 768;

export const DEFAULT_API_VALUE = ' ';

// Review Page
export const REVIEW_INPUT_TYPE = 'input';

export const REVIEW_TEXTAREA_TYPE = 'textarea';

export const REFERENCE_NEW_PRODUCT = 'New Product';

export const REFERENCE_PURCHASED_PRODUCT = 'Purchased Product';

export const REFERENCE_REGISTERED_PRODUCT = 'Registered Product';

export const PRODUCT_REVIEW_PAGE = 'Product Review Page';

export const HK_SITE_NAME = 'OHK';

export const TW_SITE_NAME = 'OTW';

export const REDIRECT_CHECKOUT_PARAM = 'redirect-to-checkout-link';

export const GA4_LOCAL_STORAGE_KEY = 'paramGA4';

export const SG_SITE_NAME = 'OSG';

export const MY_SITE_NAME = 'OMY';

export const TH_SITE_NAME = 'OTH';

export const MAXIMUM_ADDRESS_LENGTH = 50;

export const SITE_CHINESE_LANGUAGE_KEY = 'zh-HK';

export const CHINESE_LANGUAGES = {
  year: '年',
  month: '月',
  day: '日',
  hour: '時',
  minute: '分',
  second: '秒'
};

export const MACAU_PREFIX_CODE_VALUE = '+853';

export const REGEX_CHECK_MACAU_PHONE = /^[6]\d{7}$/gm;