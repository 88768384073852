import './LocationForm.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import { handleGenerateDateSlot } from '../../utils';
import FormWrapper from '../FormWrapper';
import LocationStoreItem from './LocationStoreItem';
import { resetValue } from './utils';

const LocationForm = (props) => {
  const { layoutData, values, setFieldValue, touched, errors } = props;
  const [preferredDateData, setPreferredDateData] = useState([]);
  const [preferredTimeSlot, setPreferredTimeSlot] = useState([]);
  const [isDefaultLocation, setIsDefaultLocation] = useState(false);
  const pageName = SitecoreContextFactoryService.getValueContextRouteItem('displayName');
  const pageChangeSchedule = 'event change';
  const [msgErrorTime, setMsgErrorTime] = useState('');

  useEffect(() => {
    if (values?.location?.id) {
      const dateSlot = handleGenerateDateSlot(layoutData?.['EventDetails']?.Location, values?.location?.id);

      handleSetPreferredDate(dateSlot);
    }
  }, [values?.location?.id, layoutData?.['EventDetails']?.Location]);

  useEffect(() => {
    if (values?.preferredDate?.value) {
      const dateSlot = handleGenerateDateSlot(layoutData?.['EventDetails']?.Location, values?.location?.id);
      const dateSlotSelected = dateSlot?.find((item) => item.Id.replace(/{|}/g, '').toLowerCase() === values?.preferredDate?.dateSlotId.toLowerCase());

      if (dateSlotSelected) {
        handleSetPreferredTimeSlot(dateSlotSelected);
      }
    }
  }, [values?.preferredDate?.value]);

  const handleSetPreferredDate = (dateSlot) => {
    const preferredDateDataTemp = [];
    const nowDate = new Date();
    const curDate = nowDate.getDate();
    const curMonth = nowDate.getMonth();
    const curYear = nowDate.getFullYear();

    for (let date of dateSlot) {
      const arrAvailableTimeSlot = [];

      for (const timeSlot of date?.['Time Slots']) {
        arrAvailableTimeSlot.push(timeSlot.Available);
      }
      
      const eventDate = new Date(date?.['Event Date']?.slice(0, -6));
      const dayOfEvDate = eventDate.getDate();
      const monthOfEvDate = eventDate.getMonth();
      const yearOfEvDate = eventDate.getFullYear();
      
      const isDisableDate =
        yearOfEvDate < curYear ||
        (yearOfEvDate === curYear && monthOfEvDate < curMonth) ||
        (yearOfEvDate === curYear && monthOfEvDate === curMonth && dayOfEvDate < curDate);
      
      const isDisableTimeSlot = arrAvailableTimeSlot.every(available => available === false);
      
      preferredDateDataTemp.push({
        label: date?.['Event Date Format 1'],
        value: date?.['Event Date Format 1'],
        timeSlot: date?.['Time Slots'],
        dateSlotId: date.Id?.replace(/{|}/g, ''),
        disabled: isDisableDate || isDisableTimeSlot
      });
    }

    setPreferredDateData(preferredDateDataTemp);
  };

  const handleSetPreferredTimeSlot = (date) => {
    const timeSlotArr = date['Time Slots'] || [];
    const nowTimeOfDate = new Date().getTime();
    const dateOfEvent = new Date(date?.['Event Date']?.slice(0, -6));

    const arrTimeSlot = timeSlotArr?.map((time) => {
      const timeNumber = time.TimeNumber.split(':');
      
      dateOfEvent.setHours(timeNumber[0]);
      
      dateOfEvent.setMinutes(timeNumber[1]);
      
      dateOfEvent.setSeconds(timeNumber[2]);
      let isDisable = !(time.Available) || nowTimeOfDate > dateOfEvent.getTime();

      return {
        label: time.Time,
        value: time.Id,
        disable: isDisable
      };
    });

    setPreferredTimeSlot(arrTimeSlot);

    // Handle check time slot of day is full
    const fullTimeSlot = arrTimeSlot.every((time) => time.disable === true);

    fullTimeSlot ? setMsgErrorTime(layoutData['Not Available Message Text']?.value) : setMsgErrorTime('');
  };

  const handleSelectLocation = (locationData) => {
    setFieldValue('location', { id: locationData.Id?.replace(/{|}/g, ''), title: locationData.Title });

    handleSetPreferredDate(locationData?.['Date Slot']);

    setPreferredTimeSlot([]);

    setTimeout(()=> {
      resetValue(['preferredDate', 'preferredTimeSlot'], setFieldValue);
      setFieldValue('numberOfGuest', '')
    }, 0)
  };

  useEffect(() => {
    const arrLocation = layoutData?.['EventDetails']?.Location;

    if (arrLocation && arrLocation.length === 1 && pageName === pageChangeSchedule) {
      handleSetPreferredDate(arrLocation[0]?.['Date Slot']);
    
      setPreferredTimeSlot([]);

      setTimeout(()=> {
        resetValue(['preferredDate', 'preferredTimeSlot'], setFieldValue);
        setFieldValue('numberOfGuest', '')
      }, 0)
    
      setIsDefaultLocation(true);
    }
  }, [layoutData?.['EventDetails']?.Location]);

  const handleSelectPreferredData = (date) => {
    setFieldValue('preferredDate', { value: date.value, dateSlotId: date.dateSlotId });

    setTimeout(()=> {
      resetValue(['preferredTimeSlot'], setFieldValue);
      setFieldValue('numberOfGuest', '')
    }, 0)

    // Handle set preferred time slot in useEffect value preferred date of formik
    // handleSetPreferredTimeSlot(date);
  };

  const handleSelectPreferredTimeSlot = (e, timeSlotLabel) => {
    setFieldValue('preferredTimeSlot', { timeSlotId: e.target.value, value: timeSlotLabel });
  };

  const handleSelectNumberOfGuest = (e) => {
    setFieldValue('numberOfGuest', e.target.value);
  };

  const handleSelectAnswer = (answerId, questionId) => {
    const questionAndAnswerTemp = deepCopy(values.questionAndAnswer);
    const checkAnswerExist = questionAndAnswerTemp.find((item) => item.questionId === questionId);

    if (checkAnswerExist) {
      checkAnswerExist.answerId = answerId;
    } else {
      questionAndAnswerTemp.push({ answerId, questionId });
    }

    setFieldValue('questionAndAnswer', questionAndAnswerTemp);
  };

  return (
    <FormWrapper title={layoutData?.['Title']?.value}>
      <div className='location-form'>
        <div className='row'>
          <div className='location-form__left col-12 col-lg-6'>
            <Text tag='h2' field={layoutData?.['Select Location Label']} />
            {touched?.location && errors?.location ? <span className='error-validate text-left'>{errors?.location?.id}</span> : null}
            <div className='location-form__left__list-store'>
              {layoutData?.['EventDetails']?.Location.map((location) => (
                <div
                  key={location.Id}
                  className={`store-item${values?.location.id === location.Id?.replace(/{|}/g, '') || isDefaultLocation ? ' store-active' : ''}`}
                  onClick={() => handleSelectLocation(location)}
                >
                  <LocationStoreItem locationData={location} />
                </div>
              ))}
            </div>
          </div>
          <div className='location-form__right col-12 col-lg-6'>
            <div className='preferred-date'>
              <Text tag='h2' field={layoutData?.['Select Date Label']} />
              <div className='form-group'>
                <Select
                  name='preferredDate'
                  value={preferredDateData.find((option) => option.value === values?.preferredDate.value) || ''}
                  options={preferredDateData}
                  onChange={(date) => handleSelectPreferredData(date)}
                  className='customization-dropdown customization-dropdown--lg-gray'
                  classNamePrefix='customization-dropdown'
                  placeholder='Please select a date'
                  isOptionDisabled={(option) => option.disabled}
                />
                {touched.preferredDate && errors.preferredDate ? <span className='error-validate text-left'>{errors.preferredDate?.dateSlotId}</span> : null}
              </div>
            </div>
            {preferredTimeSlot.length ? (
              <div className='preferred-time-slot'>
                <Text tag='h2' field={layoutData?.['Select Time Label']} />
                <div className='radio-list'>
                  {preferredTimeSlot.map((timeSlot) => (
                    <div key={timeSlot.value} className='radio-item'>
                      <input
                        type='radio'
                        checked={values?.preferredTimeSlot.timeSlotId === timeSlot.value}
                        name='time-slot'
                        id={timeSlot.value}
                        value={timeSlot.value}
                        onChange={(event) => handleSelectPreferredTimeSlot(event, timeSlot.label)}
                        disabled={timeSlot.disable}
                      />
                      <label htmlFor={timeSlot.value}>{timeSlot.label}</label>
                    </div>
                  ))}
                </div>
                {touched.preferredTimeSlot && errors.preferredTimeSlot ? <span className='error-validate text-left'>{msgErrorTime ? msgErrorTime : errors.preferredTimeSlot?.timeSlotId}</span> : null}
              </div>
            ) : (
              <></>
            )}
            <div className='number-of-guest'>
              <Text tag='h2' field={layoutData?.['Select Number Label']} />
              <div className='radio-list'>
                <div className='radio-item'>
                  <input
                    type='radio'
                    checked={values?.numberOfGuest === layoutData?.['Just Me Value']?.value}
                    name='guest'
                    id={layoutData?.['Just Me Value']?.value}
                    value={layoutData?.['Just Me Value']?.value}
                    onChange={handleSelectNumberOfGuest}
                    disabled={`${values?.preferredDate?.dateSlotId ? '' : 'disabled'}`}
                  />
                  <label htmlFor={layoutData?.['Just Me Value']?.value}>
                    <Text field={layoutData?.['Just Me Label']} />
                  </label>
                </div>
                <div className='radio-item'>
                  <input
                    type='radio'
                    checked={values?.numberOfGuest === layoutData?.['Me Plus Value']?.value}
                    name='guest'
                    id={layoutData?.['Me Plus Value']?.value}
                    value={layoutData?.['Me Plus Value']?.value}
                    onChange={handleSelectNumberOfGuest}
                    disabled={`${values?.preferredDate?.dateSlotId ? '' : 'disabled'}`}
                  />
                  <label htmlFor={layoutData?.['Me Plus Value']?.value}>
                    <Text field={layoutData?.['Me Plus Label']} />
                  </label>
                </div>
              </div>
              {touched.numberOfGuest && errors.numberOfGuest ? <span className='error-validate text-left'>{errors.numberOfGuest}</span> : null}
            </div>
            <div className='question-answer'>
              <Text tag='h2' field={layoutData?.['Q And A Label']} />
              {layoutData?.['EventDetails']?.['Question and Answer']?.map((qa, idx) => (
                <>
                  <div key={qa.Id} className='qa-list'>
                    <h3>{qa.Question}</h3>
                    <div className='qa-answer'>
                      {qa.Answers?.map((answer) => (
                        <div key={answer.Id} className='qa-item'>
                          <input
                            type='radio'
                            checked={Boolean(values?.questionAndAnswer.find((item) => item.answerId === answer.Id && item.questionId === qa.Id))}
                            name={`questionAndAnswer-${qa.Id}`}
                            id={`questionAndAnswer-${answer.Id}`}
                            value={answer.Id}
                            onChange={(event) => handleSelectAnswer(event.target.value, qa.Id)}
                          />
                          <label htmlFor={`questionAndAnswer-${answer.Id}`}>{answer.Answer}</label>
                        </div>
                      ))}
                    </div>
                    {touched.questionAndAnswer && errors.questionAndAnswer ? 
                      <span className='error-validate text-left'>
                        {errors.questionAndAnswer?.[idx]?.answerId}
                      </span> 
                      : null}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

LocationForm.propTypes = {
  values: PropTypes.shape({
    location: PropTypes.any,
    preferredDate: PropTypes.any,
    preferredTimeSlot: PropTypes.any,
    numberOfGuest: PropTypes.string,
    questionAndAnswer: PropTypes.array
  }),
  setFieldValue: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  layoutData: PropTypes.object
};

export default LocationForm;
