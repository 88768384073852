import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import Message from '@components/Share/Message';
import { MINUMUM_CHARACTERS_PASSWORD } from '@utils/constant';
import global from '@utils/global';

import { useCampaignContext } from '../useCampaignContext';

const useSelectStoreAndDateFormik = (layoutData, onSubmit) => {
  const { contactData } = useSelector((state) => state.campaignRegisterReducer.getContactReducer);
  const { userRegisterInfo } = useCampaignContext();
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  const initialValues = useMemo(() => {
    const contactAnswers = [];
    if (layoutData?.campaign?.QuestionsAndAvailableAnswers?.length) {
      for (const question of layoutData?.campaign?.QuestionsAndAvailableAnswers) {
        contactAnswers.push({
          QuestionId: question.Id,
          IsRequired: question.IsRequired,
          AnswerIds: []
        });
      }
    }
    if (layoutData?.appointment?.QuestionAnswers?.length) {
      for (const question of layoutData?.appointment?.QuestionAnswers) {
        const questionData = contactAnswers.find((item) => item.QuestionId === question.QuestionId);
        if (questionData) {
          questionData.AnswerIds = [...question.AnswerIds];
        }
      }
    }

    return {
      storeId: layoutData?.appointment?.Store?.Id || '',
      date: layoutData?.appointment?.Date ? new Date(layoutData?.appointment?.Date) : new Date(new Date().toDateString()), // backend data response
      // should be: 2023-03-30T00:00:00
      numberOfGuests: layoutData?.appointment?.NumberOfGuests ?? 1,
      contactAnswers: contactAnswers,
      agreePolicy: true,
      allowReceiveConfirmSMS: true,
      allowReceiveMarketingSMS: contactData?.AllowSMS,
      allowReceiveMarketingEmail: contactData?.AllowEmail,
      allowReceiveMarketingDirectMail: contactData?.AllowDirectMail,
      allowCreateAccount: false,
      email: userRegisterInfo.email ?? '',
      dob: userRegisterInfo.dob ?? '',
      gender: settingGlobal?.GenderDatasource?.find((item) => {
        return item.Key === settingGlobal?.DefaultGender;
      })?.Key || '',
      password: '',
      captcha: ''
    };
  }, [layoutData?.campaign, layoutData?.appointment, contactData, settingGlobal]);

  return useFormik({
    initialValues: initialValues,
    // validateOnChange: false,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      storeId: Yup.string().required(layoutData?.selectStoreErrorMessage?.value),
      contactAnswers: Yup.array(
        Yup.object().shape({
          IsRequired: Yup.boolean().notRequired(),
          AnswerIds: Yup.array().when('IsRequired', {
            is: true,
            then: (rule) => rule.min(1, layoutData?.['selectAnswersMessage']?.value)
          })
        })
      ),
      agreePolicy: Yup.bool().oneOf([true], layoutData?.['agreeTermsAndConditionsMessage']?.value),
      email: Yup.string()
        .email(layoutData?.['inputValidEmailAddressMessage']?.value)
        .when('allowCreateAccount', {
          is: true,
          then: Yup.string().required(<Message messageCode='Msg_001.3' />)
        }),
      dob: Yup.string()
        .trim()
        .when('allowCreateAccount', {
          is: true,
          then: Yup.string().required(<Message messageCode='Msg_001.4' />)
        }),
      gender: Yup.string()
        .trim()
        .when('allowCreateAccount', {
          is: true,
          then: Yup.string().required(<Message messageCode='Msg_019' />)
        }),
      password: Yup.string().when('allowCreateAccount', {
        is: true,
        then: Yup.string()
          .required(<Message messageCode='Msg_001.5' />)
          .matches(global.formatPassword(MINUMUM_CHARACTERS_PASSWORD), messageObj?.['Msg_001.5'])
      }),
      captcha: Yup.string()
        .trim()
        .when('allowCreateAccount', {
          is: true,
          then: Yup.string().required(<Message messageCode='Msg_001.6' />)
        })
    }),
    onSubmit: (values, formikHelpers) => {
      if (onSubmit) {
        formikHelpers.setErrors({});
        // INFO: remove IsRequired field
        for (const item of values.contactAnswers) {
          delete item.IsRequired;
        }

        onSubmit(values);
      }
    }
  });
};

export default useSelectStoreAndDateFormik;
