import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';

export const useSetScheduleData = () => {
  const { setScheduleData } = useScheduleAppointmentContext();

  return useCallback(
    (values) => {
      setScheduleData((prev) => ({
        ...prev,
        contactDetail: {
          ...prev.contactDetail,
          firstName: values.firstName,
          lastName: values.lastName,
          emailAddress: values.emailAddress || '',
          dob: values.dob,
          mobileNumber: values.mobileNumber,
          phonePrefix: values.phonePrefix,
          mobileNumberWithPrefix: `${values.phonePrefix}${values.mobileNumber}`
        },
        policyAndTermCheckbox: {
          ...prev.policyAndTermCheckbox,
          agreePolicy: values.agreePolicy,
          acceptPDDA: values.acceptPDDA
        }
      }));
    },
    [setScheduleData]
  );
};

export const useGenerateBookExperienceParams = () => {
  const { scheduleData, layoutData } = useScheduleAppointmentContext();
  const { contactData } = useSelector((state) => state.campaignRegisterReducer.getContactReducer);
  const { accountUser } = useSelector((state) => state.singleSignOnReducer.userInfoReducer);

  return useCallback(
    ({ firstName, lastName, mobileNumber, emailAddress, dob }) => {
      return {
        productId: scheduleData.scheduleExperience.productId,
        contactId: contactData?.Id || layoutData?.contact?.Id || '',
        osimAccountUsername: accountUser?.Username,

        storeId: scheduleData.scheduleExperience.storeId,
        date: scheduleData.scheduleExperience.date,
        timeSlot: scheduleData.scheduleExperience.timeSlot,
        guest: scheduleData.scheduleExperience.guest,

        teaId: scheduleData.personalizeExperience.teaId,
        scentId: scheduleData.personalizeExperience.scentId,
        additionalSelectionAns: scheduleData.personalizeExperience.additionalSelectionAns,

        firstName: firstName,
        lastName: lastName,
        mobileNumber: mobileNumber,
        emailAddress: emailAddress,
        dob: dob
      };
    },
    [scheduleData, contactData, accountUser, layoutData?.contact]
  );
};
