import '@components/CheckoutProcess/AccountChecking/AccountChecking.scss';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-date-picker';
import { connect, useDispatch, useSelector } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import PhoneNumberSelect from '@components/Share/PhoneNumberSelect';
import DateOfBirth from '@components/SingleSignOn/DateOfBirth';
import { Yup } from '@components/SingleSignOn/RegisterLogin/Register/utils';
import { checkCommerceUser, setInfoUserCheckout } from '@redux/actions/checkoutProcess';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

const FormGuestCheckout = (props) => {
  const { inputData, isLoading, error, isLoginSuccess, settingGlobal, guestData } = props;
  const [datePicker, setDatePicker] = useState(guestData.dob ? new Date(guestData.dob) : '');
  const [gender, setGender] = useState(null);
  const dispatch = useDispatch();
  const fieldPhoneCode = useRef('');
  const formikRef = useRef();
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);

  const [schema, setSchema] = useState(
    Yup.object({
      firstName: Yup.string()
        .required(<Message messageCode='Msg_001.1' />)
        .matches(Global.nameValidateRegex(), messageObj?.['Msg_001.1'])
        .trim(),
      lastName: Yup.string()
        .required(<Message messageCode='Msg_001.2' />)
        .matches(Global.nameValidateRegex(), messageObj?.['Msg_001.2'])
        .trim(),
      email: Yup.string()
        .email(<Message messageCode='Msg_001.3' />)
        .required(<Message messageCode='Msg_001.3' />)
        .trim(),
      dob: Yup.string()
        .required(<Message messageCode='Msg_001.4' />)
        .trim(),
      gender: Yup.string()
        .required(<Message messageCode='Msg_019' />)
        .trim(),
      emailConfirmation: Yup.string()
        .trim()
        .required(inputData?.['Email Confirmation Required Message']?.value)
        .oneOf([Yup.ref('email'), null], inputData?.['Email Confirmation Not Match Message']?.value)
    })
  );

  const initialValues = {
    firstName: guestData?.firstName || '',
    lastName: guestData?.lastName || '',
    dob: guestData?.dob || '',
    gender: guestData?.gender || '',
    email: guestData?.email || '',
    phoneNumber: guestData?.phoneNumber || '',
    emailConfirmation: guestData?.email || ''
  };

  const handleSubmit = (values) => {
    dispatch(setInfoUserCheckout(deepCopy({ ...values, phoneNumberCode: fieldPhoneCode.current, email: values.email.trim() })));

    dispatch(checkCommerceUser(values.email.trim()));
  };

  useEffect(() => {
    if (guestData?.gender?.length) {
      setGender(guestData?.gender || '');
    } else {
      let temp = settingGlobal?.GenderDatasource?.find((item) => {
        return item.Key === settingGlobal?.DefaultGender;
      });

      setGender(temp?.Key || '');
    }
  }, [settingGlobal?.DefaultGender]);

  useDidUpdateEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue('gender', gender);
    }
  }, [gender]);

  useEffect(() => {
    if (settingGlobal && settingGlobal?.PhoneNumberMust) {
      setSchema(
        Yup.object({
          firstName: Yup.string()
            .required(<Message messageCode='Msg_001.1' />)
            .matches(Global.nameValidateRegex(), messageObj?.['Msg_001.1'])
            .trim(),
          lastName: Yup.string()
            .required(<Message messageCode='Msg_001.2' />)
            .matches(Global.nameValidateRegex(), messageObj?.['Msg_001.2'])
            .trim(),
          email: Yup.string()
            .email(<Message messageCode='Msg_001.3' />)
            .required(<Message messageCode='Msg_001.3' />)
            .trim(),
          phoneNumber: settingGlobal.PhoneNumberMust
            ? settingGlobal.IsPhoneNumberRequired
              ? Yup.string()
                .required(<Message messageCode='Msg_020' />)
                .trim()
                .validatePhone({
                  min: settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust,
                  max: settingGlobal.PhoneNumberMust,
                  prefixCode: fieldPhoneCode.current || '',
                  errorMsg: <Message messageCode='Msg_001.7' />
                })
              : Yup.string()
                .validatePhone({
                  min: settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust,
                  max: settingGlobal.PhoneNumberMust,
                  prefixCode: fieldPhoneCode.current || '',
                  errorMsg: <Message messageCode='Msg_001.7' />
                })
            : settingGlobal.IsPhoneNumberRequired
              ? Yup.string()
                .required(<Message messageCode='Msg_020' />)
                .trim()
                .validatePhone({
                  min: settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust,
                  prefixCode: fieldPhoneCode.current || '',
                  errorMsg: <Message messageCode='Msg_001.7' />
                })
              : Yup.string().validatePhone({
                min: settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust,
                prefixCode: fieldPhoneCode.current || '',
                errorMsg: <Message messageCode='Msg_001.7' />
              }),
          dob:
            settingGlobal.IsDoBRequired &&
            Yup.string()
              .required(<Message messageCode='Msg_001.4' />)
              .trim(),
          gender:
            settingGlobal.IsGenderRequired &&
            Yup.string()
              .required(<Message messageCode='Msg_019' />)
              .trim(),
          emailConfirmation: Yup.string()
            .trim()
            .required(inputData?.['Email Confirmation Required Message']?.value)
            .oneOf([Yup.ref('email'), null], inputData?.['Email Confirmation Not Match Message']?.value)
        })
      );

      fieldPhoneCode.current = guestData?.phoneNumberCode || settingGlobal.AreaCodes[0].value;
    }
  }, [settingGlobal, messageObj]);

  return gender !== null ? (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={(values) => handleSubmit(values)} innerRef={formikRef}>
      {({ errors, touched, handleChange, values, setFieldValue }) => {
        const onHandleTelephoneChange = (evt, fieldName) => {
          const phoneValueWithoutPrefix = evt.target.value;
          // let telephone = `${fieldPhoneCode.current || ''}${phoneValueWithoutPrefix}`;
          if (!phoneValueWithoutPrefix || new RegExp(settingGlobal.PhoneNumberFormat).test(phoneValueWithoutPrefix)) {
            setFieldValue(fieldName, phoneValueWithoutPrefix);
          }
        };

        return (
          <Form id='info-guest-checkout' className='account-checking__form'>
            <Text className='account-checking__form__text' tag='p' field={inputData['Please Enter Your Details Label']} />
            {error && !isLoginSuccess ? (
              <span className='error-validate error-validate--custom'>
                <Message messageCode='Msg_003' />
              </span>
            ) : (
              ''
            )}
            {settingGlobal?.FormatName === 'First Name First' ? (
              <>
                <div className='account-checking__form__group'>
                  <div className={errors.firstName && touched.firstName ? 'form-group input-error-validate' : 'form-group'}>
                    <input
                      type='text'
                      className={values.firstName ? 'form-control form-control-lg input-valid' : 'form-control form-control-lg'}
                      placeholder={inputData['First Name Label'].value}
                      onChange={handleChange}
                      value={values.firstName}
                      id='firstName'
                      name='firstName'
                    />
                    <Text htmlFor='firstName' className='form-group__label' field={inputData['First Name Label']} tag='label' />
                    {errors.firstName && touched.firstName && <span className='error-validate'>{errors.firstName}</span>}
                  </div>
                </div>
                <div className='account-checking__form__group'>
                  <div className={errors.lastName && touched.lastName ? 'form-group input-error-validate' : 'form-group'}>
                    <input
                      type='text'
                      className={values.lastName ? 'form-control form-control-lg input-valid' : 'form-control form-control-lg'}
                      placeholder={inputData['Last Name Label'].value}
                      onChange={handleChange}
                      value={values.lastName}
                      id='lastName'
                      name='lastName'
                    />
                    <Text htmlFor='lastName' className='form-group__label' field={inputData['Last Name Label']} tag='label' />
                    {errors.lastName && touched.lastName && <span className='error-validate'>{errors.lastName}</span>}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='account-checking__form__group'>
                  <div className={errors.lastName && touched.lastName ? 'form-group input-error-validate' : 'form-group'}>
                    <input
                      type='text'
                      className={values.lastName ? 'form-control form-control-lg input-valid' : 'form-control form-control-lg'}
                      placeholder={inputData['Last Name Label'].value}
                      onChange={handleChange}
                      value={values.lastName}
                      id='lastName'
                      name='lastName'
                    />
                    <Text htmlFor='lastName' className='form-group__label' field={inputData['Last Name Label']} tag='label' />
                    {errors.lastName && touched.lastName && <span className='error-validate'>{errors.lastName}</span>}
                  </div>
                </div>
                <div className='account-checking__form__group'>
                  <div className={errors.firstName && touched.firstName ? 'form-group input-error-validate' : 'form-group'}>
                    <input
                      type='text'
                      className={values.firstName ? 'form-control form-control-lg input-valid' : 'form-control form-control-lg'}
                      placeholder={inputData['First Name Label'].value}
                      onChange={handleChange}
                      value={values.firstName}
                      id='firstName'
                      name='firstName'
                    />
                    <Text htmlFor='firstName' className='form-group__label' field={inputData['First Name Label']} tag='label' />
                    {errors.firstName && touched.firstName && <span className='error-validate'>{errors.firstName}</span>}
                  </div>
                </div>
              </>
            )}
            <div className='account-checking__form__group'>
              <div className={errors.dob && touched.dob ? 'form-group input-error-validate' : 'form-group'}>
                {/* <DatePicker
                  name='dob'
                  value={datePicker}
                  onChange={(e) => {
                    setDatePicker(e);

                    setFieldValue('dob', (e && Global.customizeISOStringHourType(e)) || '');
                  }}
                  format='dd/MMM/yyyy'
                  className={
                    values['dob'] ? 'date-picker-custom form-control form-control-lg input-valid' : 'date-picker-custom form-control form-control-lg'
                  }
                  dayPlaceholder='dd'
                  monthPlaceholder='mm'
                  yearPlaceholder='yyyy'
                /> */}
                <DateOfBirth setFieldValue={setFieldValue} dobLabel={inputData['DoB Label']} />
                {/* <Text field={inputData['DoB Label']} tag='label' className='form-group__label' /> */}
                {errors.dob && touched.dob && <span className='error-validate'>{errors.dob}</span>}
              </div>
            </div>
            {settingGlobal?.GenderDatasource.length ? (
              <div className='account-checking__form__group'>
                <div
                  className={errors.gender && touched.gender ? 'form-group form-group--gender input-error-validate' : 'form-group form-group--gender'}
                >
                  <div>
                    {settingGlobal?.GenderDatasource.map((item) => {
                      return (
                        <div className='osim-radio' key={item.Key}>
                          <input
                            type='radio'
                            name='gender'
                            value={item.Key}
                            className='osim-radio-input'
                            checked={gender?.toLowerCase() === item.Key.toLowerCase()}
                            readOnly
                          />
                          <label
                            className='osim-radio-label'
                            onClick={() => {
                              setGender(item.Key);

                              setFieldValue('gender', item.Key);
                            }}
                          />
                          <label
                            className='osim-radio-title'
                            onClick={() => {
                              setGender(item.Key);

                              setFieldValue('gender', item.Key);
                            }}
                          >
                            {item.Value}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  {errors.gender && touched.gender && <span className='error-validate'>{errors.gender}</span>}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className='account-checking__form__group'>
              <div className={errors.email && touched.email ? 'form-group input-error-validate' : 'form-group'}>
                <input
                  type='text'
                  className={values.email ? ' form-control form-control-lg input-valid' : ' form-control form-control-lg'}
                  placeholder={inputData['Email Label'].value}
                  onChange={handleChange}
                  value={values.email.trim()}
                  id='email'
                  name='email'
                />
                <Text htmlFor='email' className='form-group__label' field={inputData['Email Label']} tag='label' />
                {errors.email && touched.email && <span className='error-validate'>{errors.email}</span>}
              </div>
            </div>
            <div className='account-checking__form__group'>
              <div className={errors.emailConfirmation && touched.emailConfirmation ? 'form-group input-error-validate' : 'form-group'}>
                <input
                  type='text'
                  autoComplete='new-password'
                  className={values.emailConfirmation ? ' form-control form-control-lg input-valid' : ' form-control form-control-lg'}
                  placeholder={inputData['Email Confirmation Label'].value}
                  onChange={handleChange}
                  value={values.emailConfirmation.trim()}
                  id='emailConfirmation'
                  name='emailConfirmation'
                />
                <Text htmlFor='emailConfirmation' className='form-group__label' field={inputData['Email Confirmation Label']} tag='label' />
                {errors.emailConfirmation && touched.emailConfirmation && <span className='error-validate'>{errors.emailConfirmation}</span>}
              </div>
              <Text className='small-description' field={inputData['Send Email Policy Label']} tag='small' />
            </div>
            <div className='account-checking__form__group'>
              <div
                className={`${errors.phoneNumber && touched.phoneNumber ? 'form-group input-error-validate' : 'form-group'} ${
                  (settingGlobal?.UseAreaCode && 'form-group--group-select-input') || ''
                }`}
              >
                {settingGlobal?.UseAreaCode ? (
                  <PhoneNumberSelect
                    setPhoneNumberCodeEvt={(code) => (fieldPhoneCode.current = code)}
                    optionValues={settingGlobal.AreaCodes}
                    currentValue={guestData.phoneNumberCode}
                  />
                ) : (
                  ''
                )}
                <div className='form-group--input'>
                  <input
                    type='text'
                    className={values.phoneNumber ? ' form-control form-control-lg input-valid' : ' form-control form-control-lg'}
                    placeholder={inputData['Phone Number Label'].value}
                    onChange={(evt) => onHandleTelephoneChange(evt, 'phoneNumber')}
                    value={values.phoneNumber}
                    id='phoneNumber'
                    name='phoneNumber'
                  />
                  <Text htmlFor='phoneNumber' className='form-group__label' field={inputData['Phone Number Label']} tag='label' />
                  {errors.phoneNumber && touched.phoneNumber && <span className='error-validate'>{errors.phoneNumber}</span>}
                </div>
              </div>
              <Text className='small-description' tag='small' field={inputData['Call Policy Label']} />
            </div>
            <div className='account-checking__form__button'>
              {inputData['Continue to Delivery Button Text'].value ? (
                <div className='account-checking__form__button__submit text-center'>
                  <button type='submit' className='btn btn-primary'>
                    <Text field={inputData['Continue to Delivery Button Text']} />
                  </button>
                </div>
              ) : (
                ''
              )}
              {inputData['Back to Cart Button Text'].value ? (
                <div className='account-checking__form__button__link text-center'>
                  <Link className='btn-link btn-link--back' field={inputData['Back to Cart Button Link']}>
                    <Text field={inputData['Back to Cart Button Text']} />
                  </Link>
                </div>
              ) : (
                ''
              )}
            </div>
            {isLoading ? <LoadingMask /> : ''}
          </Form>
        );
      }}
    </Formik>
  ) : (
    <></>
  );
};

FormGuestCheckout.propTypes = {
  inputData: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.any,
  errors: PropTypes.any,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setCheckGuestEvt: PropTypes.func,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  isLoginSuccess: PropTypes.bool,
  settingGlobal: PropTypes.any,
  guestData: PropTypes.any
};

const mapStateToProps = (state) => ({
  isLoginSuccess: state.singleSignOnReducer.userInfoReducer?.isLogin,
  error: state.checkoutProcesstReducer.accountCheckingReducer?.error,
  isLoading: state.singleSignOnReducer.loginReducer?.isLoading,
  settingGlobal: state.settingGlobalReducer.settingGlobal,
  guestData: state.checkoutProcesstReducer.dataCheckoutReducer.infoUser || {}
});

export default connect(mapStateToProps)(FormGuestCheckout);
