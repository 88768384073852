import { object } from 'prop-types';
import React, { useMemo } from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

const ContactDetail = ({ layoutData }) => {
  // const changeContactDetailBtnLink = useMemo(() => {
  //   const appointmentId = layoutData?.appointment?.Id;
  //   const layoutLink = layoutData?.['Change Selection Link']?.value?.href;
  //   const changeSelectionLink = `${layoutLink}?${SCHEDULE_APPOINTMENT_PARAM_NAME}=${SCHEDULE_APPOINTMENT_STEP_CONTACT_FORM}&appointment_id=${appointmentId}`;
  //
  //   return layoutLink
  //     ? {
  //       value: {
  //         ...layoutData['Change Contact Link'].value,
  //         href: changeSelectionLink
  //       }
  //     }
  //     : '';
  // }, []);

  const firstName = useMemo(() => {
    return layoutData?.appointment?.FirstName || layoutData?.contact?.FirstName;
  }, [layoutData]);

  const lastName = useMemo(() => {
    return layoutData?.appointment?.LastName || layoutData?.contact?.LastName;
  }, [layoutData]);

  const emailAddress = useMemo(() => {
    return layoutData?.appointment?.EmailAddress || layoutData?.contact?.EmailAddress;
  }, [layoutData]);

  const mobileNumber = useMemo(() => {
    return layoutData?.appointment?.MobileNumber || layoutData?.contact?.MobileNumber;
  }, [layoutData]);

  return (
    <div className='appointment-detail__body__item appointment-detail__body__item--contact-details'>
      <Text field={layoutData?.['Contact Label']} tag='h3' className='appointment-detail__body__item__title' />
      {layoutData?.Icon && Object.keys(layoutData?.Icon?.value)?.length ? (
        <div className='appointment-detail__body__item__img'>
          <Image field={layoutData?.Icon} />
        </div>
      ) : (
        <></>
      )}
      <div className='appointment-detail__body__item__content'>
        <Text field={{ value: `${firstName} ${lastName}` }} tag='h3' className='appointment-detail__body__item__content__title user-name' />
        <Text field={{ value: emailAddress }} tag='p' className='appointment-detail__body__item__content__description' />
        {mobileNumber ? (
          <Text field={{ value: `+${mobileNumber}` }} tag='p' className='appointment-detail__body__item__content__description' />
        ) : (
          <></>
        )}
      </div>
      {/*{layoutData?.isUpComing ? (*/}
      {/*  changeContactDetailBtnLink ? (*/}
      {/*    <a*/}
      {/*      className='appointment-detail__body__item__button btn btn-outline-primary'*/}
      {/*      href={changeContactDetailBtnLink?.value?.href}*/}
      {/*    >*/}
      {/*      <Text field={layoutData?.['Change Contact Text']} />*/}
      {/*    </a>*/}
      {/*  ) : (*/}
      {/*    <></>*/}
      {/*  )*/}
      {/*) : (*/}
      {/*  <></>*/}
      {/*)}*/}
    </div>
  );
};

ContactDetail.propTypes = {
  layoutData: object
};

export default ContactDetail;
