import { object } from 'prop-types';
import React from 'react';

import { RichText } from '@sitecore-jss/sitecore-jss-react';

import { useControlRichText } from './hooks/useControlRichText';

const AddToCartSuccess = ({ inputData }) => {
  const isShowRichText = useControlRichText();

  return isShowRichText ? (
    <div className='rich-text-content add-to-cart-success'>
      <div className='container'>
        <RichText field={inputData.Text} />
      </div>
    </div>
  ) : (
    <></>
  );
};

AddToCartSuccess.propTypes = {
  inputData: object
};

export default AddToCartSuccess;
