import PropTypes from 'prop-types';
import React from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const CPStoreSelector = ({fields, selectStoreLabel, handleSelectStore}) => {
  //INFO: get cpCustomerId to know is runing startDemo
  const cpCustomerId = SitecoreContextFactoryService.getValueContextItem('cpCustomerId') || '';

  return fields?.Stores?.length > 1 ? (
    <div className='group-name'>
      <Text className='popup-sub-title' tag='h3' field={selectStoreLabel} />
      <ul className={`list-store${cpCustomerId ? ' list-store__disable' : ''}`}>
        {
          fields.Stores.map((store, index) => (
            <li className='group-name__link' key={store.StoreId || index} onClick={() => handleSelectStore(store)}>
              {store.StoreName}
            </li>
          ))
        }
      </ul>
    </div>
  ): '';
};

CPStoreSelector.propTypes = {
  fields: PropTypes.any,
  selectStoreLabel: PropTypes.any,
  handleSelectStore: PropTypes.func,
};

export default CPStoreSelector;