import {bool, object} from 'prop-types';
import React, { Fragment, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  // getContactPersonalInformationSuccess,
  setIsDeactiveAccess
} from '@redux/actions/clientTellingPortalCustomer';
import {
  getContactPersonalInformation,
  // getCPCustomer
} from '@redux/actions/clientTellingPortalCustomer';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { HK_SITE_NAME, TW_SITE_NAME } from '@utils/constant';
import { useFormatEnDate } from '@utils/customsHook/useFormatDate';
import { convertHKTWDate } from '@utils/customsHook/useFormatDate/utils';

const ExistingCustomerInformation = ({ layoutData, data, isDirectMailingHidden, isSMSCommunicationHidden }) => {
  const [phoneNumberDisplay, setPhoneNumberDisplay] = useState(false);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const dispatch = useDispatch();
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const currentLanguage = SitecoreContextFactoryService.getValueContextItem('language');
  const formatEnDate = useFormatEnDate();

  const isConvertDate = useMemo(() => {
    return (currentSiteName === HK_SITE_NAME && currentLanguage === 'zh-HK') || currentSiteName === TW_SITE_NAME;
  }, [currentSiteName, currentLanguage]);

  const getContactPersonal = (e, type) => {
    if (e.type == 'mousedown') {
      !clientTellingPortalCustomer[`${type}`] &&
        dispatch(getContactPersonalInformation({ id: clientTellingPortalCustomer.customerInformation?.ContactID, type }));

      setPhoneNumberDisplay(true);
    } else {
      setPhoneNumberDisplay(false);
    }
  };

  const dobText = useMemo(() => {
    let value = 'N/A';
    const rawDob = data?.customerInformation?.DOB;
    if (rawDob) {
      value = isConvertDate
        ? convertHKTWDate({
          dateString: rawDob,
          isHideYear: true
        })
        : formatEnDate({
          date: rawDob,
          isHideYear: true
        });
    }

    return value;
  }, [data, isConvertDate]);

  return (
    <div className='customer-acknowledge__form cp-form cp-form--existing-cus'>
      {data?.customerInformation?.PersonalInformation?.FirstName?.length || data?.customerInformation?.PersonalInformation?.LastName?.length ? (
        <h5 className='customer-acknowledge__form__cus-name'>{`${data?.customerInformation?.PersonalInformation?.FirstName} ${data?.customerInformation?.PersonalInformation?.LastName}`}</h5>
      ) : (
        <h5 className='customer-acknowledge__form__cus-name'>
          {clientTellingPortalCustomer?.contacts?.[0]?.contact_type !== 'PDPA lead' && 'Anonymous'}
        </h5>
      )}
      <div className='cp-form__wrap'>
        <div className='cp-form__left'>
          <div className='form-group d-flex'>
            <div className='cp-form__icon-left'>
              <Image field={layoutData['Mail Icon']} />
            </div>
            <span className='cp-form__value-text'>{data?.customerInformation?.Email || 'N/A'}</span>
          </div>
          <div className='form-group d-flex'>
            <div className='cp-form__icon-left'>
              <Image field={layoutData['Birthday Icon']} />
            </div>
            <div className='cp-form__group select-small'>
              {/*<span>{data?.customerInformation?.DOB || 'N/A'}</span>*/}
              <span>{dobText}</span>
            </div>
          </div>
        </div>
        <div className='cp-form__right'>
          <div className='form-group d-flex'>
            <div className='cp-form__icon-left'>
              <Image field={layoutData['Phone Icon']} />
            </div>
            <div className='cp-form__group d-flex'>
              {data?.customerInformation?.PhoneNumber?.length ? (
                <>
                  <span className='cp-form__value-text phone-value'>
                    {data?.customerInformation?.PrefixPhoneNumber}
                    {data?.PhoneNumber && phoneNumberDisplay ? data?.PhoneNumber : data?.customerInformation?.PhoneNumber}
                  </span>
                  <span
                    className='cp-icon cp-icon--eye'
                    onMouseDown={(e) => getContactPersonal(e, 'PhoneNumber')}
                    onMouseUp={(e) => getContactPersonal(e, 'PhoneNumber')}
                  />
                </>
              ) : (
                <span className='cp-form__value-text phone-value'>N/A</span>
              )}
            </div>
          </div>
          <div className='form-group form-group--mb-30 d-flex d-flex--space-between '>
            <ul className='cp-form__social-icon'>
              <li className='cp-form__social-icon__item cp-checkbox-icon'>
                <label
                  className={
                    clientTellingPortalCustomer.customerInformation.CPCustomerInfo?.CommunicationChannels?.[0]?.AllowWeChat &&
                    'cp-checkbox-icon__icon is-checked'
                  }
                >
                  <Image field={layoutData['Message Button Icon']} />
                </label>
              </li>
              <li className='cp-form__social-icon__item cp-checkbox-icon'>
                <label
                  className={
                    clientTellingPortalCustomer.customerInformation.CPCustomerInfo?.CommunicationChannels?.[0]?.AllowWhatsapp &&
                    'cp-checkbox-icon__icon is-checked'
                  }
                >
                  <Image field={layoutData['Dial Button Icon']} />
                </label>
              </li>
              {!isSMSCommunicationHidden ? (
                <li className='cp-form__social-icon__item cp-checkbox-icon'>
                  <label
                    className={
                      clientTellingPortalCustomer.customerInformation.CPCustomerInfo?.CommunicationChannels?.[0]?.AllowSMS &&
                      'cp-checkbox-icon__icon is-checked'
                    }
                  >
                    <Image field={layoutData['SMS Icon']} />
                  </label>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className='cp-form__wrap'>
        <div className='cp-form__left'>
          {layoutData.Questions.map((q, iq) => {
            return (
              <Fragment key={iq}>
                {iq % 2 != 0 ? (
                  <div className='form-group d-flex'>
                    <div className='cp-form__icon-left'>
                      <Image field={q.Image} />
                    </div>
                    <span key={iq} className='cp-form__value-text'>
                      {q?.['Question Code'] == 'Gender'
                        ? data.customerInformation?.PersonalInformation?.Gender || 'N/A'
                        : data.customerInformation?.CPCustomerInfo?.[`${q?.['Question Code']}`] || 'N/A'}
                    </span>
                  </div>
                ) : (
                  <Fragment key={iq}></Fragment>
                )}
              </Fragment>
            );
          })}
        </div>
        <div className='cp-form__right'>
          {layoutData.Questions.map((q, iq) => {
            return (
              <Fragment key={iq}>
                {iq % 2 == 0 ? (
                  <div className='form-group d-flex'>
                    <div className='cp-form__icon-left'>
                      <Image field={q.Image} />
                    </div>
                    <span key={iq} className='cp-form__value-text'>
                      {q?.['Question Code'] == 'Gender'
                        ? data.customerInformation?.PersonalInformation?.Gender || 'N/A'
                        : data.customerInformation?.CPCustomerInfo?.[`${q?.['Question Code']}`] || 'N/A'}
                    </span>
                  </div>
                ) : (
                  <Fragment key={iq}></Fragment>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
      {!layoutData?.['Is Signature Hidden']?.value && (
        <div className='form-group signature-canvas'>
          {data.customerInformation?.CPCustomerInfo?.SignatureImageUrl?.length ? (
            <img className='form-control form-control-lg' src={data.customerInformation?.CPCustomerInfo?.SignatureImageUrl} />
          ) : (
            <div className='form-control form-control-lg' />
          )}
        </div>
      )}
      <div className='form-group'>
        <div className='cp-form__checkbox'>
          <div className='osim-checkbox'>
            <input
              type='checkbox'
              id='cp-checkbox-1'
              className='osim-checkbox-input'
              checked={data.customerInformation?.CPCustomerInfo?.PDPAAgreement || false}
              readOnly
            />
            <label className='osim-checkbox-label'>
              <RichText tag='div' field={layoutData['PAPA Agreement Message']} />
            </label>
          </div>
        </div>
      </div>
      <div className='form-group form-group--checkboxes form-group--checkbox-pd-left'>
        {/*<span className='form-group--checkboxes__label'>I wish to receive marketing information via the following channels:</span>*/}
        <div className='form-group--checkboxes__list'>
          <div className='osim-checkbox'>
            <input
              type='checkbox'
              id='receiveSms'
              name='receiveSms'
              className='osim-checkbox-input'
              checked={data.customerInformation?.CPCustomerInfo?.AllowSms || false}
              readOnly
            />
            <label className='osim-checkbox-label'>
              <Text field={layoutData['SMS Label']} />
            </label>
          </div>
          <div className='osim-checkbox'>
            <input
              type='checkbox'
              id='receiveMail'
              name='receiveMail'
              className='osim-checkbox-input'
              checked={data.customerInformation?.CPCustomerInfo?.AllowEmail || false}
              readOnly
            />
            <label className='osim-checkbox-label'>
              <Text field={layoutData['Mail Label']} />
            </label>
          </div>
          {!isDirectMailingHidden ? (
            <div className='osim-checkbox'>
              <input
                type='checkbox'
                id='receiveDirectMail'
                name='receiveDirectMail'
                className='osim-checkbox-input'
                checked={data.customerInformation?.CPCustomerInfo?.AllowDirectMail || false}
                readOnly
              />
              <label className='osim-checkbox-label'>
                <Text field={layoutData['Direct Mailing Label']} />
              </label>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className='cp-form__button cp-form__button--center'>
        <button className='cp-form__button__item btn btn-primary' onClick={() => dispatch(setIsDeactiveAccess())}>
          <Text field={layoutData['Close Button Label']} />
        </button>
      </div>
    </div>
  );
};

ExistingCustomerInformation.propTypes = {
  layoutData: object,
  data: object,
  isSMSCommunicationHidden: bool,
  isDirectMailingHidden: bool
};

export default ExistingCustomerInformation;
