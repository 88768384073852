import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useGetCheckedPDPA = () => {
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);

  return useMemo(() => {
    return clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.PDPAAgreement ?? false;
  }, [clientTellingPortalCustomer]);
};
