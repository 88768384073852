import { any } from 'prop-types';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { addCurlyBrackets } from '@redux/epic/bookingExperience/utils';
import { useQuery } from '@utils/customsHook/useQuery';
import Global from '@utils/global';

import { scheduleDataInitialState } from './configs';
import { SCHEDULE_DATA_SESSION_STORAGE_NAME } from './FormikStep/ContactDetailsForm/InputForm/UpdateBEPhoneNumberText';
import Layout from './Layout';
import ScheduleAppointmentComponentModel from './models/ScheduleAppointmentComponentModel';

const initialStates = {
  layoutData: null,
  scheduleData: scheduleDataInitialState,
  setScheduleData: () => {}
};

export const ScheduleAppointmentContext = createContext(initialStates);

const ScheduleAppointmentComponent = ({ fields }) => {
  const query = useQuery();
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const accountUserInfo = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.accountUser);
  const [layoutData, setLayoutData] = useState(null);
  const [scheduleData, setScheduleData] = useState(scheduleDataInitialState);

  const useAreaCode = useMemo(() => {
    return settingGlobal?.UseAreaCode;
  }, [settingGlobal]);

  const areaCodes = useMemo(() => {
    return settingGlobal?.AreaCodes || [];
  }, [settingGlobal]);

  useEffect(() => {
    const model = new ScheduleAppointmentComponentModel();

    setLayoutData(model.getData(fields || {}));
  }, []);

  useEffect(() => {
    const checkInitialValuesEmpty = Object.keys(scheduleData).filter((key) =>
      key !== 'policyAndTermCheckbox'
        ? Object.keys(scheduleData[key]).every((childKey) =>
          typeof scheduleData[key][childKey] === 'string' ? !scheduleData[key][childKey] : !scheduleData[key][childKey]?.length
        )
        : false
    );
    const appointmentId = query.get('appointment_id');
    const bookingExperienceScheduleDataPlainValue = window.sessionStorage.getItem(SCHEDULE_DATA_SESSION_STORAGE_NAME);
    if ((appointmentId && layoutData?.appointment && checkInitialValuesEmpty.length) || bookingExperienceScheduleDataPlainValue) {
      const appointmentData = layoutData?.appointment;
      const scheduleLocalData = JSON.parse(bookingExperienceScheduleDataPlainValue);
      const scheduleDate = appointmentData?.Date || scheduleLocalData?.scheduleExperience?.date;
      let defaultValue = appointmentData?.MobileNumber || scheduleLocalData?.contactDetail?.mobileNumber || accountUserInfo?.PhoneNumber || '';
      const defaultPrefixCode = useAreaCode && areaCodes.length ? areaCodes[0].value : '';
      const phoneFormat = Global.splitPhoneNumberWithoutCode(useAreaCode, defaultValue, areaCodes);

      setScheduleData((prevState) => ({
        ...prevState,
        scheduleExperience: {
          ...prevState.scheduleExperience,
          productId: query.get('productId') || layoutData?.appointment?.Product?.Id,
          storeId: addCurlyBrackets(appointmentData?.Store?.Id || scheduleLocalData?.scheduleExperience?.storeId),
          date: scheduleDate ? new Date(scheduleDate) : undefined,
          guest: appointmentData?.NumberOfGuests || scheduleLocalData?.scheduleExperience?.guest,
          timeSlot: addCurlyBrackets(appointmentData?.TimeSlot?.Id || scheduleLocalData?.scheduleExperience?.timeSlot)
        },
        personalizeExperience: {
          ...prevState.personalizeExperience,
          teaId: appointmentData?.Tea?.Id || scheduleLocalData?.personalizeExperience?.teaId,
          scentId: appointmentData?.Scent?.Id || scheduleLocalData?.personalizeExperience?.scentId,
          additionalSelectionAns: [...(appointmentData?.QuestionAnswers || scheduleLocalData?.personalizeExperience?.additionalSelectionAns)]
        },
        contactDetail: {
          ...prevState.contactDetail,
          firstName: appointmentData?.FirstName || scheduleLocalData?.contactDetail?.firstName,
          lastName: appointmentData?.LastName || scheduleLocalData?.contactDetail?.lastName,
          dob: appointmentData?.DateOfBirth || scheduleLocalData?.contactDetail?.dob,
          emailAddress: appointmentData?.EmailAddress || scheduleLocalData?.contactDetail?.emailAddress,
          phonePrefix: phoneFormat.code || defaultPrefixCode,
          mobileNumber: phoneFormat.value,
          mobileNumberWithPrefix: `${phoneFormat.code || defaultPrefixCode}${phoneFormat.value}`
        }
      }));
    }
  }, [layoutData?.appointment, layoutData?.contact, query, useAreaCode, areaCodes]);

  return layoutData ? (
    <ScheduleAppointmentContext.Provider
      value={{
        layoutData: layoutData,
        scheduleData: {
          ...scheduleData,
          scheduleExperience: {
            ...scheduleData.scheduleExperience,
            productId: query.get('productId') || layoutData?.appointment?.Product?.Id
          }
        },
        setScheduleData: setScheduleData
      }}
    >
      <Layout />
    </ScheduleAppointmentContext.Provider>
  ) : (
    <></>
  );
};

ScheduleAppointmentComponent.propTypes = {
  fields: any
};

export default ScheduleAppointmentComponent;
