import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SitecoreLinkModel from '@models/SitecoreLinkModel';
import { setLoginLink } from '@redux/actions/unsubscribe';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import AccountQuickAccessFieldsModel from './Model/AccountQuickAccessFieldsModel';

const AccountQuickAccess = (props) => {
  const { fields } = props;
  const [data, setData] = useState(null);
  const [welcomeMessage, setMessage] = useState('');
  const userInfoReducer = useSelector((state) => state.singleSignOnReducer.userInfoReducer);
  const dispatch = useDispatch();
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  useEffect(() => {
    dispatch(setLoginLink(props?.fields?.['Login Link'] || new SitecoreLinkModel().getData({})));
    const accountQuickAccessFieldsModel = new AccountQuickAccessFieldsModel();
    if(settingGlobal?.LoginLink === window.location.pathname) {
      setData(accountQuickAccessFieldsModel.getData(deepCopy(fields || {})));
    } else {
      setData(accountQuickAccessFieldsModel.getData(deepCopy(fields || {}), `from=${window.location.href}`));
    }
  }, []);

  useDidUpdateEffect(() => {
    const accountQuickAccessFieldsModel = new AccountQuickAccessFieldsModel();
    if(settingGlobal?.LoginLink === window.location.pathname) {
      setData(accountQuickAccessFieldsModel.getData(deepCopy(fields || {})));
    } else {
      setData(accountQuickAccessFieldsModel.getData(deepCopy(fields || {}), `from=${window.location.href}`));
    }
  }, [settingGlobal]);

  useEffect(() => {
    if(userInfoReducer.isLogin && data) {
      const welcomeMessageTmp = deepCopy(data['Welcome Message']);

      welcomeMessageTmp.value = welcomeMessageTmp.value.replace('{user-first-name}', userInfoReducer.accountUser?.FirstName || '').replace('{user-last-name}', userInfoReducer.accountUser?.LastName || '');

      setMessage(welcomeMessageTmp);
    }
  }, [userInfoReducer.accountUser, data]);

  return (
    data && (
      <div className='account-quick-access-menu'>
        {userInfoReducer.isLogin ? (
          <Fragment>
            <Link role='link' className='account-quick-access header__right-contents__user' title='my account link' field={data['My Account Link']}>
              <div className='account-quick-access header__right-contents__user-icon header-icon-bg'></div>
            </Link>
            {welcomeMessage ? <Link className='account-quick-access__name' field={data['My Account Link']}>
              <Text field={welcomeMessage}/>
            </Link> : ''}
          </Fragment>
        ) : (
          <Link className='account-quick-access header__right-contents__user' title='login link' field={data['Login Link']}>
            <div className='account-quick-access header__right-contents__user-icon header-icon-bg'></div>
          </Link>
        )}
      </div>
    )
  );
};

AccountQuickAccess.propTypes = {
  fields: PropTypes.any
};

export default AccountQuickAccess;
