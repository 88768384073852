import { any, bool } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import PhoneNumberSelect from '@components/Share/PhoneNumberSelect';
import DateOfBirth from '@components/SingleSignOn/DateOfBirth';
import { useQuery } from '@utils/customsHook/useQuery';
import global from '@utils/global';

import { useDisplayFormikMessage } from '../../../hooks/useDisplayFormikMessage';
import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';
import { useContactDetailFormLayoutContext } from '../FormLayout';
import UpdateBEPhoneNumberText from './UpdateBEPhoneNumberText';

const InputForm = ({ isLoginSuccess, accountUserInfo, settingGlobal }) => {
  const query = useQuery();
  const { layoutData } = useScheduleAppointmentContext();
  const { formik } = useContactDetailFormLayoutContext();
  const displayErrorMessage = useDisplayFormikMessage();

  const isDisableInputField = useMemo(() => {
    return query.get('appointment_id') || isLoginSuccess;
  }, [isLoginSuccess, query]);

  const isDisableMobileNumberInput = useMemo(() => {
    return (isLoginSuccess && accountUserInfo?.PhoneNumber) || layoutData?.appointment?.MobileNumber;
  }, [isLoginSuccess, accountUserInfo, layoutData]);

  const useAreaCode = useMemo(() => {
    return settingGlobal?.UseAreaCode;
  }, [settingGlobal]);

  const areaCodes = useMemo(() => {
    return settingGlobal?.AreaCodes || [];
  }, [settingGlobal]);

  const handlePhoneNumberInputChange = useCallback(
    (evt) => {
      let telephoneWithoutPrefix = evt.target.value;

      // const telephoneWithPrefix = `${formik.values.phonePrefix}${telephoneWithoutPrefix}`;
      if (!telephoneWithoutPrefix || new RegExp(settingGlobal.PhoneNumberFormat).test(telephoneWithoutPrefix)) {
        formik.setFieldValue('mobileNumber', telephoneWithoutPrefix);
      }
    },
    [formik, settingGlobal]
  );

  return (
    <>
      <div className={global.renderDynamicClass(formik.errors.firstName && formik.touched.firstName, 'form-group', 'input-error-validate')}>
        <input
          type='text'
          placeholder={layoutData?.['First Name']?.value}
          className={global.renderDynamicClass(formik.values.firstName, 'form-control form-control-lg', 'input-valid')}
          name='firstName'
          id='firstName'
          autoComplete='off'
          onChange={formik.handleChange}
          value={formik.values.firstName}
          disabled={isDisableInputField}
        />
        <span className='error-validate'>{displayErrorMessage(formik, 'firstName')}</span>
      </div>

      <div className={global.renderDynamicClass(formik.errors.lastName && formik.touched.lastName, 'form-group', 'input-error-validate')}>
        <input
          type='text'
          placeholder={layoutData?.['Last Name']?.value}
          className={global.renderDynamicClass(formik.values.lastName, 'form-control form-control-lg', 'input-valid')}
          name='lastName'
          id='lastName'
          autoComplete='off'
          onChange={formik.handleChange}
          value={formik.values.lastName}
          disabled={isDisableInputField}
        />
        <span className='error-validate'>{displayErrorMessage(formik, 'lastName')}</span>
      </div>

      <div className={global.renderDynamicClass(formik.errors.dob && formik.touched.dob, 'form-group', 'input-error-validate')}>
        <DateOfBirth
          setFieldValue={formik.setFieldValue}
          dobLabel={layoutData?.['Date Of Birth Text']}
          isRegister={true}
          disabled={isDisableInputField}
        />
      </div>

      <div className={global.renderDynamicClass(formik.errors.email && formik.touched.email, 'form-group', 'input-error-validate')}>
        <input
          type='text'
          placeholder={layoutData?.['Email']?.value}
          className={global.renderDynamicClass(formik.values.emailAddress, 'form-control form-control-lg', 'input-valid')}
          name='emailAddress'
          id='emailAddress'
          autoComplete='off'
          onChange={formik.handleChange}
          value={formik.values.emailAddress}
          disabled={isDisableInputField}
        />
        <span className='error-validate'>{displayErrorMessage(formik, 'emailAddress')}</span>
      </div>
      <div className='form-group contact-detail__body-phone-number-input'>
        <div
          className={
            useAreaCode
              ? `${global.renderDynamicClass(
                formik.errors.phone && formik.touched.phone,
                'form-group',
                'input-error-validate'
              )} form-group--group-select-input`
              : global.renderDynamicClass(formik.errors.phone && formik.touched.phone, 'form-group', 'input-error-validate')
          }
        >
          {useAreaCode ? (
            <PhoneNumberSelect
              setPhoneNumberCodeEvt={(code) => formik.setFieldValue('phonePrefix', code)}
              optionValues={areaCodes}
              currentValue={formik.values.phonePrefix}
              isDisabled={isDisableMobileNumberInput}
            />
          ) : (
            <></>
          )}
          <div className='form-group--input'>
            <input
              type='text'
              placeholder={layoutData?.['Phone Number']?.value}
              className={global.renderDynamicClass(formik.values.mobileNumber, 'form-control form-control-lg', 'input-valid')}
              name='mobileNumber'
              id='mobileNumber'
              autoComplete='off'
              onChange={handlePhoneNumberInputChange}
              value={formik.values.mobileNumber}
              disabled={isDisableMobileNumberInput}
            />
            <span className='error-validate'>{displayErrorMessage(formik, 'mobileNumber')}</span>
          </div>
        </div>
        {accountUserInfo?.PhoneNumber && formik.values.mobileNumber ? <UpdateBEPhoneNumberText /> : <></>}
      </div>
    </>
  );
};

InputForm.propTypes = {
  isLoginSuccess: bool,
  accountUserInfo: any,
  settingGlobal: any
};

const mapStateToProps = (state) => ({
  isLoginSuccess: state.singleSignOnReducer.userInfoReducer?.isLogin,
  accountUserInfo: state.singleSignOnReducer.userInfoReducer?.accountUser,
  settingGlobal: state.settingGlobalReducer.settingGlobal
});

export default connect(mapStateToProps)(InputForm);
