import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isStickyHeader$ } from '@components/Product/VariantDetail/ProductDetailHeader/handleStickyHeaderService';
import NotifyMePopup from '@components/Product/VariantDetail/ProductDetailHeader/NotifyMePopup';
import { behaviorCreateBundle } from '@components/PromotionDetailBundle/CreateBundle/CreateBundleBehavior';
import ResponsiveImage from '@components/ResponsiveImage';
import CountdownTimerCampaign from '@components/Share/CountdownTimer/CountdownTimerCampaign';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import Loading from '@components/Share/Loading';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import ModalComponent from '@components/Share/ModalComponent';
import { initBundleData } from '@redux/actions/dynamicBundle';
import { getNotifyStatusProduct, resetMessagePopupNotify } from '@redux/actions/product';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import {Placeholder} from '@sitecore-jss/sitecore-jss-react';
import { LIST_CLASS_NAME__DESKTOP, LIST_CLASS_NAME__MOBILE, TEXT_COLOR_WHITE } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import DynamicBundleHeaderModel from './model/DynamicBundleHeaderModel';

const DynamicBundleHeader = ({ fields, params, ...props }) => {
  const dispatch = useDispatch();
  const dynamicBundleReducer = useSelector((state) => state.dynamicBundleReducer);
  const bundleSellingPrice = useSelector((state) => state.dynamicBundleReducer.sellingPrice);
  const bundleListingPrice = useSelector((state) => state.dynamicBundleReducer.listingPrice);
  const allowBuy = useSelector((state) => state.dynamicBundleReducer.allowBuy);
  const buyOptionLink = useSelector((state) => state.dynamicBundleReducer.buyOptionLink);
  const isNotifySuccess = useSelector((state) => state.productReducer.notifyProductStatusReducer.isNotifySuccess);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const countdownFormatSettingObj = useSelector((state) => state.settingGlobalURLReducer.settingGlobalURL);
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);

  const [layoutData, setLayoutData] = useState(null);
  const [inputData, setInputData] = useState(null);
  const [showNotifyMe, setShowNotifyMe] = useState(false);
  const [isShowNotifyMe, setIsShowNotifyMe] = useState(false);
  const [isDynamicBundleHeaderSticky, setIsDynamicBundleHeaderSticky] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [installment, setInstallment] = useState(null);
  const [sellingPrice, setSellingPrice] = useState(null);
  const [installmentCartLevel, setInstallmentCartLevel] = useState(null);
  const [installmentProductLevel, setInstallmentProductLevel] = useState(null);
  const [installmentCartLevelAndProductLevel, setInstallmentCartLevelAndProductLevel] = useState(null);

  const isGA4 = Global.getIsGA4();

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  useEffect(() => {
    if (scrollTop > 0) {
      setIsDynamicBundleHeaderSticky(true);

      isStickyHeader$.next(true);
    } else {
      setIsDynamicBundleHeaderSticky(false);

      isStickyHeader$.next(false);
    }
  }, [scrollTop]);

  const message = useMemo(() => {
    if (isNotifySuccess?.message) {
      if (isNotifySuccess.status) {
        return <p className='notify-success'>{isNotifySuccess.message}</p>;
      } else {
        return <p className='notify-faild'>{isNotifySuccess.message}</p>;
      }
    }

    return null;
  }, [isNotifySuccess]);

  useEffect(() => {
    const model = new DynamicBundleHeaderModel();

    setLayoutData(model.getData(deepCopy(fields)));

    const startTime = new Date(SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Time']?.value).toISOString();

    const today = new Date().toISOString();

    if (today < startTime) {
      setShowNotifyMe(true);
    }

    setInputData({
      bannerImage: SitecoreContextFactoryService.getValueContextRouteItem('fields')['Banner Image']?.value.src || '',
      mobileBannerImage: SitecoreContextFactoryService.getValueContextRouteItem('fields')['Mobile Banner Image']?.value.src || '',
      startTime: SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Time']?.value || '',
      endTime: SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Time']?.value || '',
      description: SitecoreContextFactoryService.getValueContextRouteItem('fields')['Page Description']?.value || ''
    });
  }, []);

  useEffect(() => {
    dispatch(initBundleData(fields));
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem('showNotifyMeDynamicBundle', showNotifyMe);
  }, [showNotifyMe]);

  useEffect(() => {
    const behaviorCreateBundleSubscription = behaviorCreateBundle.subscribe((params) => {
      setInstallment(params.installment);

      setSellingPrice(params.sellingPrice);
    });

    return () => {
      behaviorCreateBundleSubscription.unsubscribe();
    };
  }, [behaviorCreateBundle]);

  useDidUpdateEffect(() => {
    if (currentCart) {
      setInstallmentCartLevel(
        currentCart?.PaymentConfiguration?.Gateways.length &&
          currentCart?.PaymentConfiguration?.Gateways.filter(
            (gateway) =>
              gateway?.SupportInstallment && gateway?.InstallmentCartLevel && !gateway?.InstallmentProductLevel && gateway?.InstallmentPeriods.length
          )
      );

      setInstallmentProductLevel(
        currentCart?.PaymentConfiguration?.Gateways.length &&
          currentCart?.PaymentConfiguration?.Gateways.filter(
            (gateway) => gateway?.SupportInstallment && gateway?.InstallmentProductLevel && !gateway?.InstallmentCartLevel && installment?.length
          )
      );

      setInstallmentCartLevelAndProductLevel(
        currentCart?.PaymentConfiguration?.Gateways.length &&
          currentCart?.PaymentConfiguration?.Gateways.filter(
            (gateway) => gateway?.SupportInstallment && gateway?.InstallmentProductLevel && gateway?.InstallmentCartLevel && installment?.length
          )
      );
    }
  }, [currentCart, installment, sellingPrice]);

  const bannerTextPositionDesktop = params['Desktop Text Position'] || '';
  const bannerTextPositionMobi = params['Mobile Text Position'] || '';
  const bannerTextColor = params['Text Color'] || '';

  const classRenderMobile = (data) => {
    return LIST_CLASS_NAME__MOBILE[data] || '';
  };

  const classRenderDesktop = (data) => {
    return LIST_CLASS_NAME__DESKTOP[data] || '';
  };

  const scrollToCreateBundle = () => {
    let element = document.getElementById('create-bundle');

    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };

  const onSubmitNotifyHandler = (emailSubmit) => {
    dispatch(getNotifyStatusProduct({ payload: emailSubmit }));
  };

  const onClosePopupNotifyHandler = () => {
    setIsShowNotifyMe(false);

    dispatch(resetMessagePopupNotify());
  };

  const handleGa4SelectItem = (action) => {
    if (isGA4) {
      const queryParams = new URLSearchParams(window.location.search);
      const pdpPromoCode = queryParams.get('cCode');
      const categoryName = SitecoreContextFactoryService.getValueContextRouteItem('displayName');
      const currencyCode = dynamicBundleReducer?.currency?.Name;
      const idxProdGA4 = Global.getIndexGA4Param();

      const dataBundleGA4 = [
        {
          BundleId: layoutData?.['Dynamic Bundle Data']?.BundleId,
          BundleName: layoutData?.['Dynamic Bundle Data']?.BundleName,
          Price: {
            ListPrice: dynamicBundleReducer?.listingPrice,
            SellingPrice: dynamicBundleReducer?.sellingPrice
          },
          VariantPropertyValue: 'Dynamic Bundle',
          Stock: {
            StockQuantity: 1
          }
        }
      ];

      let paramsSelectItem = {
        ecommerce: {
          action: action,
          items: Global.handleGA4ViewAndSelectItems(idxProdGA4, dataBundleGA4, categoryName, pdpPromoCode, '', currencyCode)
        }
      };

      new GoogleAnalytic().gAnalytic4('event', 'select_item', paramsSelectItem);
    }
  };

  return layoutData && countdownFormatSettingObj ? (
    <Fragment>
      <div
        className={`promotion-detail-bundle__header${
          isDynamicBundleHeaderSticky ? ' promotion-detail-bundle__header-sticky' : ''
        } animated-slow fadeIn delay-100`}
      >
        <div className='container promotion-detail-bundle__header__wrap'>
          <div className='promotion-detail-bundle__header__text'>
            {/* <span className='promotion-detail-bundle__header__text__name'>{layoutData['Dynamic Bundle Data'].BundleName}</span> */}
            <CountdownTimerCampaign
              endDate={SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Time']?.value || ''}
              endText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Soon Message']?.value || ''}
              remainingDate={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Time Remaining']?.value || ''}
              startDate={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Time']?.value || ''}
              startText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Soon Message']?.value || ''}
              validText={layoutData['Dynamic Bundle Data'].BundleName}
              wasEndText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Was End Message']?.value || ''}
              countdownFormatSetting={countdownFormatSettingObj['CountdownFormatSetting']}
              componentType='PromotionHeader'
            />
            {/* <CountdownTimerItem
                productName={layoutData['Dynamic Bundle Data'].BundleName}
                startTime={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Time'].value}
                endTime={SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Time'].value}
                remainingTime={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Time Remaining'].value}
                dataFromLayout={layoutData}
                startMsg={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Soon Message'].value}
                endMsg={SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Soon Message'].value}
              /> */}
            <div className='promotion-detail-bundle__header__text__price'>
              <span className='promotion-detail-bundle__header__text__price__current'>
                {bundleSellingPrice !== 0
                  ? `${Global.formatDynamicCurrency(
                    bundleSellingPrice,
                      layoutData.Currency?.Symbol,
                      layoutData.Currency?.SymbolPosition,
                      layoutData.Currency?.CurrencyNumberFormatCulture,
                      layoutData.Currency?.NumberFormat?.CurrencyDecimalDigits
                  )}`
                  : ''}
              </span>
              <del className='promotion-detail-bundle__header__text__price__old'>
                {bundleListingPrice !== 0 && bundleListingPrice > bundleSellingPrice
                  ? `${Global.formatDynamicCurrency(
                    bundleListingPrice,
                      layoutData.Currency?.Symbol,
                      layoutData.Currency?.SymbolPosition,
                      layoutData.Currency?.CurrencyNumberFormatCulture,
                      layoutData.Currency?.NumberFormat?.CurrencyDecimalDigits
                  )}`
                  : ''}
              </del>
            </div>
          </div>
          <div className='promotion-detail-bundle__header__btn'>
            {showNotifyMe ? (
              <button className='promotion-detail-bundle__header__btn__item btn btn-outline-primary' onClick={() => setIsShowNotifyMe(true)}>
                {messageObj?.['LBL-NotifyMe']}
              </button>
            ) : (
              <a
                href={`${layoutData['Buy Now Button Link'].value.href}${buyOptionLink}&LstName=${
                  SitecoreContextFactoryService.getValueContextRouteItem('displayName') || ''
                }`}
                className={`btn btn-primary ${allowBuy ? '' : 'disabled'}`}
                onClick={() => handleGa4SelectItem('Buy Now')}
              >
                {layoutData['Buy Now Button Label'].value}
              </a>
            )}
          </div>
        </div>
      </div>

      <Placeholder name='osim-product-main-top' rendering={props.rendering}/>

      <div className='promotion-detail-bundle'>
        <div className={bannerTextColor === TEXT_COLOR_WHITE ? 'promotion-detail-bundle__banner text-white' : 'promotion-detail-bundle__banner'}>
          <ResponsiveImage
            cssClassPicture='promotion-detail-bundle__banner__image'
            imageMobile={inputData.mobileBannerImage}
            srcSet={[{ mw: 768, image: inputData.bannerImage }]}
            attributes={{
              alt: 'x'
            }}
          />
          <div className='promotion-detail-bundle__banner__wrapper-contents'>
            <div
              className={
                'container content-position ' + classRenderMobile(bannerTextPositionMobi) + ' ' + classRenderDesktop(bannerTextPositionDesktop)
              }
            >
              <div className='promotion-detail-bundle__banner__contents content-wrapper'>
                {layoutData['Dynamic Bundle Data'].BundleName?.length > 0 && (
                  <h2 className='promotion-detail-bundle__banner__contents__title'>{layoutData['Dynamic Bundle Data'].BundleName}</h2>
                )}

                {inputData.description.length > 0 && (
                  <p className='promotion-detail-bundle__banner__contents__description'>{inputData.description}</p>
                )}

                {layoutData['CTA Button Label'].value.length > 0 && (
                  <div className='promotion-detail-bundle__banner__contents__btn'>
                    <button
                      onClick={() => scrollToCreateBundle()}
                      className='promotion-detail-bundle__banner__contents__btn__item btn btn-outline-primary'
                    >
                      {layoutData['CTA Button Label'].value}
                    </button>
                  </div>
                )}
                {layoutData?.['Payment Gateways']?.Gateways?.map((gateway) => {
                  return (
                    <>
                      {(!gateway?.InstallmentMaxCartAmount && sellingPrice >= gateway?.InstallmentMinCartAmount) ||
                      (gateway?.InstallmentMaxCartAmount &&
                        sellingPrice >= gateway?.InstallmentMinCartAmount &&
                        sellingPrice < gateway?.InstallmentMaxCartAmount) ? (
                          <span className='product-variant-info__content__installment' key={gateway?.Id}>
                            {gateway?.InstallmentDynamicBundleLevelText.replace(
                              '{number_of_payments}',
                            gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period
                            )
                              .replace(
                                '${min}',
                                Math.round(
                                  (sellingPrice / gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period) * 1000
                                ) / 1000
                              )
                              .replace('{logo}', '')}
                            {gateway?.InstallmentDynamicBundleLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                              <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                            ) : (
                              <></>
                            )}
                          </span>
                        ) : (
                          <></>
                        )}
                      {sellingPrice < gateway?.InstallmentMinCartAmount ? (
                        <span className='product-variant-info__content__installment' key={gateway?.Id}>
                          {gateway?.InstallmentCartLevelText.replace('${amount}', gateway?.InstallmentMinCartAmount - sellingPrice).replace(
                            '{logo}',
                            ''
                          )}
                          {gateway?.InstallmentCartLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                            <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                          ) : (
                            <></>
                          )}
                        </span>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
                {/*{installmentProductLevel?.map((gateway) => {*/}
                {/*  return (*/}
                {/*    <span className='product-variant-info__content__installment' key={gateway?.Id}>*/}
                {/*      {gateway?.InstallmentDynamicBundleLevelText.replace(*/}
                {/*        '{number_of_payments}',*/}
                {/*        installment?.slice().sort((a, b) => a?.Period - b?.Period)?.[0]).replace(*/}
                {/*        '${min}',*/}
                {/*        Math.round((sellingPrice / installment?.slice().sort((a, b) => a?.Period - b?.Period)?.[0]) * 1000) / 1000*/}
                {/*      ).replace('{logo}', '')}*/}
                {/*      {gateway?.InstallmentDynamicBundleLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (*/}
                {/*        <img className='installment-img' src={gateway?.PdpLogoItemUrl} />*/}
                {/*      ) : (*/}
                {/*        <></>*/}
                {/*      )}*/}
                {/*    </span>*/}
                {/*  );*/}
                {/*})}*/}
                {/*{installmentCartLevelAndProductLevel?.map((gateway) => {*/}
                {/*  return (*/}
                {/*    <>*/}
                {/*      {(!gateway?.InstallmentMaxCartAmount && sellingPrice >= gateway?.InstallmentMinCartAmount) || (gateway?.InstallmentMaxCartAmount && sellingPrice >= gateway?.InstallmentMinCartAmount && sellingPrice < gateway?.InstallmentMaxCartAmount) ? (*/}
                {/*        <span className='product-variant-info__content__installment' key={gateway?.Id}>*/}
                {/*          {gateway?.InstallmentDynamicBundleLevelText.replace(*/}
                {/*            '{number_of_payments}',*/}
                {/*            installment?.slice().sort((a, b) => a?.Period - b?.Period)?.[0]).replace(*/}
                {/*            '${min}',*/}
                {/*            Math.round((sellingPrice / installment?.slice().sort((a, b) => a?.Period - b?.Period)?.[0]) * 1000) / 1000*/}
                {/*          ).replace('{logo}', '')}*/}
                {/*          {gateway?.InstallmentDynamicBundleLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (*/}
                {/*            <img className='installment-img' src={gateway?.PdpLogoItemUrl} />*/}
                {/*          ) : (*/}
                {/*            <></>*/}
                {/*          )}*/}
                {/*        </span>*/}
                {/*      ) : (*/}
                {/*        <></>*/}
                {/*      )}*/}
                {/*      {sellingPrice < gateway?.InstallmentMinCartAmount ? (*/}
                {/*        <span className='product-variant-info__content__installment' key={gateway?.Id}>*/}
                {/*          {gateway?.InstallmentCartLevelText.replace('${amount}', gateway?.InstallmentMinCartAmount - sellingPrice).replace(*/}
                {/*            '{logo}',*/}
                {/*            ''*/}
                {/*          )}*/}
                {/*          {gateway?.InstallmentCartLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (*/}
                {/*            <img className='installment-img' src={gateway?.PdpLogoItemUrl} />*/}
                {/*          ) : (*/}
                {/*            <></>*/}
                {/*          )}*/}
                {/*        </span>*/}
                {/*      ) : (*/}
                {/*        <></>*/}
                {/*      )}*/}
                {/*    </>*/}
                {/*  );*/}
                {/*})}*/}
                <CountdownTimerCampaign
                  endDate={SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Time']?.value || ''}
                  endText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Soon Message']?.value || ''}
                  remainingDate={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Time Remaining']?.value || ''}
                  startDate={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Time']?.value || ''}
                  startText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Soon Message']?.value || ''}
                  validText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Valid Message']?.value || ''}
                  wasEndText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Was End Message']?.value || ''}
                  countdownFormatSetting={countdownFormatSettingObj['CountdownFormatSetting']}
                  componentType='PromotionHeader'
                />
              </div>
            </div>
          </div>
        </div>
        <ModalComponent
          isShow={isShowNotifyMe}
          titlePopup={<span>{messageObj?.['LBL-NotifyMe']}</span>}
          onCloseHandler={() => onClosePopupNotifyHandler()}
        >
          <NotifyMePopup
            inputData={{ productId: SitecoreContextFactoryService.getValueContextRouteItem('itemId') }}
            notifyMeEvt={onSubmitNotifyHandler}
          >
            {message}
          </NotifyMePopup>
        </ModalComponent>
      </div>
    </Fragment>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <Fragment>
        <div
          className={`promotion-detail-bundle__header${isDynamicBundleHeaderSticky ? ' promotion-detail-bundle__header-sticky' : ''} skeleton__item`}
        >
          <div className='container promotion-detail-bundle__header__wrap'>
            <div className='promotion-detail-bundle__header__text'></div>
            <div className='promotion-detail-bundle__header__btn'>
              <span className='promotion-detail-bundle__header__btn__item btn image-wrap'>
                <span className='image-line'></span>
              </span>
            </div>
          </div>
        </div>
        <div className='promotion-detail-bundle'>
          <div className='promotion-detail-bundle__banner skeleton__item'>
            <Loading></Loading>
          </div>
        </div>
      </Fragment>
    </LoadingShimmer>
  );
};

DynamicBundleHeader.propTypes = {
  fields: PropTypes.any,
  params: PropTypes.any,
  isNotifySuccess: PropTypes.any
};

export default DynamicBundleHeader;
