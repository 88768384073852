import { format } from 'date-fns';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { bookAppointmentStart } from '@redux/actions/campaignRegister/bookAppointment';
import { createContactFailure, createContactSuccess } from '@redux/actions/campaignRegister/createContact';
import { CREATE_CONTACT_START } from '@redux/actions/campaignRegister/createContact/types';
import { postAsObservable } from '@services/genericService';
import { MY_SITE_NAME } from '@utils/constant';
import { isAtSiteName } from '@utils/customsHook/useIsAtSiteName';
import { formatDateToUTC } from '@utils/dateFormatV2';

import { CREATE_CONTACT_ENDPOINT } from './config';
import { CreateContactStatus } from './responseStatus';

const createContactEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_CONTACT_START),
    switchMap((action) => {

      const params = {
        CampaignId: action.payload.campaignId,
        FirstName: action.payload.firstName,
        LastName: action.payload.lastName,
        DateOfBirth: action.payload.dob ? formatDateToUTC(action.payload.dob) : '',
        EmailAddress: action.payload.email,
        MobileNumber: isAtSiteName(MY_SITE_NAME) ? `+${action.payload.phoneNumber}` : action.payload.phoneNumber,
        AllowSMS: action.payload.bookingsValues?.allowReceiveMarketingSMS ?? false,
        AllowEmail: action.payload.bookingsValues?.allowReceiveMarketingEmail ?? false,
        AllowDirectMail: action.payload.bookingsValues?.allowReceiveMarketingDirectMail ?? false,
      };

      return from(
        postAsObservable(CREATE_CONTACT_ENDPOINT, params).pipe(
          switchMap((res) => {
            if (CreateContactStatus.SuccessStatusCode.includes(res.data.StatusCode)) {
              return of(
                createContactSuccess(res.data),
                bookAppointmentStart({
                  ...action.payload.bookingsValues,
                  contactId: res.data.Result.Id
                })
              );
            } else {
              return of(createContactFailure(res.data));
            }
          })
        )
      );
    }),
    catchError((error) => of(createContactFailure(error.message || 'Failed')))
  );

export default createContactEpic;
