import IncludedProductStockModel from '@components/BundleElements/model/IncludedProductStockModel';
import PriceModel from '@components/BundleElements/model/PriceModel';
import BOVariantModel from '@components/BuyingOptions/BOVariants/model/BOVariantsModel';
import BooleanModel from '@components/PopupBanner/model/BooleanModel';
import LabelsModel from '@models/LabelsModel';
import { STOCK_QUANTITY } from '@utils/constant';
import { deepCopy } from '@utils/utility';

import RefundableDepositProductsModel from './RefundableDepositProductsModel';

export default class ProductsModel {
  constructor() {
    this['CatalogName'] = '';

    this['CategoryName'] = '';

    this['Description'] = '';

    this['Id'] = '';

    this['Image'] = '';

    this['Labels'] = [];

    this['Price'] = new PriceModel();

    this['ProductId'] = '';

    this['ProductName'] = '';

    this['ProductOverview'] = '';

    this['ProductType'] = '';

    this['RepresentativeVariantId'] = '';

    this['Stock'] = new IncludedProductStockModel();

    this['Url'] = '';

    this['Variants'] = [];

    this.LastFewUnitsLeftLabel = '';

    this.LastLabel = '';

    this['IsRentalProduct'] = false;

    this['RefundableDepositProducts'] = [];

    this['PriceExtraInformation'] = '';

    this.IsBundle = false;

    this['ItemListId'] = '';

    this['ItemListName'] = '';

    this['item_list_id'] = '';

    this['CategoryPageId'] = '';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CatalogName')) {
      this['CatalogName'] = dataSource['CatalogName'] ?? this['CatalogName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CategoryName')) {
      this['CategoryName'] = dataSource['CategoryName'] ?? this['CategoryName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'] = dataSource['Description'] ?? this['Description'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Id')) {
      this['Id'] = dataSource['Id'] ?? this['Id'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'] = dataSource['Image'] ?? this['Image'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Labels')) {
      let newLabelsList = [];

      dataSource['Labels'] &&
        dataSource['Labels'].map((element) => {
          newLabelsList.push(new LabelsModel().getData(deepCopy(element) || {}));
        });

      this['Labels'] = newLabelsList;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this['Price'].getData(dataSource['Price']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductId')) {
      this['ProductId'] = dataSource['ProductId'] ?? this['ProductId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductName')) {
      this['ProductName'] = dataSource['ProductName'] ?? this['ProductName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductOverview')) {
      this['ProductOverview'] = dataSource['ProductOverview'] ?? this['ProductOverview'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductType')) {
      this['ProductType'] = dataSource['ProductType'] ?? this['ProductType'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'RepresentativeVariantId')) {
      this['RepresentativeVariantId'] = dataSource['RepresentativeVariantId'] ?? this['RepresentativeVariantId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Stock')) {
      this['Stock'].getData(dataSource['Stock']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Url')) {
      this['Url'] = dataSource['Url'] ?? this['Url'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Variants')) {
      let newVariantsList = [];

      dataSource['Variants'] &&
        dataSource['Variants'].map((element) => {
          newVariantsList.push(new BOVariantModel().getData(deepCopy(element) || {}));
        });

      this['Variants'] = newVariantsList;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LastFewUnitsLeftLabel')) {
      this.LastFewUnitsLeftLabel = dataSource.LastFewUnitsLeftLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LastLabel') && dataSource.LastLabel) {
      this.LastLabel = dataSource.LastLabel.replace(STOCK_QUANTITY, dataSource?.Stock?.PurchasableLimit || 0);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsRentalProduct')) {
      this['IsRentalProduct'] = dataSource['IsRentalProduct'] ?? this['IsRentalProduct'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'RefundableDepositProducts')) {
      dataSource['RefundableDepositProducts'] &&
      dataSource['RefundableDepositProducts'].map((element) => {
        this['RefundableDepositProducts'].push(new RefundableDepositProductsModel().getData(deepCopy(element) || {}));
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PriceExtraInformation')) {
      this['PriceExtraInformation'] = dataSource['PriceExtraInformation'] ?? this['PriceExtraInformation'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsBundle')) {
      this['IsBundle'] = dataSource['IsBundle'] ?? this['IsBundle'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemListId')) {
      this['ItemListId'] = dataSource['ItemListId'] ?? this['ItemListId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemListName')) {
      this['ItemListName'] = dataSource['ItemListName'] ?? this['ItemListName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'item_list_id')) {
      this['item_list_id'] = dataSource['item_list_id'] ?? this['item_list_id'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CategoryPageId')) {
      this['CategoryPageId'] = dataSource['CategoryPageId'] ?? this['CategoryPageId'];
    }

    return this;
  }
}
