import { object } from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getContactPersonalInformation, getCPCustomer } from '@redux/actions/clientTellingPortalCustomer';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { HK_SITE_NAME, TW_SITE_NAME } from '@utils/constant';
import { useFormatEnDate } from '@utils/customsHook/useFormatDate';
import { convertHKTWDate } from '@utils/customsHook/useFormatDate/utils';
import { deepCopy } from '@utils/utility';

import CPContactInfoShimmer from './CPContactInfoShimmer';
import CPContactInfoModel from './model/CPContactInfoModel';

const CPContactInfo = ({ fields }) => {
  const dispatch = useDispatch();
  const [layoutData, setLayoutData] = useState(null);
  const [phoneNumberDisplay, setPhoneNumberDisplay] = useState(false);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const communicationCustomerInfo = clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.CommunicationChannels?.[0];
  const communicationJsonFacet = clientTellingPortalCustomer?.contacts?.[0]?.json_facet?.CPCustomerInfo?.CommunicationChannels?.[0];
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const currentLanguage = SitecoreContextFactoryService.getValueContextItem('language');
  const formatEnDate = useFormatEnDate();

  const isConvertDate = useMemo(() => {
    return (currentSiteName === HK_SITE_NAME && currentLanguage === 'zh-HK') || currentSiteName === TW_SITE_NAME;
  }, [currentSiteName, currentLanguage]);

  useEffect(() => {
    const model = new CPContactInfoModel();

    setLayoutData(model.getData(deepCopy(fields || {})));

    let url = new URL(window.location.href);

    dispatch(getCPCustomer({
      id: url.searchParams.get('contactId'),
      fromURL: true,
      isGetCustomerProduct: true
    }));
  }, []);

  const getBirthDay = (params) => {
    if (params) {
      let date = new Date(params);
      let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

      return `${date.getDate() || ''} ${monthArr[date.getMonth()] || ''} ${date.getFullYear() ? '****' : ''}`;
    } else {
      return 'N/A';
    }
  };

  const getContactPersonal = (e, type) => {
    if (e.type == 'mousedown') {
      let url = new URL(window.location.href);

      !clientTellingPortalCustomer[`${type}`] && dispatch(getContactPersonalInformation({ id: url.searchParams.get('contactId'), type }));

      setPhoneNumberDisplay(true);
    } else {
      setPhoneNumberDisplay(false);
    }
  };

  const isHideCommunicationIcon = useMemo(() => {
    return layoutData?.['Is Communication Method Hidden']?.value ?? false;
  }, [layoutData]);

  const dobText = useMemo(() => {
    let value = 'N/A';
    const rawDob = clientTellingPortalCustomer.currentCustomerDetail?.DOB;
    if (rawDob) {
      value = isConvertDate
        ? convertHKTWDate({
          dateString: rawDob,
          isHideYear: true
        })
        : formatEnDate({
          date: rawDob,
          isHideYear: true
        });
    }

    return value;
  }, [clientTellingPortalCustomer.currentCustomerDetail, isConvertDate]);

  return layoutData ? (
    <div className='cp-contact-info profiles__col'>
      {!clientTellingPortalCustomer?.isLoading ? (
        <>
          <div className='customer'>
            <h2 className='customer__name'>
              {clientTellingPortalCustomer?.currentCustomerDetail?.CPCustomerInfo?.contact_type === 'Anonymous lead'
                ? clientTellingPortalCustomer.currentCustomerDetail?.PersonalInformation?.FirstName ||
                  clientTellingPortalCustomer.currentCustomerDetail?.PersonalInformation?.LastName
                  ? `${clientTellingPortalCustomer.currentCustomerDetail?.PersonalInformation?.FirstName} ${clientTellingPortalCustomer.currentCustomerDetail?.PersonalInformation?.LastName}`
                  : 'Anonymous'
                : `${clientTellingPortalCustomer.currentCustomerDetail?.PersonalInformation?.FirstName || ''}  ${
                    clientTellingPortalCustomer.currentCustomerDetail?.PersonalInformation?.LastName || ''
                  }`}
            </h2>
            {/* <div className='customer__rank'>
              <img src={require('@assets/images/CP-images/silver-rank.PNG')} alt='sliver rank' />
            </div> */}
          </div>
          <div className='contact-infor box-data'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='flex-content'>
                  <div className='icon-place'>
                    <Image field={layoutData['Mail Icon']} className='cp-icon' />
                  </div>
                  <div className='data-place'>
                    <span className='data-text'>
                      {clientTellingPortalCustomer.currentCustomerDetail?.Email == 'null' || !clientTellingPortalCustomer.currentCustomerDetail?.Email
                        ? 'N/A'
                        : clientTellingPortalCustomer.currentCustomerDetail?.Email}
                    </span>
                  </div>
                </div>
                <div className='flex-content'>
                  <div className='icon-place'>
                    <Image field={layoutData['Phone Icon']} className='cp-icon' />
                  </div>
                  <div className='data-place phone-value'>
                    {clientTellingPortalCustomer.currentCustomerDetail?.PhoneNumber == 'null' ||
                    !clientTellingPortalCustomer.currentCustomerDetail?.PhoneNumber ? (
                        <span className='data-text'>N/A</span>
                      ) : (
                        <>
                          <span className='data-text'>
                            {clientTellingPortalCustomer?.currentCustomerDetail?.PrefixPhoneNumber || ''}
                            {phoneNumberDisplay
                              ? clientTellingPortalCustomer?.PhoneNumber
                              : clientTellingPortalCustomer.currentCustomerDetail?.PhoneNumber}
                          </span>
                          <span
                            className='cp-icon cp-icon--eye'
                            onMouseDown={(e) => getContactPersonal(e, 'PhoneNumber')}
                            onMouseUp={(e) => getContactPersonal(e, 'PhoneNumber')}
                          />
                        </>
                      )}
                  </div>
                </div>
                <div className='form-group form-group--mb-30 d-flex d-flex--space-between'>
                  <ul className='cp-form__social-icon'>
                    <li className='cp-form__social-icon__item cp-checkbox-icon'>
                      <label
                        className={
                          (communicationCustomerInfo?.AllowWeChat || communicationJsonFacet?.AllowWeChat) && 'cp-checkbox-icon__icon is-checked'
                        }
                      >
                        <Image field={layoutData['Message Button Icon']} />
                      </label>
                    </li>
                    <li className='cp-form__social-icon__item cp-checkbox-icon'>
                      <label
                        className={
                          (communicationCustomerInfo?.AllowWhatsapp || communicationJsonFacet?.AllowWhatsapp) && 'cp-checkbox-icon__icon is-checked'
                        }
                      >
                        <Image field={layoutData['Dial Button Icon']} />
                      </label>
                    </li>
                    {!isHideCommunicationIcon ? (
                      <li className='cp-form__social-icon__item cp-checkbox-icon'>
                        <label
                          className={(communicationCustomerInfo?.AllowSMS || communicationJsonFacet?.AllowSMS) && 'cp-checkbox-icon__icon is-checked'}
                        >
                          <Image field={layoutData['SMS Icon']} />
                        </label>
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='flex-content'>
                  <div className='icon-place'>
                    <Image field={layoutData['Gender Icon']} className='cp-icon' />
                  </div>
                  <div className='data-place'>
                    <span className='data-text'>
                      {clientTellingPortalCustomer.currentCustomerDetail?.PersonalInformation?.Gender == 'null' ||
                      !clientTellingPortalCustomer.currentCustomerDetail?.PersonalInformation?.Gender
                        ? 'N/A'
                        : clientTellingPortalCustomer.currentCustomerDetail?.PersonalInformation?.Gender}
                    </span>
                  </div>
                </div>
                <div className='flex-content'>
                  <div className='icon-place'>
                    <Image field={layoutData['Marital Status Icon']} className='cp-icon' />
                  </div>
                  <div className='data-place'>
                    <span className='data-text'>
                      {clientTellingPortalCustomer.currentCustomerDetail?.CPCustomerInfo?.MaritalStatus == 'null' ||
                      !clientTellingPortalCustomer.currentCustomerDetail?.CPCustomerInfo?.MaritalStatus
                        ? 'N/A'
                        : clientTellingPortalCustomer.currentCustomerDetail?.CPCustomerInfo?.MaritalStatus}
                    </span>
                  </div>
                </div>
                <div className='flex-content'>
                  <div className='icon-place'>
                    <Image field={layoutData['Birthday Icon']} className='cp-icon' />
                  </div>
                  <div className='data-place'>
                    {/*<span className='data-text'>{clientTellingPortalCustomer.currentCustomerDetail?.DOB}</span>*/}
                    <span className='data-text'>{dobText}</span>
                  </div>
                </div>
                <div className='flex-content'>
                  <div className='icon-place'>
                    <Image field={layoutData['Health Issues Icon']} className='cp-icon' />
                  </div>
                  <div className='data-place'>
                    <span className='data-text'>
                      {clientTellingPortalCustomer.currentCustomerDetail?.CPCustomerInfo?.JobNature == 'null' ||
                      !clientTellingPortalCustomer.currentCustomerDetail?.CPCustomerInfo?.JobNature
                        ? 'N/A'
                        : clientTellingPortalCustomer.currentCustomerDetail?.CPCustomerInfo?.JobNature}
                    </span>
                  </div>
                </div>
                <div className='flex-content'>
                  {/* <div className='icon-place'>
                    <Image field={layoutData['Age Icon']} className='cp-icon' />
                  </div>
                  <div className='data-place'>
                    <span className='data-text'>
                      {clientTellingPortalCustomer.currentCustomerDetail?.CPCustomerInfo?.Age == 'null' ||
                      !clientTellingPortalCustomer.currentCustomerDetail?.CPCustomerInfo?.Age
                        ? 'N/A'
                        : clientTellingPortalCustomer.currentCustomerDetail?.CPCustomerInfo?.Age}
                    </span>
                  </div> */}
                </div>
              </div>
              <div className='col-md-4'>
                <div className='flex-content'>
                  <div className='icon-place'>
                    <Image field={layoutData['Household Size Icon']} className='cp-icon' />
                  </div>
                  <div className='data-place'>
                    <span className='data-text'>
                      {clientTellingPortalCustomer.currentCustomerDetail?.CPCustomerInfo?.HouseholdSize == 'null' ||
                      !clientTellingPortalCustomer.currentCustomerDetail?.CPCustomerInfo?.HouseholdSize
                        ? 'N/A'
                        : clientTellingPortalCustomer.currentCustomerDetail?.CPCustomerInfo?.HouseholdSize}
                    </span>
                  </div>
                </div>
                <div className='flex-content'>
                  <div className='icon-place'>
                    <Image field={layoutData['Owned Product Icon']} className='cp-icon' />
                  </div>
                  <div className='data-place'>
                    {!clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.MassageOwnership &&
                    !clientTellingPortalCustomer?.currentCustomerDetail?.CPCustomerInfo?.MassageOwnership ? (
                        <span className='data-text'>N/A</span>
                      ) : (
                        <span className='data-text'>
                          {clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.MassageOwnershipType ||
                          clientTellingPortalCustomer?.currentCustomerDetail?.CPCustomerInfo?.MassageOwnershipType}
                          <br></br>(
                          {clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.MassageOwnership ||
                          clientTellingPortalCustomer?.currentCustomerDetail?.CPCustomerInfo?.MassageOwnership}
                        )
                        </span>
                      )}
                  </div>
                </div>
                {clientTellingPortalCustomer?.customerProducts?.length ? (
                  <div className='flex-content'>
                    <div className='icon-place'>
                      <Image field={layoutData['Owned Product Icon']} className='cp-icon' />
                    </div>
                    <div className='data-place have-product'>
                      <span className='data-text'>Have OSIM</span>
                      <span className='data-text'>{`(${clientTellingPortalCustomer.customerProducts[0].CategoryName})`}</span>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className='flex-content'>
                  <div className='icon-place'>
                    <Image field={layoutData['Job Nature Icon']} className='cp-icon' />
                  </div>
                  <div className='data-place'>
                    <span className='data-text'>
                      {clientTellingPortalCustomer.currentCustomerDetail?.CPCustomerInfo?.HealthIssues == 'null' ||
                      !clientTellingPortalCustomer.currentCustomerDetail?.CPCustomerInfo?.HealthIssues
                        ? 'N/A'
                        : clientTellingPortalCustomer.currentCustomerDetail?.CPCustomerInfo?.HealthIssues}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <CPContactInfoShimmer layoutData={layoutData} />
      )}
    </div>
  ) : (
    <></>
  );
};

CPContactInfo.propTypes = {
  fields: object
};

export default CPContactInfo;
