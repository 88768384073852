import './InformationForm.scss';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

import PhoneNumberSelect from '../../../Share/PhoneNumberSelect';
import FormWrapper from '../FormWrapper';

const InformationForm = ({ layoutData, userInfo, values, setFieldValue, touched, errors }) => {
  const isLogin = useSelector((state) => state.singleSignOnReducer.userInfoReducer.isLogin);
  const { settingGlobal } = useSelector((state) => state.settingGlobalReducer);
  const REGISTRATION_PAGE_PATH = '/my-account/Member-Events/Registration?eventId=';

  const useAreaCode = useMemo(() => {
    return settingGlobal?.UseAreaCode;
  }, [settingGlobal]);

  const areaCodes = useMemo(() => {
    return settingGlobal?.AreaCodes || [];
  }, [settingGlobal]);

  const handleSetPhone = (event) => {
    let telephone = event.target.value;
    if (!telephone || new RegExp(settingGlobal?.PhoneNumberFormat).test(telephone)) {
      setFieldValue('phoneNumber', telephone);
    }
  };

  return (
    <FormWrapper title={layoutData?.['Register Title']?.value}>
      <div className='information-form'>
        <Text tag='h2' field={layoutData?.['Title Detail']} />

        <div className='form-group'>
          <input
            className='form-control form-control-lg'
            name='firstName'
            type='text'
            placeholder={layoutData?.['First Name Label']?.value}
            value={values.firstName}
            onChange={(e) => setFieldValue('firstName', e.target.value)}
            disabled={isLogin && userInfo.firstName}
          />
          {errors.firstName ? <span className='error-validate text-left'>{errors.firstName}</span> : null}
        </div>

        <div className='form-group'>
          <input
            className='form-control form-control-lg'
            name='lastName'
            type='text'
            placeholder={layoutData?.['Last Name Label']?.value}
            value={values.lastName}
            onChange={(e) => setFieldValue('lastName', e.target.value)}
            disabled={isLogin && userInfo.lastName}
          />
          {errors.lastName ? <span className='error-validate text-left'>{errors.lastName}</span> : null}
        </div>

        <div className='form-group'>
          <input
            className='form-control form-control-lg'
            name='email'
            type='text'
            placeholder={layoutData?.['Email Label']?.value}
            value={values.email}
            onChange={(e) => setFieldValue('email', e.target.value)}
            disabled={isLogin && userInfo.email}
          />
          {errors.email ? <span className='error-validate text-left'>{errors.email}</span> : null}
        </div>

        <div className='form-group'>
          <div
            className={
              useAreaCode
                ? `${Global.renderDynamicClass(
                  errors.phoneNumber && touched.phoneNumber,
                  'form-group',
                  'input-error-validate'
                )} form-group--group-select-input`
                : Global.renderDynamicClass(errors.phoneNumber && touched.phoneNumber, 'form-group', 'input-error-validate')
            }
          >
            {useAreaCode ? (
              <PhoneNumberSelect
                setPhoneNumberCodeEvt={(code) => setFieldValue('phonePrefix', code)}
                optionValues={areaCodes}
                currentValue={values.phonePrefix}
                isDisabled={isLogin && userInfo.phoneNumber}
              />
            ) : (
              <></>
            )}
            <div className='form-group--input'>
              <input
                className='form-control form-control-lg'
                name='phoneNumber'
                type='text'
                placeholder={layoutData?.['Phone Label'].value}
                value={values.phoneNumber}
                onChange={handleSetPhone}
                disabled={isLogin && userInfo.phoneNumber}
              />
              {errors.phoneNumber ? <span className='error-validate text-left'>{errors.phoneNumber}</span> : null}
            </div>
          </div>
        </div>
        <div className='information-form__text'>
          <Text field={layoutData?.['Help Message Text']} />
          <Link
            field={{ href: `${layoutData?.['Help Link Url']?.value?.href}?from=${REGISTRATION_PAGE_PATH}${layoutData?.['EventDetails']?.EventId}` }}
          >
            <Text field={{ value: ` ${layoutData?.['Help Link Text']?.value}` }} />
          </Link>
        </div>
      </div>
    </FormWrapper>
  );
};

InformationForm.propTypes = {
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    phonePrefix: PropTypes.string
  }),
  setFieldValue: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  layoutData: PropTypes.object,
  userInfo: PropTypes.object
};

export default InformationForm;
