import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLayoutQuestions, setIsActiveQAProductInterestListing } from '@redux/actions/clientTellingPortalCustomer';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import AccessItem from '../AccessItem';
import ProductInterestPopup from '../ProductInterestPopup';
import QAWarningPopup from '../QAWarningPopup';
import QAProductInterestListingModel from './model/QAProductInterestListingModel';

const QAProductInterestListing = ({ fields, dataModel }) => {
  const [layoutData, setLayoutData] = useState(null);
  const [warningPopup, setWarningPopup] = useState(false);
  const clientTellingPortalQuickAccess = useSelector((state) => state.clientTellingPortalQuickAccess);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const cpCustomerId = SitecoreContextFactoryService.getValueContextItem('cpCustomerId') || '';
  const dispatch = useDispatch();

  useEffect(() => {
    const model = new QAProductInterestListingModel();

    setLayoutData(model.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    dispatch(getLayoutQuestions(Global.removeEmptyObj(fields.Questions)));
  }, []);

  const handleClickCustomeInfo = () => {
    if (cpCustomerId) {
      dispatch(setIsActiveQAProductInterestListing(!clientTellingPortalQuickAccess.isActiveQAProductInterestListing));
    } else {
      setWarningPopup(true);
    }
  };

  const closeAccessPopup = () => {
    dispatch(setIsActiveQAProductInterestListing(false));
  };

  return layoutData ? (
    <>
      <li className={clientTellingPortalQuickAccess.isActiveQAProductInterestListing ? 'active-access' : ''}>
        <AccessItem clickAccessItem={handleClickCustomeInfo} layoutData={<Image field={layoutData['Icon']} />} />
        <ProductInterestPopup
          closeAccessPopup={closeAccessPopup}
          isCenter={false}
          layoutData={layoutData}
          customerInterest={clientTellingPortalCustomer?.contacts?.[0]?.json_facet?.CPCustomerInterest?.Products}
        />
      </li>
      <QAWarningPopup warningPopup={warningPopup} setWarningPopup={() => setWarningPopup(false)} dataModel={dataModel} />
    </>
  ) : (
    <></>
  );
};

QAProductInterestListing.propTypes = {
  fields: object,
  dataModel: object
};

export default QAProductInterestListing;
