import './EventRegistration.scss';

import { useFormik } from 'formik';
import { object } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
// import phoneNumberSelect from '@components/Share/PhoneNumberSelect';
import { registerEventBookingStart } from '@redux/actions/eventBooking';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { MY_SITE_NAME } from '@utils/constant';
import { useIsAtSiteName } from '@utils/customsHook/useIsAtSiteName';
import global from '@utils/global';

import InformationForm from './InformationForm';
import LocationForm from './LocationForm';
import { EventRegistrationModel } from './model/EventRegistrationModel';
import MultiStepForm from './MultiStepForm';

const EventRegistration = ({ fields }) => {
  const dispatch = useDispatch();
  const { isLoading, registerResponse, error } = useSelector((state) => state.eventBookingReducer.eventBookingRegisterReducer);
  const { settingGlobal } = useSelector((state) => state.settingGlobalReducer);
  const [validationSchema, setValidationSchema] = useState(null);
  const [isExperiedDate, setIsExperiedDate] = useState(false);
  // use Regex from setting Global
  // const REGEX_PHONE_NUMBER = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  const [layoutData, setLayoutData] = useState(null);
  const [isUpdatePhoneNumber, setIsUpdatePhoneNumber] = useState(false);
  const REGISTER_SUCCESS_PAGE_PATH = '/my-account/Member%20Events/Event%20Registered?eventId=';
  const isAtMySite = useIsAtSiteName(MY_SITE_NAME);

  useEffect(() => {
    let model = new EventRegistrationModel();

    setLayoutData(model.getData(fields));
  }, []);

  useEffect(() => {
    if (registerResponse && registerResponse?.Success) {
      window.location.href = `${REGISTER_SUCCESS_PAGE_PATH}${layoutData?.['EventDetails']?.EventId}`;
    }
  }, [registerResponse, registerResponse?.Success]);

  const errorMessage = useMemo(() => {
    if (layoutData?.['Error Message Text']?.value) {
      return layoutData?.['Error Message Text']?.value;
    }
  }, [layoutData?.['Error Message Text']?.value]);

  const validation = useMemo(
    () => [
      Yup.object().shape(
        {
          firstName: Yup.string()
            // .max(7, 'Must be 7 characters or less')
            .required(errorMessage),
          lastName: Yup.string()
            // .max(7, 'Must be 7 characters or less')
            .required(errorMessage),
          email: Yup.string().when('phoneNumber', {
            is: (phoneNumber) => !phoneNumber || phoneNumber.length === 0,
            then: Yup.string().email(errorMessage).required(errorMessage),
            otherwise: Yup.string()
          }),
          phoneNumber: Yup.string().when('email', {
            is: (email) => !email || email.length === 0,
            then: Yup.string().required(errorMessage),
            otherwise: Yup.string()
              .min(settingGlobal?.PhoneNumberMinimumMust || settingGlobal?.PhoneNumberMust, <Message messageCode='Msg_001.7' />)
              .matches(new RegExp(settingGlobal?.PhoneNumberFormat), 'Invalid')
          })
        },
        ['email', 'phoneNumber']
      ),
      Yup.object().shape({
        location: Yup.object().shape({
          id: Yup.string().required(errorMessage),
          title: Yup.string().required(errorMessage)
        }),
        preferredDate: Yup.object().shape({
          value: Yup.string().required(errorMessage),
          dateSlotId: Yup.string().required(errorMessage)
        }),
        preferredTimeSlot: Yup.object().shape({
          value: Yup.string().required(errorMessage),
          timeSlotId: Yup.string().required(errorMessage)
        }),
        numberOfGuest: Yup.string().required(errorMessage),
        questionAndAnswer: Yup.array().of(
          Yup.object().shape({
            answerId: Yup.string().required(errorMessage)
          })
        )
      })
    ],
    [settingGlobal, errorMessage]
  );

  const questionAndAnswerInitValue = useMemo(() => {
    const qaValues = [];

    if (layoutData?.EventDetails?.['Question and Answer']?.length) {
      for (let qa of layoutData?.EventDetails?.['Question and Answer']) {
        qaValues.push({
          answerId: '',
          questionId: qa.Id
        });
      }
    }

    return qaValues;
  }, [layoutData?.EventDetails?.['Question and Answer']]);

  const loactionInitValue = useMemo(() => {
    const arrLocation = layoutData?.['EventDetails'].Location;
    var locationObj = {
      id: '',
      title: ''
    };

    if (arrLocation && arrLocation.length === 1) {
      locationObj.id = arrLocation[0].Id?.replace(/{|}/g, '');

      locationObj.title = arrLocation[0].Title;
    }

    return locationObj;
  }, [layoutData?.['EventDetails']?.Location]);

  const userInfo = useMemo(() => {
    const useAreaCode = settingGlobal?.UseAreaCode;
    const areaCodes = settingGlobal?.AreaCodes || [];
    let defaultValue = layoutData?.UserDetails?.PhoneNumber || '';
    const phoneNumber = defaultValue ? (isAtMySite ? `+${defaultValue}` : defaultValue) : '';
    const defaultPrefixCode = useAreaCode && areaCodes.length ? areaCodes[0].value : '';
    const phoneFormat = global.splitPhoneNumberWithoutCode(useAreaCode, phoneNumber, areaCodes);

    return {
      firstName: layoutData?.UserDetails?.FirstName,
      lastName: layoutData?.UserDetails?.LastName,
      email: layoutData?.UserDetails?.EmailAddress,
      phoneNumber: phoneFormat.value,
      phonePrefix: phoneFormat.code || defaultPrefixCode
    };
  }, [layoutData?.UserDetails, settingGlobal, isAtMySite]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...userInfo,
      location: loactionInitValue,
      preferredDate: {
        value: '',
        dateSlotId: ''
      },
      preferredTimeSlot: {
        value: '',
        timeSlotId: ''
      },
      numberOfGuest: '',
      questionAndAnswer: questionAndAnswerInitValue
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      // if (!isLastStep) return next();
      const answersId = values.questionAndAnswer.map((answer) => `${answer.questionId}:${answer.answerId}`).join(',');
      const phoneNumberRawValue=`${values.phonePrefix || ''}${values.phoneNumber}`;

      const registerEventData = {
        CustomerId: SitecoreContextFactoryService.getValueContextItem('CustomerId'),
        UserName: SitecoreContextFactoryService.getValueContextItem('user')?.name,
        Email: values.email,
        EventId: layoutData?.['EventDetails']?.EventId,
        EventName: layoutData?.['EventDetails']?.Title,
        LocationId: values.location.id,
        LocationTitle: values.location.title,
        DateSlotId: values.preferredDate.dateSlotId,
        EventDate: global.customDate(values.preferredDate.value),
        TimeSlotId: values.preferredTimeSlot.timeSlotId,
        EventTime: values.preferredTimeSlot.value,
        NumberOfGuest: values.numberOfGuest,
        SubmitedDate: global.customDate(new Date()),
        AnswersId: answersId,
        FirstName: values.firstName,
        LastName: values.lastName,
        PhoneNumber: isAtMySite ? `+${phoneNumberRawValue}` : phoneNumberRawValue,
        UpdatePhoneNumber: isUpdatePhoneNumber
      };

      dispatch(registerEventBookingStart(registerEventData));
    }
  });

  useEffect(() => {
    if (userInfo.phoneNumber) {
      setIsUpdatePhoneNumber(false);
    } else {
      formik.values.phoneNumber ? setIsUpdatePhoneNumber(true) : setIsUpdatePhoneNumber(false);
    }
  }, [userInfo, formik]);

  const { step, currentStepIndex, isFirstStep, isLastStep, back, next } = MultiStepForm([
    <InformationForm key={0} {...formik} layoutData={layoutData} userInfo={userInfo} />,
    <LocationForm key={1} {...formik} layoutData={layoutData} />
  ]);

  useEffect(() => {
    if (layoutData?.['EventDetails']) {
      if (new Date(layoutData?.['EventDetails']?.['Registration End Time']?.slice(0, -6)).getTime() < new Date().getTime()) {
        setIsExperiedDate(true);
      } else {
        if (new Date(layoutData?.['EventDetails']?.['Registration Start Time']?.slice(0, -6)).getTime() > new Date().getTime()) {
          setIsExperiedDate(true);
        } else {
          setIsExperiedDate(false);
        }
      }
    }
  }, [layoutData?.['EventDetails']]);

  useEffect(() => {
    setValidationSchema(validation[currentStepIndex]);
  }, [currentStepIndex, layoutData]);

  const nextStep = useCallback(
    async (evt) => {
      evt.preventDefault();
      const validateFormResponse = await formik.validateForm();
      if (!Object.keys(validateFormResponse).length && !isLastStep) {
        return next();
      }
    },
    [formik, isLastStep, next]
  );

  return layoutData ? (
    <>
      <div className='event-registration'>
        <div className='container'>
          <form className='event-registration__form' onSubmit={formik.handleSubmit}>
            {step}
            {isExperiedDate ? (
              <Text field={layoutData?.['Expired Message Text']} tag='p' className='error-message' />
            ) : (
              <>
                {isLastStep ? (
                  <button className='btn btn-primary' type='submit'>
                    <Text field={layoutData['Register Button Label']} />
                  </button>
                ) : (
                  <button className='btn btn-primary' type='button' onClick={(evt) => nextStep(evt)}>
                    <Text field={layoutData['Button Label']} />
                  </button>
                )}
                {!isFirstStep && (
                  <a className='btn-back' type='button' onClick={back}>
                    BACK TO CONTACT INFO
                  </a>
                )}
              </>
            )}
            {error ? <span className='error-validate'>{error}</span> : <></>}
          </form>
        </div>
      </div>
      {isLoading ? <LoadingMask /> : <></>}
    </>
  ) : (
    <></>
  );
};

EventRegistration.propTypes = {
  fields: object
};

export default EventRegistration;
