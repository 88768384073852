import './ProductVariantInfo.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProductCountdownTimer from '@components/ProductCountdownTimer';
import LinkModelWithTitle from '@models/LinkModelWithTitle';
import { getProductCountdownTimer } from '@redux/actions/productCountdownTimer';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import global from '@utils/global';

import Global from '../../../../../utils/global';
import CountdownTimerItem from '../../../../Share/CountdownTimer/CountdownTimerItem/index';
import TermsConditionsModal from './termsConditionsModal/index';
import { useConvertBalanceDate } from './utils';

const ProductVariantInfo = (props) => {
  const dispatch = useDispatch();
  const { isLoading, productCountdownTimer } = useSelector((state) => state.productCountdownTimerReducer);
  const { dataContent, dataSources, productDetailOverview } = props;
  const [extraLink, setExtraLink] = useState(new LinkModelWithTitle().getData({}));
  const refunableDepositProduct = useSelector((state) => state.productReducer.productDetailOverviewReducer.productDetailOverview);
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);
  const [installmentCartLevel, setInstallmentCartLevel] = useState(null);
  const [installmentProductLevel, setInstallmentProductLevel] = useState(null);
  const [installmentCartLevelAndProductLevel, setInstallmentCartLevelAndProductLevel] = useState(null);
  const isDeposit = useSelector((state)=> state?.productReducer?.productDetailHeaderReducer?.IsDeposit);
  const deposit = useSelector((state)=> state?.productReducer?.productDetailHeaderReducer?.Deposit);
  const priceDeposit = useSelector((state)=> state?.productReducer?.productDetailHeaderReducer?.Price);
  const [isShow, setShow] = useState(false);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const convertBalanceDateService = useConvertBalanceDate();

  const onShowHandler = () => {
    setShow(true);
  };

  const onCloseHandler = () => {
    setShow(false);
  };

  useEffect(() => {
    dispatch(getProductCountdownTimer());
  }, []);

  useEffect(() => {
    const modelFields = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Extra Link'];

    setExtraLink(new LinkModelWithTitle().getData(modelFields?.fields || {}));
  }, []);

  useEffect(() => {
    if (currentCart && dataContent) {
      setInstallmentCartLevel(
        currentCart?.PaymentConfiguration?.Gateways.length &&
          currentCart?.PaymentConfiguration?.Gateways.filter(
            (gateway) =>
              gateway?.SupportInstallment && gateway?.InstallmentCartLevel && !gateway?.InstallmentProductLevel && gateway?.InstallmentPeriods.length
          )
      );

      setInstallmentProductLevel(
        currentCart?.PaymentConfiguration?.Gateways.length &&
          currentCart?.PaymentConfiguration?.Gateways.filter(
            (gateway) =>
              gateway?.SupportInstallment &&
              gateway?.InstallmentProductLevel &&
              !gateway?.InstallmentCartLevel &&
              productDetailOverview?.InstallmentPeriods?.length
          )
      );

      setInstallmentCartLevelAndProductLevel(
        currentCart?.PaymentConfiguration?.Gateways.length &&
          currentCart?.PaymentConfiguration?.Gateways.filter(
            (gateway) =>
              gateway?.SupportInstallment &&
              gateway?.InstallmentProductLevel &&
              gateway?.InstallmentCartLevel &&
              productDetailOverview?.InstallmentPeriods?.length
          )
      );
    }
  }, [currentCart, dataContent]);

  const startBalanceDate = useMemo(() => convertBalanceDateService(deposit?.BalanceStartDate), [deposit]);
  const endBalanceDate = useMemo(() => convertBalanceDateService(deposit?.BalanceEndDate), [deposit]);

  return !isLoading ? (
    <div className='product-variant-info'>
      <div className='product-variant-info__content'>
        <h1 className='product-variant-info__content__name'>{dataContent.ProductName}</h1>
        {parseInt(productCountdownTimer?.ProductCountdownTimer?.IsEnable) === 1 ? (
          <div className='product-variant-info__content__countdown'>
            <ProductCountdownTimer
              className={'countdown-timer__custom'}
              fontSize={{
                dateMobileUnit: productCountdownTimer?.ProductCountdownTimer?.DateUnitMobileFontSize || '',
                dateUnit: productCountdownTimer?.ProductCountdownTimer?.DateUnitFontSize || '',
                timerMobileText: productCountdownTimer?.ProductCountdownTimer?.CountdownTimerTextMobileFontSize || '',
                timerText: productCountdownTimer?.ProductCountdownTimer?.CountdownTimerTextFontSize || '',
                titleMobileText: productCountdownTimer?.ProductCountdownTimer?.TitleTextMobileFontSize || '',
                titleText: productCountdownTimer?.ProductCountdownTimer?.TitleTextFontSize || ''
              }}
              colorCode={{
                dateUnitText: productCountdownTimer?.ProductCountdownTimer?.DateUnitColorCode || '',
                timerText: productCountdownTimer?.ProductCountdownTimer?.CountdownTimerTextColorCode || '',
                titleText: productCountdownTimer?.ProductCountdownTimer?.TitleTextColorCode || ''
              }}
              text={{
                title: productCountdownTimer?.ProductCountdownTimer?.TitleText || '',
                days: productCountdownTimer?.ProductCountdownTimer?.DaysText || '',
                hours: productCountdownTimer?.ProductCountdownTimer?.HoursText || '',
                minutes: productCountdownTimer?.ProductCountdownTimer?.MinutesText || '',
                seconds: productCountdownTimer?.ProductCountdownTimer?.SecondsText || ''
              }}
              startTime={parseInt(productCountdownTimer?.ProductCountdownTimer?.StartTime?.replaceAll(/[/Date()]/gm, '')) || ''}
              endTime={parseInt(productCountdownTimer?.ProductCountdownTimer?.EndTime?.replaceAll(/[/Date()]/gm, '')) || ''}
              countdownBgImage={productCountdownTimer?.ProductCountdownTimer?.BackgroundImageUrl || ''}
            />
          </div>
        ) : (
          <></>
        )}
        <div className='product-variant-info__content__price'>
          {isDeposit ? (
            <div className='product-variant-info__content__depposit'>
              <div className='product-variant-info__content__depposit__price'>
                <div className='item'>
                  <Text field={dataSources?.['Deposit Usual Label']} tag='span' className='item__description item__description--usual' />
                  <del className='item__value'>{priceDeposit?.ListPriceWithCurrency}</del>
                </div>
                <div className='item'>
                  <Text field={dataSources?.['Deposit Pre Order Label']} tag='span' className='item__description item__description--pre-order' />
                  <span className='item__value item__value--pre-order'>{deposit?.PreorderPriceWithCurrency}</span>
                </div>
                <div className='item'>
                  <Text field={dataSources?.['Deposit Label']} tag='span' className='item__description item__description--deposit' />
                  <span className='item__value item__value--deposit item__value--fontWeightBold'>{deposit?.DepositPriceWithCurrency}</span>
                  {deposit?.DiscountPrice > 0 && (
                    <Text
                      field={{ value: dataSources?.['Deposit Instant Off Label']?.value.replace('{Discount}', deposit?.DiscountPriceWithCurrency) }}
                      tag='span'
                      className='item__value item__value--deposit'
                    />
                  )}
                </div>
              </div>
              <div className='product-variant-info__content__depposit__info'>
                <div className='item'>
                  <div className='item__number item__number--before'>1</div>
                  <div className='item__box'>
                    <Text field={{ value: `${dataSources?.['Pay Deposit Label']?.value}: ` }} tag='span' className='item__description' />
                    <Text tag='span' field={dataSources?.['End At Label']} className='item__description item__description--fontWeightRegular' />
                    <CountdownTimerItem
                      startTime={parseInt(`/Date(${new Date().getTime()})/`.match(/\d+/)[0])}
                      endTime={parseInt((deposit?.DepositEndDate).match(/\d+/)[0])}
                      remainingTime={parseInt(`/Date(${new Date().getTime()})/`.match(/\d+/)[0])}
                      dataFromLayout={''}
                      startMsg={''}
                      endMsg={''}
                      onAction={''}
                      isTranslateDateTimeLetters={true}
                    />
                  </div>
                </div>
                <div className='item'>
                  <div className='item__number item__number--before'>2</div>
                  <div className='item__box'>
                    <div>
                      <Text field={{ value: `${dataSources?.['Balance Payment Label']?.value}: ` }} tag='span' className='item__description' />
                      <span className='item__description item__description--fontWeightRegular'>{deposit?.BalancePriceWithCurrency}</span>
                    </div>
                    <div>
                      <Text field={{ value: `${dataSources?.['Payment Period Label']?.value}: ` }} tag='span' className='item__description' />
                      <span className='item__description item__description--fontWeightRegular'>{`${startBalanceDate} ${dataSources?.['To Text']?.value} ${endBalanceDate}`}</span>
                    </div>
                  </div>
                </div>
                <div className='item'>
                  <div className='item__number'>3</div>
                  <div className='item__box'>
                    <Text field={{ value: `${dataSources?.['Ship Out Label']?.value}: ` }} tag='span' className='item__description' />
                    <Text
                      field={{ value: dataSources?.['Ship Out Details']?.value.replace('{ShipOut}', deposit?.ShipOut) }}
                      tag='span'
                      className='item__description item__description--fontWeightRegular'
                    />
                  </div>
                </div>
              </div>
              <div className='product-variant-info__content__depposit__link'>
                <span onClick={onShowHandler}>
                  <Text field={dataSources?.['Term And Condition Label']} onClick={onShowHandler} />
                </span>
              </div>
              <TermsConditionsModal isShow={isShow} onCloseHandler={onCloseHandler} ondataSources={dataSources} />
            </div>
          ) : (
            <>
              <span className='product-variant-info__content__selling-price'>
                {refunableDepositProduct?.RefunableDepositProduct.length ? <Text tag='span' field={dataSources['Rental Price Label']} /> : ''}{' '}
                {dataContent?.Price?.SellingPriceWithCurrency}{' '}
                {SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Price Extra Information']?.value}
              </span>
              {dataContent?.Price?.ListPriceWithCurrency !== dataContent?.Price?.SellingPriceWithCurrency ? (
                <span className='product-variant-info__content__list-price'>
                  <Text field={dataSources['Usual Price Text']} tag='span' /> <del>{dataContent?.Price?.ListPriceWithCurrency}</del>
                </span>
              ) : (
                ''
              )}
            </>
          )}

          {dataSources?.['Payment Gateways']?.Gateways?.map((gateway) => {
            return (
              <>
                {(!gateway?.InstallmentMaxCartAmount && dataContent?.Price?.SellingPrice >= gateway?.InstallmentMinCartAmount) ||
                (gateway?.InstallmentMaxCartAmount &&
                  dataContent?.Price?.SellingPrice >= gateway?.InstallmentMinCartAmount &&
                  dataContent?.Price?.SellingPrice < gateway?.InstallmentMaxCartAmount &&
                  !isDeposit) ? (
                    <span className='product-variant-info__content__installment' key={gateway?.Id}>
                      {gateway?.InstallmentProductLevelText.replace(
                        '{number_of_payments}',
                      gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period
                      )
                        .replace(
                          '${installment_amount}',
                          Math.round(
                            (dataContent?.Price?.SellingPrice /
                            gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period) *
                            1000
                          ) / 1000
                        )
                        .replace('{logo}', '')}
                      {gateway?.InstallmentProductLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                        <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                      ) : (
                        <></>
                      )}
                    </span>
                  ) : (
                    <></>
                  )}
                {dataContent?.Price?.SellingPrice < gateway?.InstallmentMinCartAmount ? (
                  <span className='product-variant-info__content__installment' key={gateway?.Id}>
                    {gateway?.InstallmentCartLevelText.replace(
                      '${amount}',
                      gateway?.InstallmentMinCartAmount - dataContent?.Price?.SellingPrice
                    ).replace('{logo}', '')}
                    {gateway?.InstallmentCartLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                      <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                    ) : (
                      <></>
                    )}
                  </span>
                ) : (
                  <></>
                )}
              </>
            );
          })}
          {/*{installmentProductLevel?.map((gateway) => {*/}
          {/*  return (*/}
          {/*    <span className='product-variant-info__content__installment' key={gateway?.Id}>*/}
          {/*      {gateway?.InstallmentProductLevelText.replace(*/}
          {/*        '{number_of_payments}',*/}
          {/*        productDetailOverview?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]*/}
          {/*      )*/}
          {/*        .replace(*/}
          {/*          '${installment_amount}',*/}
          {/*          Math.round(*/}
          {/*            (dataContent?.Price?.SellingPrice /*/}
          {/*              productDetailOverview?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]) **/}
          {/*              1000*/}
          {/*          ) / 1000*/}
          {/*        )*/}
          {/*        .replace('{logo}', '')}*/}
          {/*      {gateway?.InstallmentProductLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (*/}
          {/*        <img className='installment-img' src={gateway?.PdpLogoItemUrl} />*/}
          {/*      ) : (*/}
          {/*        <></>*/}
          {/*      )}*/}
          {/*    </span>*/}
          {/*  );*/}
          {/*})}*/}
          {/*{installmentCartLevelAndProductLevel?.map((gateway) => {*/}
          {/*  return (*/}
          {/*    <>*/}
          {/*      {(!gateway?.InstallmentMaxCartAmount && dataContent?.Price?.SellingPrice >= gateway?.InstallmentMinCartAmount) ||*/}
          {/*      (gateway?.InstallmentMaxCartAmount &&*/}
          {/*        dataContent?.Price?.SellingPrice >= gateway?.InstallmentMinCartAmount &&*/}
          {/*        dataContent?.Price?.SellingPrice < gateway?.InstallmentMaxCartAmount) ? (*/}
          {/*          <span className='product-variant-info__content__installment' key={gateway?.Id}>*/}
          {/*            {gateway?.InstallmentProductLevelText.replace(*/}
          {/*              '{number_of_payments}',*/}
          {/*            productDetailOverview?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]*/}
          {/*            )*/}
          {/*              .replace(*/}
          {/*                '${installment_amount}',*/}
          {/*                Math.round(*/}
          {/*                  (dataContent?.Price?.SellingPrice /*/}
          {/*                  productDetailOverview?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]) **/}
          {/*                  1000*/}
          {/*                ) / 1000*/}
          {/*              )*/}
          {/*              .replace('{logo}', '')}*/}
          {/*            {gateway?.InstallmentProductLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (*/}
          {/*              <img className='installment-img' src={gateway?.PdpLogoItemUrl} />*/}
          {/*            ) : (*/}
          {/*              <></>*/}
          {/*            )}*/}
          {/*          </span>*/}
          {/*        ) : (*/}
          {/*          <></>*/}
          {/*        )}*/}
          {/*      {dataContent?.Price?.SellingPrice < gateway?.InstallmentMinCartAmount ? (*/}
          {/*        <span className='product-variant-info__content__installment' key={gateway?.Id}>*/}
          {/*          {gateway?.InstallmentCartLevelText.replace(*/}
          {/*            '${amount}',*/}
          {/*            gateway?.InstallmentMinCartAmount - dataContent?.Price?.SellingPrice*/}
          {/*          ).replace('{logo}', '')}*/}
          {/*          {gateway?.InstallmentCartLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (*/}
          {/*            <img className='installment-img' src={gateway?.PdpLogoItemUrl} />*/}
          {/*          ) : (*/}
          {/*            <></>*/}
          {/*          )}*/}
          {/*        </span>*/}
          {/*      ) : (*/}
          {/*        <></>*/}
          {/*      )}*/}
          {/*    </>*/}
          {/*  );*/}
          {/*})}*/}
        </div>
        {productDetailOverview.BundleDealsButtonUrl ? (
          <a href={productDetailOverview.BundleDealsButtonUrl} className='btn-link'>
            <span>{messageObj?.['LBL_BundleDealsButtonUrl']}</span>
          </a>
        ) : (
          <></>
        )}
        {refunableDepositProduct?.RefunableDepositProduct.length ? (
          <span className='product-variant-info__content__deposit'>
            <Text tag='span' field={dataSources['Deposit Label']} />{' '}
            {refunableDepositProduct?.RefunableDepositProduct[0].Price.SellingPriceWithCurrency} <Text tag='span' field={dataSources['Refundable']} />
          </span>
        ) : (
          <></>
        )}
        <RichText
          className='product-variant-info__content__description'
          field={SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Product Overview']}
        />
        {extraLink.Title.value ? (
          <Link className='product-variant-info__content__description' field={extraLink.Link}>
            <Text field={extraLink.Title} />
          </Link>
        ) : (
          // <Link className='product-variant-info__content__description' field={extraLink.Link} />
          <></>
        )}
        <RichText
          className='product-variant-info__content__description'
          field={SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Additional Information']}
        />
        <div className='product-variant-info__content__see-detail'>{props.children}</div>
      </div>
    </div>
  ) : (
    <></>
  );
};

ProductVariantInfo.propTypes = {
  dataContent: Proptypes.any,
  children: Proptypes.any,
  dataSources: Proptypes.object,
  productDetailOverview: Proptypes.object
};

export default ProductVariantInfo;
