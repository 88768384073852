import { bool, func, object } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { updateCPCustomerContact } from '@redux/actions/clientTellingPortalCustomer';
import { cPStartDemo } from '@redux/actions/cPStartDemo';
import { cpCheckContactDuplicateSelector } from '@redux/reducers/cpCheckContactDuplicate/selector';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const CustomerInformationWarningPopup = ({ layoutData, isShowWarningPopup, setShowWarningPopup, generateContactFormData }) => {
  const dispatch = useDispatch();
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const { contactFound, checkContactErrorData } = useSelector(cpCheckContactDuplicateSelector);
  const isDuplicatedEmail = useMemo(() => checkContactErrorData && checkContactErrorData.isDuplicatedEmail, [checkContactErrorData]);
  const isDuplicatedPhone = useMemo(() => checkContactErrorData && checkContactErrorData.isDuplicatedPhone, [checkContactErrorData]);

  const secondaryBtnLabel = useMemo(
    () => (isDuplicatedEmail ? layoutData['Create New Profile Button'] : layoutData['Create New Profile Dupplicated Phone Button']),
    [isDuplicatedPhone, isDuplicatedEmail, contactFound, layoutData]
  );

  const contactExistData = useMemo(() => {
    const data = clientTellingPortalCustomer?.errorData?.[0] ?? contactFound?.[0];

    return {
      contactId: data?.contactId || '',
      currentSessionContactId: data?.contacts?.[0]?.contactId || '',
      fullName: `${data?.json_facet?.Personal?.FirstName || ''} ${data?.json_facet?.Personal?.LastName || ''}`,
      email: data?.json_facet?.Email?.PreferredEmail?.SmtpAddress || '',
      phone: data?.json_facet?.PhoneNumbers?.PreferredPhoneNumber?.Number || ''
    };
  }, [clientTellingPortalCustomer, contactFound]);

  const handleStartDemo = () => {
    dispatch(
      cPStartDemo({
        ContactId: contactExistData.contactId,
        currentSessionContactId: contactExistData.currentSessionContactId,
        sessionId: SitecoreContextFactoryService.getValueContextItem('cpSessionId')
      })
    );
  };

  const handleCreateNewProfile = useCallback(() => {
    if (contactFound.length) {
      const formData = generateContactFormData();

      if (isDuplicatedPhone) dispatch(updateCPCustomerContact(formData));
    }

    setShowWarningPopup(false);
  }, [generateContactFormData, contactFound, isDuplicatedPhone]);

  return (
    <ModalComponent
      cssClass='warning-popup'
      isShow={isShowWarningPopup}
      onCloseHandler={() => {
        setShowWarningPopup(false);
      }}
    >
      <div className='warning-popup__content'>
        <Text className='warning-popup__content__title' field={layoutData['Warning Title']} tag='h4' />
        <Text className='warning-popup__content__desc' tag='p' field={layoutData['Warning Description']} />
        <div className='warning-popup__content__info'>
          <span className='warning-popup__content__info__line'>{contactExistData.fullName}</span>
          <span className='warning-popup__content__info__line'>{contactExistData.email}</span>
          <span className='warning-popup__content__info__line'>{contactExistData.phone}</span>
        </div>
        <div className='warning-popup__content__btn'>
          <span className='warning-popup__content__btn__item btn btn-primary' onClick={handleStartDemo}>
            <Text field={layoutData['Continue With Existing Button']} />
          </span>
          <span className='warning-popup__content__btn__item os-btn-link' onClick={handleCreateNewProfile}>
            <Text field={secondaryBtnLabel} />
          </span>
        </div>
      </div>
    </ModalComponent>
  );
};

CustomerInformationWarningPopup.propTypes = {
  layoutData: object,
  setShowWarningPopup: func,
  isShowWarningPopup: bool,
  generateContactFormData: func
};

export default CustomerInformationWarningPopup;
