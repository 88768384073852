import * as moment from 'moment';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import * as constant from './constant';
import { GA4_LOCAL_STORAGE_KEY, MY_SITE_NAME } from './constant';
import { isAtSiteName } from './customsHook/useIsAtSiteName';

export const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const TH_MONTH_NAMES = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];

const Global = {
  renderDynamicClass: (condition, className, errorClass) => {
    return condition ? `${className} ${errorClass}` : className;
  },

  formatCurrency: (amount, decimalCount) => {
    const DECIMAL_CHARACTER = '.';
    const THOUSANDS_CHARACTER = ',';
    if (amount?.toString().length) {
      amount = Math.abs(Number(amount) || '');

      decimalCount = isNaN(Math.abs(decimalCount)) ? constant.DECIMAL_COUNT_DEFAULT : decimalCount;

      const negaticurrentcurrentcurrentValuealuealueeSign = amount < 0 ? '-' : '';

      let integer = parseInt(amount.toFixed(decimalCount)).toString();
      let substrLength = integer.length > 3 ? integer.length % 3 : 0;

      return (
        negaticurrentcurrentcurrentValuealuealueeSign +
        (substrLength ? integer.substr(0, substrLength) + THOUSANDS_CHARACTER : '') +
        integer.substr(substrLength).replace(/(\d{3})(?=\d)/g, '$1' + THOUSANDS_CHARACTER) +
        (decimalCount
          ? DECIMAL_CHARACTER +
            Math.abs(amount - integer)
              .toFixed(decimalCount)
              .slice(2)
          : '')
      );
    }

    return amount;
  },
  formatDynamicCurrency: (amount, currencySymbol, currencySymbolPosition, numberFormat, fractionDigits) => {
    const DECIMAL_CHARACTER = '.';
    const THOUSANDS_CHARACTER = ',';
    if (amount?.toString().length) {
      if (numberFormat === '') {
        numberFormat = constant.CURRENCY_FORMAT_DEFAULT;
      }
      if (fractionDigits) {
        fractionDigits = 0;
      }

      let formattedAmount = new Intl.NumberFormat(numberFormat, { minimumFractionDigits: fractionDigits }).format(amount);
      switch (currencySymbolPosition) {
      case 0:
        return `${currencySymbol}${formattedAmount}`;

      case 1:
        return `${formattedAmount}${currencySymbol}`;

      case 2:
        return `${currencySymbol} ${formattedAmount}`;

      case 3:
        return `${formattedAmount} ${currencySymbol}`;
      }
    }

    return amount;
  },
  getQueryParams: (url, name) => {
    const objectQuery = (url.toLowerCase().match(/([^?=&]+)(=([^&]*))/g) || []).reduce((objectQuery, currentValue) => {
      objectQuery[currentValue.slice(0, currentValue.indexOf('='))] = currentValue.slice(currentValue.indexOf('=') + 1);

      return objectQuery;
    }, {});

    return name ? objectQuery[name.toLowerCase()] : objectQuery;
  },
  getQueryParamsNoLower: (url, name) => {
    const objectQuery = (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce((objectQuery, currentValue) => {
      objectQuery[currentValue.slice(0, currentValue.indexOf('='))] = currentValue.slice(currentValue.indexOf('=') + 1);

      return objectQuery;
    }, {});

    return name ? objectQuery[name] : objectQuery;
  },
  addZero: (number) => {
    if (number < 10) {
      number = `0${number}`;
    }

    return number;
  },
  formatDateDDMYYY: (date) => {
    if (date) {
      const arrDate = date.slice(6, date.length - 2);
      const currentDate = +arrDate ? new Date(+arrDate) : new Date();

      return `${Global.addZero(currentDate.getDate())}/${
        currentDate.getMonth() + 1
      }/${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}`;
    } else {
      return '';
    }
  },
  formatDateMMDDYYYY: (date, hasTime = true) => {
    if (date) {
      const dateString = `${Global.addZero(date.getMonth() + 1)}/${Global.addZero(date.getDate())}/${date.getFullYear()}`;
      const timeString = `, ${Global.addZero(date.getHours())}:${Global.addZero(date.getMinutes())}`;

      return hasTime ? dateString + timeString : dateString;
    } else {
      return '';
    }
  },
  formatDateDDMMMYYYY: (date) => {
    if (date) {
      const timestamp = parseInt(date.match(/\d+/)[0]);

      const dateObj = new Date(timestamp);

      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      const day = dateObj.getDate();
      const month = months[dateObj.getMonth()];
      const year = dateObj.getFullYear();

      return `${day} ${month} ${year}`;
    }
  },
  checkNumber: (number) => (number < 10 ? '0' + number : number),
  formatDateYYYYMMDD: (date) => {
    if (date) {
      const datePicker = new Date(date);
      const year = datePicker.getFullYear();
      const month = Global.checkNumber(datePicker.getMonth() + 1);
      const day = Global.checkNumber(datePicker.getDate());

      return year + '-' + month + '-' + day;
    }

    return;
  },
  formatDateCoupon: (date) => {
    if (date) {
      const arrDate = date.slice(6, date.length - 2);
      const currentDate = +arrDate ? new Date(+arrDate) : new Date();

      return `${Global.addZero(currentDate.getDate())}/${
        currentDate.getMonth() + 1
      }/${currentDate.getFullYear()} ${currentDate.getHours()}:${Global.addZero(currentDate.getMinutes())}`;
    } else {
      return '';
    }
  },
  formartDateIOS8601UTC: (data) => {
    if (data) {
      const originalDate = new Date(data);
      const expires = moment(originalDate).endOf('day').utc();

      return expires.toISOString();
    }
  },
  formartStartDateIOS8601UTC: (data) => {
    if (data) {
      const originalDate = new Date(data);
      const expires = moment(originalDate).startOf('day').utc();

      return expires.toISOString();
    }
  },
  stopScrollingPage: () => {
    document.body.classList.add('stop-scrolling');
  },

  scrollingPage: () => {
    document.body.classList.remove('stop-scrolling');
  },

  formatEpochDate: (epoch, isConvert) => {
    // let monthArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    var date = isConvert ? epoch : epoch * 1000;

    return Global.customDate(date);
    // let day = date.getDate();
    // let month = monthArr[date.getMonth()];
    // let year = date.getFullYear();

    // var formattedDay = day < 10 ? '0' + day : day;
    // var formattedMonth = month < 10 ? '0' + month : month;

    // //===============Format 1=================//

    // var formattedDate = formattedDay + ' ' + formattedMonth + ' ' + year;

    // return formattedDate;
  },

  decodeURLQueryParams: (params) => {
    return decodeURIComponent(params?.replace(/\+/g, '%20') || '');
  },

  createArrayTag: (data) => {
    let arrTags = [];

    data.map((itemTag) => {
      arrTags.push(itemTag?.fields?.Title?.value);
    });

    return arrTags;
  },

  onFormatPromotionValidMessageHandler: (mess, start, end, dateFormat) => {
    let tempValidString = mess;

    tempValidString =
      start !== '0001-01-01T00:00:00Z' && start !== '0001-01-01T00:00:00.0000000Z'
        ? tempValidString.replace(/{Date1}|{start-date}|{start-time}/g, moment(start).format(dateFormat))
        : tempValidString;

    tempValidString =
      end !== '0001-01-01T00:00:00Z' && end !== '0001-01-01T00:00:00.0000000Z'
        ? tempValidString.replace(/{Date2}|{end-date}|{end-time}/g, moment(end).format(dateFormat))
        : tempValidString;

    return tempValidString;
  },

  genURLParamAndStayPage: (arrayValue) => {
    // Usage
    // global.genURLParamAndStayPage([
    //   {key: 'searchTerm', value: params.searchTerm},
    //   {key: 'tags', value: tagsValue}]);

    const url = new URL(window.location);

    arrayValue.map((item) => {
      item.value !== '' ? url.searchParams.set(item.key, item.value) : url.searchParams.delete(item.key);
    });

    window.history.replaceState({}, '', url);
  },

  genURLParamAndChangePage: (sourceLink, object) => {
    // Usage:
    // window.location.href = Global.genURLParamAndChangePage(`/link`, { searchTerm: value, tags: params.tags });
    let newURL;
    let fixURL;
    let fixParams;
    let params = new URLSearchParams(object);
    let keysForDel = [];

    params.forEach((value, key) => {
      if (value == '') keysForDel.push(key);
    });

    keysForDel.forEach((key) => {
      params.delete(key);
    });

    params = params.toString() !== '' ? `?${params}` : '';

    if (sourceLink.includes('?')) {
      const splitted = sourceLink.split('?');

      fixURL = splitted[0];

      fixParams = splitted
        .slice(1)
        .filter((item) => item.trim().length !== 0)
        .join('&');

      fixParams =
        fixParams.toString() != ''
          ? `&${splitted
            .slice(1)
            .filter((item) => item.trim().length !== 0)
            .join('&')}`
          : '';

      newURL = fixURL + params + fixParams;
    } else {
      fixURL = sourceLink;

      newURL = fixURL + params;
    }

    return newURL;
  },

  removeUselessWords: (string, uselessWordsArray) => {
    var expStr = uselessWordsArray.join('\\b|\\b');

    return string.replace(new RegExp(expStr, 'gi'), '').trim().replace(/ +/g, ' ');
  },

  escapeRegex: (string) => {
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  },

  scrollToWithCallback: (offset, callback) => {
    const fixedOffset = offset.toFixed(),
      onScroll = function () {
        if (window.pageYOffset.toFixed() === fixedOffset) {
          window.removeEventListener('scroll', onScroll);

          callback();
        }
      };

    window.addEventListener('scroll', onScroll);

    onScroll();

    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    });
  },

  scrollWithHash: (paddingTop) => {
    //INFO: auto scroll to component id === location.hash
    const hash = window.location.hash;
    if (hash) {
      const targetComponent = document.querySelector(hash);
      if (targetComponent) {
        let offsetTop = targetComponent.offsetTop - (paddingTop || 0);

        window.scroll({ top: offsetTop, left: 0, behavior: 'smooth' });
      }
    }
  },

  getPositionAtCenter: (element) => {
    const { top, left, width, height } = element.getBoundingClientRect();

    return {
      x: left + width / 2,
      y: top + height / 2
    };
  },
  getDistanceBetweenElements: (a, b) => {
    const aPosition = Global.getPositionAtCenter(a);
    const bPosition = Global.getPositionAtCenter(b);

    return Math.sqrt(Math.pow(aPosition.x - bPosition.x, 2) + Math.pow(aPosition.y - bPosition.y, 2));
  },
  removeItemEmtyObj: (obj) => {
    Object.entries(obj).forEach((key) => (!key[1] ? delete obj[key[0]] : 0));

    return obj;
  },
  shallowEqual: (objCurrent, objCheck) => {
    const keycheck = Object.keys(objCheck);
    let couter = 0;

    keycheck.map((keyFilter) => {
      if (objCheck[keyFilter] === objCurrent[keyFilter]) {
        couter = couter + 1;
      } else {
        return false;
      }
    });

    return keycheck.length === couter;
  },
  getUniqueListBy: (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  },
  customDate: (date, isUseComma = true) => {
    const country = SitecoreContextFactoryService.getValueContextItem('site').name;
    const language = SitecoreContextFactoryService.getValueContextItem('language');

    if (language === 'en') {
      const cusDate = new Date(date);

      const getDate = cusDate.getDate(),
        getMonth = MONTH_NAMES[cusDate.getMonth()],
        getFullYear = cusDate.getFullYear();
      const comma = isUseComma ? ',' : '';

      return `${getDate} ${getMonth}${comma} ${getFullYear}`;
    } else {
      if (country === 'OHK' || country === 'OTW') {
        const cusDate = new Date(date);

        const getDate = cusDate.getDate(),
          getMonth = cusDate.getMonth() + 1,
          getFullYear = cusDate.getFullYear();

        return `${getFullYear}/${getMonth}/${getDate}`;
      }
      if (country === 'OTH') {
        const cusDate = new Date(date);

        const getDate = cusDate.getDate(),
          getMonth = TH_MONTH_NAMES[cusDate.getMonth()],
          getFullYear = cusDate.getFullYear();

        return `${getDate} ${getMonth} ${getFullYear}`;
      }
      if (country === 'OCN') {
        const cusDate = new Date(date);

        const getDate = cusDate.getDate(),
          getMonth = cusDate.getMonth() + 1,
          getFullYear = cusDate.getFullYear();

        return `${getFullYear}年${getMonth}月${getDate}日`;
      }
    }
  },
  customTime: (time) => {
    let date = new Date(time);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = date.getHours() >= 12 ? 'pm' : 'am';

    hours = hours % 12;

    hours = hours ? hours : 12;

    minutes = minutes < 10 ? '0' + minutes : minutes;

    let strTime = hours + ':' + minutes + ' ' + ampm;

    return strTime;
  },
  formatPassword: (min) => {
    return new RegExp(`^(?!.* )(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{${min},}$`);
  },

  getItemList: (list) => {
    let itemList = [];

    list.forEach((item, index) => {
      itemList.push({
        id: item.ProductId,
        name: item.DisplayName,
        list_name: 'Search Results',
        brand: 'Osim',
        category: item.CategoryName,
        variant: item.Properties?.Color,
        list_position: index + 1,
        quantity: item.Quantity,
        price: item.LineFinalTotalAmount
        // [`item_${index + 1}_id`]: item.ProductId,
        // [`item_${index + 1}_name`]: item.DisplayName,
        // [`item_${index + 1}_list_name`]: 'Search Results',
        // [`item_${index + 1}_brand`]: 'Osim',
        // [`item_${index + 1}_category`]: item.CategoryName,
        // [`item_${index + 1}_variant`]: item?.Properties?.Color,
        // [`item_${index + 1}_list_position`]: index + 1,
        // [`item_${index + 1}_quantity`]: parseInt(item.Quantity),
        // [`item_${index + 1}_price`]: item.LineFinalTotalAmount
      });
    });

    return itemList;
  },

  getItems: (list, coupon, isQuantity, currency, indexGA4, discount) => {
    let itemList = [];
    const queryString = window.location.search;
    const urlSearchParams = new URLSearchParams(queryString);
    const listName = urlSearchParams.get('LstName') || urlSearchParams.get('CategoryName');
    const indexGA4Param = urlSearchParams.get('IndexGA4') || '';

    list.forEach((item, index) => {
      itemList.push({
        item_id: item?.ProductId,
        item_name: item?.ProductName || item?.DisplayName,
        coupon: coupon || '',
        currency: item?.Price?.CurrencyCode || currency,
        index: indexGA4 || indexGA4Param || index,
        discount: discount || item?.LineDiscountAmount || (Number(item?.Quantity) || 1) * Number(item?.Price?.Discount || 0) || 0,
        item_brand: 'OSIM',
        item_category: item?.CategoryDisplayName || item?.CategoryName || '',
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_list_id: listName || item?.itemListName || item?.CategoryDisplayName || item.CategoryName || '',
        item_list_name: listName || item?.itemListName || item.CategoryName || '',
        item_variant: item?.Variants?.[0]?.Color || item?.VariantPropertyValue || '',
        price: item?.LineFinalPriceAmount || item?.Price?.SellingPrice || item?.LinePriceAmount || item?.SellingPrice || item?.SellPrice?.Amount || 0,
        quantity: isQuantity ? Number(isQuantity) || 1 : Number(item?.Quantity || 1)
      });
    });

    return itemList;
  },

  getItemsCart: (list, coupon, isQuantity, currency, indexGA4, discount, status, LstName) => {
    let itemList = [];

    const paramGA4 = JSON.parse(window.localStorage.getItem('paramGA4'));
    const itemId = SitecoreContextFactoryService.getValueContextItem('itemId');

    list.forEach((item, index) => {
      itemList.push({
        item_id: item?.ProductId,
        item_name: item?.ProductName || item?.DisplayName,
        coupon: coupon || '',
        currency: item?.Price?.CurrencyCode || currency,
        index: indexGA4 ? indexGA4 : index,
        discount: discount || item?.LineDiscountAmount || (Number(item?.Quantity) || 1) * Number(item?.Price?.Discount || 0) || 0,
        item_brand: 'OSIM',
        item_category: item?.CategoryDisplayName || item?.CategoryName || '',
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_list_id: itemId || LstName || paramGA4?.[Object.keys(paramGA4).find((lstName) => lstName === item?.VariantId)],
        item_list_name: LstName || paramGA4?.[Object.keys(paramGA4).find((lstName) => lstName === item?.VariantId)],
        item_variant: item?.Variants?.[0]?.Color || item?.VariantPropertyValue || '',
        // price: status === 'REMOVE_ALL' ? item?.LineFinalTotalAmount : item?.LineFinalTotalAmount / Number(item?.Quantity),
        price: status === 'REMOVE_ALL' ? item?.LineFinalTotalAmount : item?.LineFinalPriceAmount,
        quantity: isQuantity ? Number(isQuantity) || 1 : Number(item?.Quantity || 1)
      });
    });

    return itemList;
  },

  handleGA4ViewAndSelectItems: (indexGA4, list, categoryName, coupon, brand, currencyCode, itemLstName) => {
    let itemList = [];
    const queryString = window.location.search;
    const urlSearchParams = new URLSearchParams(queryString);
    const lstNameParam = urlSearchParams.get('LstName');
    const categoryNameParam = urlSearchParams.get('CategoryName');
    const itemId = SitecoreContextFactoryService.getValueContextItem('itemId');

    list.forEach((item) => {
      itemList.push({
        item_id: item?.ProductId || item?.BundleId || item?.VariantId,
        item_name: item?.ProductName || item?.BundleName,
        coupon: coupon || item?.Coupon || '',
        currency:
          item?.Price?.CurrencyCode ||
          item?.CurrencyInformation?.Name ||
          item?.IncludedProducts?.[0]?.Price?.CurrencyCode ||
          item?.Products?.[0]?.Price?.CurrencyCode ||
          currencyCode,
        discount: item?.Price?.ListPrice - item?.Price?.SellingPrice || item?.ListPrice?.Amount - item?.SellingPrice?.Amount || 0,
        index: Number(indexGA4),
        item_brand: 'OSIM',
        item_category: categoryName || item?.CategoryDisplayName || item?.CategoryName,
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_list_id:
          item.item_list_id || itemId || itemLstName || lstNameParam || categoryName || item?.CategoryDisplayName || item?.CategoryName || '',
        item_list_name: itemLstName || lstNameParam || categoryName || item?.CategoryName || categoryNameParam || '',
        item_variant: item?.Variants?.[0]?.Color || item?.VariantPropertyValue || '',
        price: item?.Price?.SellingPrice || item?.SellPrice?.Amount || item?.SellPrice || item?.SellingPrice,
        // quantity: item?.Stock?.StockQuantity || 0
        quantity: 1
      });
    });

    return itemList;
  },

  handleGA4Navigation: (data) => {
    if (data) {
      let params = {
        item_click: data.labelItem, //label of navigation button clicked
        page_type: data.pageTitle, //page title where navigation clicked
        nav_category: data.labelCategory, // label of the navigation
        page_url: data.pageUrl //URL of the destination page
      };

      //GA4
      new GoogleAnalytic().gAnalytic4('event', 'navigationClick', params);
    }
  },

  getItemsPromotion: (list, creativeSlot) => {
    let itemList = [];

    list.forEach((item, index) => {
      itemList.push({
        promotion_id: item?.id,
        promotion_name: item?.fields?.title || item?.fields?.Title?.value,
        creative_name: item?.fields?.title || item?.fields?.Title?.value,
        creative_slot: creativeSlot ? creativeSlot : index
      });
    });

    return itemList;
  },

  handleGA4ViewItems: (list, categoryName, coupon, brand, currencyCode, itemLstName) => {
    let itemList = [];
    const itemId = SitecoreContextFactoryService.getValueContextItem('itemId');

    list.forEach((item, index) => {
      itemList.push({
        item_id: item?.ProductId || item?.BundleId,
        item_name: item?.ProductName || item?.BundleName,
        coupon: coupon || item?.Coupon || '',
        currency:
          item?.Price?.CurrencyCode ||
          item?.CurrencyInformation?.Name ||
          item?.IncludedProducts?.[0]?.Price?.CurrencyCode ||
          item?.Products?.[0]?.Price?.CurrencyCode ||
          currencyCode,
        discount: item?.Price?.ListPrice - item?.Price?.SellingPrice || 0,
        index: index,
        item_brand: 'OSIM',
        item_category: categoryName || item?.CategoryDisplayName || item?.CategoryName,
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_list_id: item.item_list_id || itemId || itemLstName || categoryName || item?.CategoryDisplayName || item?.CategoryName || '',
        item_list_name: item.ItemListName || itemLstName || categoryName || item?.CategoryName || '',
        item_variant: item?.Variants?.[0]?.Color || item?.VariantPropertyValue || '',
        price: item?.Price?.SellingPrice || item?.SellPrice?.Amount || item?.SellPrice || item?.SellingPrice
      });
    });

    return itemList;
  },

  handleSwitchVariant: (data, item) => {
    let product = {};

    Object.assign(product, {
      item_id: data?.ProductId,
      item_name: data?.ProductName,
      item_category: data?.CategoryDisplayName || data?.CategoryName,
      selected_option: item?.VariantPropertyValue,
      switch_from: data?.VariantId || '',
      switch_to: item?.VariantId
    });

    return product;
  },

  splitPhoneNumberWithoutCode: (isSplit, phone, codeList) => {
    // INFO: prefix of MY site must be saved in backend without +
    if (isSplit && phone && phone.includes('+') && codeList?.length) {
      const isMySiteName = isAtSiteName(MY_SITE_NAME);
      let tmp = phone;
      const currentCode = codeList.find((item) => phone.includes(isMySiteName ? `+${item.value}` : item.value));
      const prefixCodeValue = currentCode?.value ? (isMySiteName ? `+${currentCode.value}` : currentCode.value) : '';

      tmp = phone.replace(prefixCodeValue, '');

      return { code: currentCode?.value, value: tmp };
    }

    return { code: '', value: phone };
  },

  customizeISOStringHourType: (date) => {
    return new Date(date.setHours(date.getHours() - new Date().getTimezoneOffset() / 60)).toISOString();
  },

  formatToISOStringHour: (day, month, year) => {
    let date = new Date();

    date.setHours(date.getHours() - new Date().getTimezoneOffset() / 60);

    date.setFullYear(year?.value || '0001');

    date.setMonth(month?.value - 1, 1);

    date.setDate(day?.value);

    return date.toISOString();
  },

  onGetMinInstallmentPeriods: (InstallmentPeriods) => {
    if (InstallmentPeriods.length) {
      return InstallmentPeriods.slice().sort(function (a, b) {
        return a.Period - b.Period;
      })[0].Period;
    }

    return null;
  },

  onGetMinInstallmentPeriodsInPDPAndBO: (i, ic) => {
    if (ic.length) {
      if (i.length) {
        let sic = ic.slice().sort((a, b) => a?.Period - b?.Period);
        let si = i.slice().sort((a, b) => a - b);
        if (sic.some((ele1) => si.some((ele2) => ele1.Period == ele2))) {
          return sic.find((InstallmentPeriod) => {
            if (si.includes('' + InstallmentPeriod?.Period)) return InstallmentPeriod?.Period;
          });
        } else if (sic.every((ele1) => si.every((ele2) => ele1.Period != ele2))) return sic?.[0]?.Period;
      } else return ic.slice().sort((a, b) => a?.Period - b?.Period)?.[0];
    } else return null;
  },

  onGetMinInstallmentPeriodsInCartAndReview: (linesArr) => {
    let newLinesArr = linesArr.map((item) => item.InstallmentPeriods).filter((item) => item[0].length);
    if (newLinesArr.length) {
      let tmpArr = [];

      newLinesArr.forEach((line) => {
        tmpArr.push(Math.min(...line));
      });

      // return newLinesArr.shift().find((v) => newLinesArr.every((a) => a.indexOf(v) !== -1));
      return Math.min(...tmpArr);
    }

    return null;
  },

  convertEpochTime: (end) => {
    if (end) {
      let endTime = end.match(/\d/g).join('');

      let date = new Date(parseInt(endTime));

      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
    }

    return '';
  },

  // onRemoveDuplicateGateway: (gateways) => {
  //   return gateways.reduce((total, ele) => {
  //     let logosArr = [];
  //     if (
  //       !total.some((obj) => {
  //         return Global.onGetMinInstallmentPeriods(obj.InstallmentPeriods) === Global.onGetMinInstallmentPeriods(ele.InstallmentPeriods);
  //       })
  //     ) {
  //       let minPeriod = Global.onGetMinInstallmentPeriods(ele.InstallmentPeriods);
  //       let tempGateways = gateways.filter((item) => {
  //         return Global.onGetMinInstallmentPeriods(item.InstallmentPeriods) === minPeriod;
  //       });

  //       tempGateways.forEach((tempGateway) => {
  //         return logosArr.push(tempGateway.PdpLogoItemUrl);
  //       });

  //       let tempEle = { ...ele };

  //       tempEle.LogosArr = logosArr;

  //       total.push(tempEle);
  //     }

  //     return total;
  //   }, []);
  // }

  onGetKeyInParamUrl: () => {
    let keys = {};
    if (window.location.search) {
      window.location.search
        .substr(1)
        .split('&')
        .forEach(function (item) {
          let s = item.split('='),
            k = s[0],
            v = s[1] && decodeURIComponent(s[1]);

          (keys[k] = keys[k] || []).push(v);
        });
    }

    return keys;
  },

  onGetValueTagInParamsURL: (paramsURL) => {
    let tags = [];

    Object.keys(paramsURL)
      .filter((item) => item.includes('Tag'))
      .forEach((tag) => {
        tags.push(paramsURL[tag][0]);
      });

    return tags;
  },

  onSetValueMultiSelect: (currentValue, value) => {
    if (currentValue) {
      let answersArr = currentValue.split(', ') || [];
      if (answersArr.indexOf(value) > -1) {
        answersArr.splice(answersArr.indexOf(value), 1);

        return answersArr.join(', ');
      } else {
        answersArr.push(value);

        return answersArr.join(', ');
      }
    } else {
      return value;
    }
  },

  onFormatProductId: (productId) => {
    return productId?.replace(/{|}/g, '').toLowerCase();
  },

  removeEmptyObj: (array) => {
    return array.filter((element) => {
      if (Object.keys(element).length !== 0) {
        return true;
      }

      return false;
    });
  },

  onGetSelectedMultiOption: (newArr, currArr) => {
    if (newArr.length > currArr.length) {
      return newArr.find((item) => !currArr.includes(item));
    } else {
      return currArr.find((item) => !newArr.includes(item));
    }
  },
  convertDateTimeFromString: (dateText, lng = null) => {
    const country = SitecoreContextFactoryService.getValueContextItem('site').name;
    const language = lng || SitecoreContextFactoryService.getValueContextItem('language');
    const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const TH_MONTH_NAMES = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];
    let MONTH__NUMBER = [];
    let TH_MONTH__NUMBER = [];

    for (let i = 0; i < 12; i++) {
      MONTH__NUMBER[MONTH_NAMES[i]] = { value: i + 1 };
    }
    for (let i = 0; i < 12; i++) {
      TH_MONTH__NUMBER[TH_MONTH_NAMES[i]] = { value: i + 1 };
    }

    if (dateText) {
      const splitDateText = dateText.split(' ');
      if (language === 'en') {
        return {
          day: { value: parseInt(splitDateText[0], 10), label: parseInt(splitDateText[0], 10) },
          month: {
            value: parseInt(MONTH__NUMBER[splitDateText[1]]?.value, 10),
            label: parseInt(MONTH__NUMBER[splitDateText[1]]?.value, 10)
          },
          year: {
            value: splitDateText[2] ? parseInt(splitDateText[2]) : null,
            label: splitDateText[2] ? parseInt(splitDateText[2]) : null
          }
        };
      } else {
        if (country === 'OTH') {
          return {
            day: { value: parseInt(splitDateText[0], 10), label: parseInt(splitDateText[0], 10) },
            month: {
              value: parseInt(TH_MONTH__NUMBER[splitDateText[1]]?.value, 10),
              label: parseInt(TH_MONTH__NUMBER[splitDateText[1]]?.value, 10)
            },
            year: { value: parseInt(splitDateText[2]), label: parseInt(splitDateText[2]) }
          };
        }
      }
    }
  },

  isImage: (url) => {
    return url ? /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url.toLowerCase()) : '';
  },

  fancyTimeFormat: (duration) => {
    //INFO: ~~ is a shorthand for Math.floor
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    let ret = '';

    if (hrs > 0) {
      ret += '' + Global.addZero(hrs) + ':' + (mins < 10 ? '0' : '');
    }

    ret += '' + Global.addZero(mins) + ':' + (secs < 10 ? '0' : '');

    ret += '' + secs;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    return ret;
  },

  videoType: (videoSrc) => {
    let typeVideo = videoSrc?.slice(videoSrc?.lastIndexOf('.') + 1);
    if (typeVideo.toLowerCase() === 'mov' || typeVideo.toLowerCase() === 'mkv') {
      typeVideo = 'mp4';

      return typeVideo;
    }

    return typeVideo;
  },

  checkElementVisible: (launchTimeData, endTimeData) => {
    const bannerLaunchTime = launchTimeData;
    const bannerEndTime = endTimeData;
    const isValidBannerLaunchTime = !(bannerLaunchTime === '0001-01-01T00:00:00Z' || bannerLaunchTime === '0001-01-01T00:00:00.0000000Z');
    const isValidBannerEndTime = !(bannerEndTime === '0001-01-01T00:00:00Z' || bannerEndTime === '0001-01-01T00:00:00.0000000Z');
    const currentDate = Date.parse(new Date(Date.now()));
    const startTime = new Date(bannerLaunchTime);
    const endTime = new Date(bannerEndTime);

    if (!isValidBannerLaunchTime && !isValidBannerEndTime) {
      return true;
    } else if (!isValidBannerLaunchTime || !isValidBannerEndTime) {
      if (!isValidBannerLaunchTime && isValidBannerEndTime && currentDate <= endTime) return true;
      if (isValidBannerLaunchTime && !isValidBannerEndTime && currentDate >= startTime) return true;
    } else {
      if (currentDate >= startTime && currentDate <= endTime) {
        return true;
      }
    }
  },

  getIsGA4: () => {
    return (
      SitecoreContextFactoryService.getValueContextItem('Is GA4') === 'true' || SitecoreContextFactoryService.getValueContextItem('Is GA4') === true
    );
  },
  nameValidateRegex: () => {
    const currentLanguage = SitecoreContextFactoryService.getValueContextItem('language');
    const englishNameValidate = new RegExp(/^[a-zA-Z][a-zA-Z ]+$/gm);
    const chinesNameValidate = new RegExp('^[\\a-zA-Z\u4e00-\u9fa5\uf900-\ufa2d-\0-9]*$');
    const thaiNameValidate = new RegExp(/[\u0E00-\u0E7Fa-zA-Z']+$/g);
    let regexValue = null;
    switch (currentLanguage) {
    case 'en':
      regexValue = englishNameValidate;
      break;

    case 'en-US':
      regexValue = englishNameValidate;
      break;

    case 'th-TH':
      regexValue = thaiNameValidate;
      break;
    default:
      regexValue = chinesNameValidate;
    }

    return regexValue;
  },
  getGA4Params: () => {
    const params = window.localStorage.getItem(GA4_LOCAL_STORAGE_KEY);

    return params ? JSON.parse(params) : '';
  },
  getIndexGA4Param: () => {
    const queryString = window.location.search;
    const urlSearchParams = new URLSearchParams(queryString);

    return urlSearchParams.get('IndexGA4');
  },
  convertJSONDateToNumber: (jsonDate) => {
    if (!jsonDate?.includes('/Date(-')) {
      return new Date(jsonDate.match(/\d+/)[0] * 1) || '';
    }

    return '';
  },
  checkIsDecimalNumber: (number) => number % 1 !== 0,
  truncate: (string, maxLength = 100, suffix = '...') => (string ? string.substring(0, maxLength).trim() + suffix : ''),
  roundUpDecimal: (value, to = 0) => Math.ceil(value * Math.pow(10, to)) / Math.pow(10, to)
};

export default Global;
