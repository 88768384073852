import './PromotionDetailBundleItem.scss';

import PropTypes from 'prop-types';
import React from 'react';

import LabelsModel from '@models/LabelsModel';

const PromotionDetailBundleItem = ({ data, layoutData }) => {
  return (
    <div className='promotion-detail-bundle-item'>
      <div className='promotion-detail-bundle-item__contents'>
        <div className='promotion-detail-bundle-item__thumb'>
          <a className='promotion-detail-bundle-item__thumb__picture' rel='noreferrer' target='_blank' href={data.Url}>
            <img src={data.Image} alt='x' />
          </a>
        </div>
        <ul className='list-tags'>
          {data.Labels.map((item, index) => {
            const itemData = new LabelsModel().getData(item);

            return (
              <li key={index} className={`list-tags__item osim-label ${itemData.IsSpecial ? 'osim-label--highlight' : 'osim-label--default'}`}>
                {itemData.Name}
              </li>
            );
          })}
        </ul>
        <div className='promotion-detail-bundle-item__info'>
          <h3 className='promotion-detail-bundle-item__name bundle-sub-title'>
            <a href={data.Url} rel='noreferrer' target='_blank'>{data.ProductName}</a>
          </h3>
          <div className='promotion-detail-bundle-item__selling-price'>{data.Price.SellingPriceWithCurrency}</div>
          {
            data.Price.ListPrice !== data.Price.SellingPrice ? <div className='promotion-detail-bundle-item__list-price'>{data.Price.ListPriceWithCurrency}</div> : null
          }
          <p className='promotion-detail-bundle-item__description bundle-description'>{data.Description}</p>
        </div>
      </div>
      <div className='promotion-detail-bundle-item__link'>
        <a className='btn-link' href={data.Url} rel='noreferrer' target='_blank'>
          {layoutData['Learn More Label'].value}
        </a>
      </div>
    </div>
  );
};

PromotionDetailBundleItem.propTypes = {
  data: PropTypes.any,
  layoutData: PropTypes.any
};

export default PromotionDetailBundleItem;
