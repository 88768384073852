import './AddressComponent.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import AdditionalFullFilmentOption from '@components/Share/AdditionalFullFilmentOption';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import ModalComponent from '@components/Share/ModalComponent';
import AddNewAddress from '@components/SlicingPage/MyAccount/MyAddress/AddNewAddress';
import { addPriorityToCart } from '@redux/actions/addPriorityToCart';
import { setCartFullfillment, setCartLineFullfillment } from '@redux/actions/cartDetail';
import { addNewAddressAndSetFulfill, setAddressValidErrorMessage } from '@redux/actions/checkoutProcess';
import { checkPriorityDeliveryStart } from '@redux/actions/priorityDelivery';
import { checkAddressErrorMessageSelector } from '@redux/reducers/checkoutProcess/setAddressValidErrorMessage/selector';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { DELIVERY_OPTION } from '@utils/constant';
import global from '@utils/global';
import { deepCopy } from '@utils/utility';

import { addressErrorMessagePlaced } from '../../configs';
import { USER_ADDRESS_COMPONENT, USER_ADDRESS_SECTION_ID } from '../../ContinuePaymentBtn/configs';
import { useCheckAddressValid } from '../../hooks';
import SelectAddressPopup from './SelectAddressPopup';
import { behaviorUserAddressComponent$ } from './UserAddressComponentBehavior';

const UserAddressComponent = ({
  type,
  userAddress,
  layoutData,
  page,
  isLogin,
  cartData,
  cartValue,
  showError,
  isLoading,
  isLoadingAddPriority,
  messageObj,
  isMyOrder,
  cartLineData,
  setCartLineFulfillmentError
}) => {
  const dispatch = useDispatch();
  const checkAddressValid = useCheckAddressValid();
  const [showPopup, setShowPopup] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [listShowAddress, setListShowAddress] = useState(null);
  const [defaultAddress, setDefaultAddress] = useState({});
  const [areaFieldCheck, setAreaFieldCheck] = useState(null);
  const [tempItem, setTempItem] = useState(null);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isSameAsDelivery, setIsSameAsDelivery] = useState(false);
  const [isShowPriorityDeliveryInfo, setIsShowPriorityDeliveryInfo] = useState(false);
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const { displayPriorityDelivery } = useSelector((state) => state.priorityDeliveryReducer);
  const { section: sectionToShowMessage, isValid: isDeliveryAddressValid } = useSelector(checkAddressErrorMessageSelector);

  const [addressErrorMessage, setAddressErrorMessage] = useState({
    isShow: false,
    errorMsgPlaced: addressErrorMessagePlaced.SECTION_PLACE
  });

  const isShowCategoryShowPriorityDelivery = useMemo(() => {
    return cartLineData.some((lineItem) => lineItem.CategoryShowPriorityDelivery === true);
  }, [cartLineData]);

  useEffect(() => {
    let areaCheckField = [];

    if (layoutData['Area Shipping Fee Setting']) {
      layoutData['Area Shipping Fee Setting']['Address Elements'].forEach((item) => {
        if (item['Field Name'] && item['Field Name'] !== '') {
          areaCheckField.push(item['Field Name'].value);
        }
      });
    }

    if (areaCheckField.length > 0) {
      setAreaFieldCheck(areaCheckField);
    }

    if (userAddress && userAddress.length > 0 && !cartLineData?.[0]?.Address?.FirstName) {
      const defaultDelivery = userAddress.find((item) => {
        return item.IsPrimary;
      });

      if (defaultDelivery && checkAreaChange(defaultDelivery, areaCheckField)) {
        // dispatch(setCartFullfillment({ setFulfillmentPayload: removeRedundantField(defaultDelivery), reloadPromotions: false }));
        dispatch(setCartLineFullfillment({ updateCartLineFulfillmentPayload: removeRedundantField(defaultDelivery), reloadPromotions: false }));
      }
    }
  }, []);

  const isHidePriorityDelivery = useMemo(() => {
    return layoutData?.['Hide Priority Delivery']?.value || false;
  }, [layoutData]);

  useEffect(() => {
    if (userAddress && userAddress.length > 0) {
      const listShowAddress = [];

      const shippingDefault = userAddress.find((item) => {
        return item.IsPrimary;
      });

      setDefaultAddress(shippingDefault);

      const selectedAddress = userAddress.find((item) => {
        return item.ExternalId === cartLineData?.[0]?.Address?.ExternalId;
      });

      if (shippingDefault) {
        listShowAddress.push(shippingDefault);
      }
      if (selectedAddress && selectedAddress.ExternalId !== shippingDefault?.ExternalId) {
        listShowAddress.push(selectedAddress);
      }

      userAddress.forEach((item) => {
        if (item.ExternalId !== shippingDefault?.ExternalId && item.ExternalId !== selectedAddress?.ExternalId && listShowAddress.length < 3) {
          listShowAddress.push(item);
        }
      });

      setListShowAddress(listShowAddress);
    }
  }, [userAddress, cartLineData?.[0]?.Address]);

  useEffect(() => {
    // check priority deliver
    dispatch(checkPriorityDeliveryStart());
  }, []);

  const defaultSetFulfillmentPayload = {
    AreaCode: '',
    CityCode: '',
    ProvinceCode: '',
    Postcode: '',
    DistrictCode: '',
    SubDistrictCode: '',
    StateCode: '',
    AddressName: '',
    Address1: '',
    Address2: '',
    PhoneNumber: ''
  };

  const onClosePopupHandler = () => {
    setShowPopup(false);

    setAddressErrorMessage((prevState) => ({ ...prevState, isShow: false }));
  };

  const GAAddShippingInfo = () => {
    let params = {
      items: global.getItemList(cartValue.Lines),
      checkout_step: 2,
      checkout_option: 'shipping info'
    };

    new GoogleAnalytic().ggGateWay('event', 'checkout_progress', params);

    // new GoogleAnalytic().ggGateWay('event', 'set_checkout_option', {
    //   'checkout_step': 2,
    //   'checkout_option': 'shipping info'
    // });
  };

  const setAddAddressEvtHandler = (value, isBilling) => {
    const listId = cartValue?.Lines?.filter((item) => item.DeliveryOptionId == cartLineData?.[0]?.DeliveryOptionId).map(
      (item) => item.ExternalCartLineId
    );

    behaviorUserAddressComponent$.next(isBilling);

    if (isLogin) {
      if (checkAreaChange(value)) {
        GAAddShippingInfo();

        dispatch(
          addNewAddressAndSetFulfill({
            params: value,
            SameAsDeliveryAddress: isBilling,
            cartLineIds: listId,
            deliveryOptionId: cartLineData?.[0]?.DeliveryOptionId
          })
        );
      } else {
        setTempItem(value);

        setIsAddNew(true);

        setIsSameAsDelivery(isBilling);

        setShowConfirmPopup(true);
      }
    } else {
      GAAddShippingInfo();
      const updateCartLineFulfillmentPayload = Object.assign(defaultSetFulfillmentPayload, value);

      delete updateCartLineFulfillmentPayload.FullAddress;
      if (isBilling) {
        updateCartLineFulfillmentPayload.SameAsDeliveryAddress = isBilling;
      }
      if (checkAreaChange(updateCartLineFulfillmentPayload)) {
        updateCartLineFulfillmentPayload.CartLineIds = listId;

        updateCartLineFulfillmentPayload.DeliveryOptionId = cartLineData?.[0]?.DeliveryOptionId;

        // dispatch(setCartFullfillment({ setFulfillmentPayload: setFulfillmentPayload, reloadPromotions: false }));
        dispatch(setCartLineFullfillment({ updateCartLineFulfillmentPayload: updateCartLineFulfillmentPayload }));
      } else {
        setTempItem(updateCartLineFulfillmentPayload);

        setIsSameAsDelivery(isBilling);

        setShowConfirmPopup(true);
      }
    }
  };

  const removeRedundantField = (item) => {
    const tempObj = deepCopy(item);

    delete tempObj.FullAddress;

    const listId = cartValue?.Lines?.map((item) => {
      return item.ExternalCartLineId;
    });

    tempObj.CartLineIds = listId;

    tempObj.DeliveryOptionId = cartLineData?.[0]?.DeliveryOptionId;

    return tempObj;
  };

  const selectFromListAddress = (item, errorMsgPlaced = addressErrorMessagePlaced.SECTION_PLACE) => {
    let isClosePopup = true;
    const isValidAddress = checkAddressValid(item);

    const addressErrorMessageTemp = {
      errorMsgPlaced: errorMsgPlaced,
      isShow: false
    };
    if (errorMsgPlaced === addressErrorMessagePlaced.POPUP_PLACE) {
      isClosePopup = isValidAddress;
    }
    if (Object.keys(item).length !== 0) {
      if (isValidAddress) {
        addressErrorMessageTemp.isShow = false;
        if (checkAreaChange(item)) {
          let tempObj = deepCopy(item);
          let tempArr = [];

          delete tempObj.FullAddress;

          cartLineData.forEach((line) => {
            tempArr.push(line?.ExternalCartLineId);
          });

          tempObj.CartLineIds = tempArr;

          tempObj.DeliveryOptionId = cartLineData?.[0]?.DeliveryOptionId;

          // dispatch(setCartFullfillment({setFulfillmentPayload: removeRedundantField(item), reloadPromotions: false}));

          dispatch(setCartLineFullfillment({ updateCartLineFulfillmentPayload: tempObj }));

          // INFO: dispatch to hide error message display when address valid
          dispatch(setAddressValidErrorMessage({ section: '', isValid: true }));
        } else {
          setTempItem(removeRedundantField(item));

          setShowConfirmPopup(true);
        }
      } else {
        addressErrorMessageTemp.isShow = true;
      }

      setAddressErrorMessage(addressErrorMessageTemp);
    }

    setShowPopup(isClosePopup !== true);
  };

  const checkAreaChange = (item, areaCheckField) => {
    let fieldToCheck = areaCheckField ? areaCheckField : areaFieldCheck;

    if (fieldToCheck && cartLineData?.[0]?.Address) {
      let checkArea = fieldToCheck
        .map((field) => {
          if (field) {
            return item[field] ? item[field] === cartLineData?.[0]?.Address?.[field] : cartLineData?.[0]?.Address?.[field] === '';
          }
        })
        .every((item) => item);

      return checkArea;
    } else {
      return true;
    }
  };

  const confirmChangeArea = (deliveryOptionId) => {
    const listId = cartValue?.Lines?.filter((item) => item.DeliveryOptionId == deliveryOptionId).map((item) => item.ExternalCartLineId);
    if (isAddNew) {
      // GAAddShippingInfo();

      let additionalItem = { ...tempItem };

      additionalItem.cartLineIds = listId;

      additionalItem.deliveryOptionId = cartLineData?.[0]?.DeliveryOptionId;

      dispatch(
        addNewAddressAndSetFulfill({
          params: tempItem,
          SameAsDeliveryAddress: isSameAsDelivery,
          cartLineIds: listId,
          deliveryOptionId: cartLineData?.[0]?.DeliveryOptionId
        })
      );

      setIsAddNew(false);
    } else {
      let updateCartLineFulfillmentPayload = { ...tempItem };

      updateCartLineFulfillmentPayload.CartLineIds = listId;

      updateCartLineFulfillmentPayload.DeliveryOptionId = cartLineData?.[0]?.DeliveryOptionId;

      // dispatch(setCartFullfillment({ setFulfillmentPayload: updateCartLineFulfillmentPayload, reloadPromotions: false }));
      dispatch(setCartLineFullfillment({ updateCartLineFulfillmentPayload: updateCartLineFulfillmentPayload }));
    }

    setIsSameAsDelivery(false);

    setShowConfirmPopup(false);
  };

  const getAddressRootCode = () =>
    cartLineData?.[0]?.DeliveryOptions.find((option) => option?.ShippingOptionType?.Value == DELIVERY_OPTION.DeliveryBySFExpress)?.AddressRootCode;

  const handleAddPriorityToCart = (event) => {
    dispatch(addPriorityToCart({ priorityDelivery: event.target.checked }));
  };

  const isShowInvalidAddressMessage = useMemo(() => {
    return !(showError && !cartLineData?.[0]?.Address?.FirstName)
      ? (addressErrorMessage.isShow && addressErrorMessage.errorMsgPlaced === addressErrorMessagePlaced.SECTION_PLACE) ||
          (sectionToShowMessage === USER_ADDRESS_COMPONENT && !isDeliveryAddressValid)
      : false;
  }, [cartData, showError, addressErrorMessage, sectionToShowMessage, isDeliveryAddressValid]);

  return (
    <div className='address-component' id={USER_ADDRESS_SECTION_ID}>
      <h3 className='delivery-item-title'>
        <Text field={layoutData['Delivery Address Label']} />
      </h3>
      <div className='delivery-row address-component__row'>
        <div className='delivery-col-left'>
          {page === 'review' ? (
            ''
          ) : page === 'balance-payment' ? (
            ''
          ) : (
            <div className='address-component__line1'>
              {type === 'billing-details' ? (
                <div className='auto-address'>
                  <input type='checkbox' id='auto-address' className='auto-address__input' />
                  <label htmlFor='auto-address'>
                    <Text field={layoutData['Billing Same as Delivery Address Label']} />
                  </label>
                </div>
              ) : (
                ''
              )}
            </div>
          )}
          {cartLineData?.[0]?.Address?.AreaFullAddress ? (
            <div className='address-component__line2'>
              {page === 'review' ? (
                ''
              ) : page === 'balance-payment' ? (
                ''
              ) : (
                <div className='area-selected'>
                  <p dangerouslySetInnerHTML={{ __html: cartLineData?.[0]?.Address.AreaFullAddress }} className='area-selected__content'></p>
                  {type == 'delivery-by-sf-express' ? (
                    <></>
                  ) : (
                    cartValue && (
                      <span className='area-selected__price'>
                        {cartValue.DeliveryTotalAmount === 0 ? <Message messageCode='LBL-Free' /> : cartValue.DeliveryTotal}
                      </span>
                    )
                  )}
                </div>
              )}
              {cartLineData?.[0]?.Address?.FirstName !== '' ? (
                <>
                  {isMyOrder ? (
                    ''
                  ) : (
                    <strong>
                      {cartLineData?.[0]?.Address?.AddressName}
                      {cartLineData?.[0]?.Address?.ExternalId === defaultAddress?.ExternalId ? (
                        <span className='address-default'>
                          <Text field={layoutData['Default Label']} />
                        </span>
                      ) : (
                        ''
                      )}
                    </strong>
                  )}
                  <span>{`${settingGlobal?.FullNameFormat.replace('{LastName}', cartLineData?.[0]?.Address?.LastName).replace(
                    '{FirstName}',
                    cartLineData?.[0]?.Address?.FirstName
                  )}`}</span>
                  <span dangerouslySetInnerHTML={{ __html: cartLineData?.[0]?.Address?.FullAddress }}></span>
                  <span>
                    <Text field={layoutData['Phone Number']} />: {cartLineData?.[0]?.Address?.PhoneNumber}
                  </span>
                  <AdditionalFullFilmentOption
                    inputData={SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Additional Fulfilment Info']?.fields}
                  />
                </>
              ) : (
                <p className='address-component__no-address'>
                  <Text field={layoutData['No Address Message']} />
                </p>
              )}
            </div>
          ) : (
            <div className='address-component__line2'>
              {page === 'review' ? (
                ''
              ) : page === 'balance-payment' ? (
                ''
              ) : (
                <>
                  <div className='area-selected'>
                    <p className='area-selected__content'>{messageObj?.['LBL-ShippingFee']}</p>
                    {type == 'delivery-by-sf-express' ? (
                      <></>
                    ) : (
                      cartValue && (
                        <span className='area-selected__price'>
                          {cartValue.DeliveryTotalAmount === 0 ? <Message messageCode='LBL-Free' /> : cartValue.DeliveryTotal}
                        </span>
                      )
                    )}
                  </div>
                  <p className='address-component__no-address'>
                    <Text field={layoutData['No Address Message']} />
                  </p>
                </>
              )}
            </div>
          )}
        </div>
        {page === 'review' ? (
          ''
        ) : page === 'balance-payment' ? (
          ''
        ) : (
          <div className='delivery-col-right delivery-col-right--padding-left'>
            {showError && cartLineData?.[0]?.Address?.FirstName == '' ? (
              <p className='warning-message'>
                <Text field={layoutData['Please Select Address Message']} />
              </p>
            ) : (
              <></>
            )}
            {isLogin && listShowAddress && listShowAddress.length > 0 ? (
              <>
                <ul className='address-component__list'>
                  {listShowAddress.map((item, ind) => {
                    return (
                      <li
                        onClick={() => selectFromListAddress(item)}
                        className={`address-component__list__item ${item.ExternalId === cartLineData?.[0]?.Address?.ExternalId ? 'selected' : ''} ${
                          type == 'delivery-by-sf-express' && item?.CityCode != getAddressRootCode() ? 'disabled' : ''
                        }`}
                        key={ind}
                      >
                        <span className='item-name'>{`${item.AddressName ? item.AddressName : ''}, ${settingGlobal?.FullNameFormat.replace(
                          '{LastName}',
                          item.LastName
                        ).replace('{FirstName}', item.FirstName)}, ${item?.FullAddress ? item?.FullAddress.replace(/<br\/>/g, ', ') : ''}, ${
                          layoutData['Phone Number'].value
                        }: ${item.PhoneNumber ? item.PhoneNumber : ''}`}</span>
                        {item.IsPrimary ? (
                          <small className='item-default'>
                            <Text field={layoutData['Default Label']} />
                          </small>
                        ) : (
                          ''
                        )}
                      </li>
                    );
                  })}
                </ul>
                {userAddress && userAddress.length > 3 ? (
                  <p onClick={() => setShowPopup(true)} className='address-component__button-see-more'>
                    <Text field={layoutData['Billing See More Address Label']} />
                  </p>
                ) : (
                  <></>
                )}
              </>
            ) : null}
            <div className='address-component__button-add-new'>
              <AddNewAddress
                setAddAddressEvt={(value, isBilling) => setAddAddressEvtHandler(value, isBilling)}
                dataSources={layoutData}
                isShippingAddressFullfillment={true}
                needCheckArea={true}
                isAddNew={true}
                // addressCurrent={cartData.Party}
                addressCurrent={cartLineData?.[0]?.Address}
                cartLineData={cartLineData}
                type={type}
                macauAddressCurrent={cartData?.DeliveryBySFExpress?.[0]}
                // setSameAsDelivery={() => setSameAsDelivery()}
              />
            </div>
            {isLoading ? <LoadingMask></LoadingMask> : <></>}
            {isShowInvalidAddressMessage ? (
              <Text tag='span' field={layoutData?.['Incomplete Address Message']} className='error-message invalid-address-msg' />
            ) : (
              <></>
            )}
            {setCartLineFulfillmentError ? <p className='error-message invalid-address-msg'>{setCartLineFulfillmentError}</p> : <></>}
          </div>
        )}
        {page === 'review' ? (
          ''
        ) : page === 'balance-payment' ? (
          ''
        ) : displayPriorityDelivery?.StatusDisplay && isShowCategoryShowPriorityDelivery && !isHidePriorityDelivery ? (
          <div className='address-component__priority-delivery'>
            <Text tag='p' field={layoutData['Eligible For Priority Delivery Label']} className='title' />
            <Text tag='p' field={layoutData['Priority Delivery Member Label']} className='description' />
            <div className='auto-address checkout-checkbox priority-delivery__checkbox'>
              <input
                type='checkbox'
                // checked={sameAsDelivery}
                // onClick={() => setBillingAddress()}
                onChange={handleAddPriorityToCart}
                id='priority-delivery'
                className='auto-address__input checkout-checkbox__input'
                checked={cartValue.PriorityDelivery?.PriorityDelivery}
              />
              <label htmlFor='priority-delivery' className='checkout-checkbox__label'>
                <Text field={layoutData['Priority Delivery Label']} />
              </label>
              <div className='info-icon info-icon--small' onClick={() => setIsShowPriorityDeliveryInfo(true)}></div>
            </div>
            {isLoadingAddPriority ? <LoadingMask /> : <></>}
          </div>
        ) : (
          ''
        )}
      </div>
      <ModalComponent
        isShow={showPopup}
        titlePopup={<Text field={layoutData['PopUp Select Address Label']} tag='span' />}
        onCloseHandler={onClosePopupHandler}
      >
        <SelectAddressPopup
          idSelected={cartLineData?.[0]?.Address?.ExternalId}
          type='user'
          isDelivery={true}
          selectAddress={(event) => selectFromListAddress(event, addressErrorMessagePlaced.POPUP_PLACE)}
          listAddress={userAddress}
          layoutData={layoutData}
          deliveryType={type}
          cityCode={getAddressRootCode()}
        ></SelectAddressPopup>
        {addressErrorMessage.isShow && addressErrorMessage.errorMsgPlaced === addressErrorMessagePlaced.POPUP_PLACE ? (
          <Text tag='span' field={layoutData?.['Incomplete Address Message']} className='error-message invalid-address-msg' />
        ) : (
          <></>
        )}
      </ModalComponent>
      <ModalComponent
        isShow={showConfirmPopup}
        titlePopup={
          <span>
            <Text field={layoutData['PopUp Confirm Select Address Label']} />
          </span>
        }
        onCloseHandler={() => setShowConfirmPopup(false)}
      >
        <div className='confirm-popup confirm-remove-cart'>
          <p className='confirm-remove-cart__text'>
            <Text field={layoutData['PopUp Confirm Description']} />
          </p>
          <div className='my-address__btn text-center'>
            <button className='btn-move-wishlist btn btn-outline-primary' onClick={() => confirmChangeArea(cartLineData?.[0]?.DeliveryOptionId)}>
              <Text field={layoutData['PopUp Accept Label']} />
            </button>
            <button className='btn-remove-cart' onClick={() => setShowConfirmPopup(false)}>
              <Text field={layoutData['PopUp Cancel Label']} />
            </button>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        isShow={isShowPriorityDeliveryInfo}
        titlePopup={<Text field={layoutData['Priority Delivery Info']} />}
        onCloseHandler={() => setIsShowPriorityDeliveryInfo(false)}
      >
        <div className='confirm-popup'>
          <RichText field={layoutData['Note Priority Delivery Label']} />
        </div>
      </ModalComponent>
    </div>
  );
};

const mapStateToProps = (state) => {
  const currentDefaultCartData = state.productReducer.cartDetailReducer?.currentCart || null;
  const clientSOCartData = state.personalizeOfferReducer.getClientSOCartReducer.data?.OsimCart || null;

  return {
    userAddress: state.checkoutProcesstReducer.addressListReducer?.userAddressList || [],
    isLogin: state.singleSignOnReducer.userInfoReducer?.isLogin || false,
    guestData: state.checkoutProcesstReducer.dataCheckoutReducer.infoUser || {},
    userData: state.singleSignOnReducer.userInfoReducer.accountUser || {},
    countryDelivery: state.checkoutProcesstReducer.addressListReducer.countryDelivery?.Data || [],
    cartValue: clientSOCartData || currentDefaultCartData,
    isLoading: state.checkoutProcesstReducer.updateCartReducer.isLoadingUserAddress,
    isLoadingAddPriority: state.addPriorityToCartReducer.isLoading,
    messageObj: state.getMessageReducer.objMessages,
    setCartLineFulfillmentError: state.checkoutProcesstReducer.updateCartReducer.setCartLineFulfillmentError,
  };
};

UserAddressComponent.propTypes = {
  type: PropTypes.string,
  userAddress: PropTypes.array,
  layoutData: PropTypes.object,
  page: PropTypes.string,
  isLogin: PropTypes.bool,
  guestData: PropTypes.any,
  userData: PropTypes.any,
  countryDelivery: PropTypes.any,
  messageObj: PropTypes.any,
  cartData: PropTypes.any,
  cartValue: PropTypes.object,
  showError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingAddPriority: PropTypes.bool,
  isMyOrder: PropTypes.bool,
  cartLineData: PropTypes.array,
  setCartLineFulfillmentError: PropTypes.any
};

export default connect(mapStateToProps)(UserAddressComponent);
